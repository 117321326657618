import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../apiConfig';
import WhatsAppButton from '../components/WhatsAppButton';

const Feedback = () => {
    const navigate = useNavigate();

    const { idSheet } = useParams();

    const [loading, setLoading] = useState(false);
    const [idTechnician, setIdTechnician] = useState(null);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [askedForMoney, setAskedForMoney] = useState(false);
    const [details, setDetails] = useState('');
    const [appExperience, setAppExperience] = useState('');
    const [improvements, setImprovements] = useState('');

    useEffect(() => {
        if (idSheet) {
            const fetchOrder = async () => {
                setLoading(true);

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/pdf/${idSheet}`,
                        headers: {}
                    };
                    axios.request(config)
                        .then((response => {
                            setIdTechnician(response.data.sheet[0].idPartnerTechnician)
                            setLoading(false);
                        }))
                } catch (error) {
                    console.error('Erreur lors de la récupération de la commande: ', error);
                    setLoading(false);
                }
            }

            fetchOrder();
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!rating) {
            alert('Vous ne pouvez pas accorder 0 étoile.');
            return;
        }

        setLoading(true);

        const feedback = {
            idSheet,
            rating,
            askedForMoney,
            details,
            appExperience,
            improvements
        };

        try {
            const response = await axios.post(`${BASE_URL}/api/feedback/create/${idTechnician}`, feedback);
            if (response.data && response.data.message) {
                setLoading(false);
                alert(response.data.message);
                navigate('/');
            } else {
                alert(response.data.error);
                setLoading(false);
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données', error);
            setLoading(false);
        }
    };

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../assets/img/icons/gif/loading-icon-animated.jpeg')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <main className="w-full flex">
                        <div className="flex-1 flex items-center justify-center h-screen">
                            <div className="w-full space-y-8 p-10 bg-white text-gray-600">
                                <div className="w-full text-center p-10">
                                    <img src={require('../assets/img/icons/favicon/favicon-camtel.png')} width={200} className="mx-auto mt-20" />
                                    <div className="space-y-5">
                                        <h3 className="text-gray-800 mb-3 text-2xl font-bold sm:text-4xl text-center">Feedback</h3>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className=''>
                                            <div className='text-center'>Noter le technicien en fonction de votre satisfaction: </div>
                                            <div className="flex justify-center">
                                                {[...Array(5)].map((star, i) => {
                                                    const ratingValue = i + 1;

                                                    return (
                                                        <label key={i}>
                                                            <input
                                                                type="radio"
                                                                name="rating"
                                                                value={ratingValue}
                                                                onClick={() => {
                                                                    setRating(ratingValue);
                                                                    console.log(`Vous avez choisi ${ratingValue} étoile(s)`);
                                                                }}
                                                                className="hidden"
                                                            />
                                                            <svg
                                                                className={`w-6 h-6 cursor-pointer transition duration-200 ease-in-out ${ratingValue <= (hover || rating) ? 'text-yellow-500' : 'text-gray-300'
                                                                    }`}
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20"
                                                                onMouseEnter={() => setHover(ratingValue)}
                                                                onMouseLeave={() => setHover(0)}
                                                            >
                                                                <path d="M10 15.27L16.18 19l-1.64-7.03L22 9.24l-7.19-.61L10 2 7.19 8.63 0 9.24l5.46 4.73L3.82 19z" />
                                                            </svg>
                                                        </label>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div>
                                            <p>Le technicien vous a-t-il demandé de l'argent au moment de l'installation ?</p>
                                            <input type="radio" id="yes" name="askedForMoney" value="yes" onChange={() => setAskedForMoney(true)} required />
                                            <label htmlFor="yes"> Oui</label><br />
                                            <input type="radio" id="no" name="askedForMoney" value="no" onChange={() => setAskedForMoney(false)} required />
                                            <label htmlFor="no"> Non</label><br />
                                            <div>
                                                <label htmlFor="details">Si oui, veuillez donner plus de détails sur le montant :</label><br />
                                                <textarea className='border p-2' id="details" name="details" rows="4" cols="50" onChange={e => setDetails(e.target.value)}></textarea>
                                            </div>
                                            <p>Votre expérience avec l'application FiberConnect a-t-elle été satisfaisante et a-t-elle facilité votre quotidien ?</p>
                                            <input type="radio" id="yes" name="appExperience" value="yes" onChange={() => setAppExperience('yes')} required />
                                            <label htmlFor="yes"> Oui</label><br />
                                            <input type="radio" id="no" name="appExperience" value="no" onChange={() => setAppExperience('no')} required />
                                            <label htmlFor="no"> Non</label><br />
                                            <div>
                                                <label htmlFor="improvements">Quelles améliorations aimeriez-vous voir sur FiberConnect ?</label><br />
                                                <textarea className='border p-3' id="improvements" name="improvements" rows="4" cols="50" onChange={e => setImprovements(e.target.value)} required></textarea>
                                            </div>
                                        </div>

                                        <button
                                            type="submit"
                                            className="px-4 py-2 mb-5 text-white font-medium bg-sky-500 hover:bg-sky-600 active:bg-sky-600 rounded-lg duration-150"
                                        >
                                            Soumettre
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                )
            }

            <WhatsAppButton />
        </>
    );
}

export default Feedback;