import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { BASE_URL } from "../../apiConfig";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import FormatDate from "./components/FormatDate";
import Modal from 'react-modal';

const Activation = () => {
    const [orders, setOrders] = useState([]);

    const [filterStatus, setFilterStatus] = useState('ALL');
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedOrderEmail, setSelectedOrderEmail] = useState(null);
    const [selectedOrderEmailEntity, setSelectedOrderEmailEntity] = useState(null);
    const [selectedOrderNameEntity, setSelectedOrderNameEntity] = useState(null);
    const [selectedOrderName, setSelectedOrderName] = useState(null);
    const [selectedOrderOffer, setSelectedOrderOffer] = useState(null);
    const [selectedOrderLocalisation, setSelectedOrderLocalisation] = useState(null);

    const [showCommercialCamtelForm, setShowCommercialCamtelForm] = useState(false);

    const [paymentDate, setPaymentDate] = useState(null);

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isAddActivationVisible, setIsAddctivationVisible] = useState(true);

    const [showActivationEditForm, setShowActivationEditForm] = useState(true);
    const [isEditActivationVisible, setIsEditActivationVisible] = useState(true);

    const [editingActivation, setEditingActivation] = useState(null);
    const [editedCbsOrderId, setEditedCbsOrderId] = useState("");
    const [editedCrmCustomerCode, setEditedCrmCustomerCode] = useState("");
    const [editedBillingAccount, setEditedBillingAccount] = useState("");
    const [editedPhoneNumberActivated, setEditedPhoneNumberActivated] = useState("");
    const [editedOntNumber, setEditedOntNumber] = useState("");
    const [editedPaymentRef, setEditedPaymentRef] = useState("");
    const [editedPaymentDate, setEditedPaymentDate] = useState(new Date());
    const [editedStbIpStickNumber, setEditedStbIpStickNumber] = useState("");

    const [selectedAppointmentOrder, setSelectedAppointmentOrder] = useState(null);
    const [selectedPreStudyOrder, setSelectedPreStudyOrder] = useState(null);
    const [selectedInstalationData, setSelectedInstallationData] = useState(null);
    const [selectedActivationData, setSelectedActivationData] = useState(null);
    const [selectedPaymentData, setSelectedPaymentData] = useState(null);

    const [comPartnerName, setComPartnerName] = useState([]);
    const [techPartnerName, setTechPartnerName] = useState([]);
    const [techCamtelName, setTechCamtelName] = useState([]);
    const [comCamtelName, setComCamtelName] = useState([]);

    const [details, setDetails] = useState(null);

    const [loading, setLoading] = useState(false);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [cni, setCni] = useState({ idSheet: null, idCardNumber: null, rectoCni: null, versoCni: null });
    const [passport, setPassport] = useState({ idSheet: null, idPassportNumber: null, idPasseportFront: null });
    const [residentPermit, setResidentPermit] = useState({ idSheet: null, idResidencePermitNumber: null, idResidencePermitFront: null });

    const [title, setTitle] = useState('');
    let [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [expirationDate, setExpirationDate] = useState('');

    const [oldCustomerPhotoFileName, setOldCustomerPhotoFileName] = useState("");
    const [oldImageLocalisationFileName, setOldImageLocalisationFileName] = useState("");
    const [oldRectoCniFileName, setOldRectoCniFileName] = useState("");
    const [oldVersoCniFileName, setOldVersoCniFileName] = useState("");
    const [oldIdPassportFrontFileName, setOldIdPassportFrontFileName] = useState("");
    const [oldIdResidencePermitFrontFileName, setOldIdResidencePermitFrontFileName] = useState("");
    const [oldRecotCommerceRegFileName, setOldRectoCommerceRegFileName] = useState("");

    const [displayCustomerPhoto, setDisplayCustomerPhoto] = useState("");
    const [displayImageLocalisation, setDisplayImageLocalisation] = useState("");
    const [displayRectoCni, setDisplayRectoCni] = useState("");
    const [displayVersoCni, setDisplayVersoCni] = useState("");
    const [displayIdPassportFront, setDisplayIdPassportFront] = useState("");
    const [displayIdResidencePermitFront, setDisplayIdResidencePermitFront] = useState("");
    const [displayRecotCommerceReg, setDisplayRectoCommerceReg] = useState("");

    const [editingOrder, setEditingOrder] = useState(null);
    const [editedCustomerPhoto, setEditedCustomerPhoto] = useState("");
    const [editedNameOrSocialName, setEditedNameOrSocialName] = useState("");
    const [editedCustomerType, setEditedCustomerType] = useState("");
    const [editedRegion, setEditedRegion] = useState("");
    const [editedCity, setEditedCity] = useState("");
    const [editedLocalisation, setEditedLocalisation] = useState("");
    const [editedLieuDit, setEditedLieuDit] = useState("");
    const [editedCustomerGpsCoordinates, setEditedCustomerGpsCoordinates] = useState("");
    const [editedImageLocalisation, setEditedImageLocalisation] = useState("");
    const [editedEmail, setEditedEmail] = useState("");
    const [editedMobile, setEditedMobile] = useState("");
    const [editedIdCardNumber, setEditedIdCardNumber] = useState("");
    const [editedIdPassportNumber, setEditedIdPassportNumber] = useState("");
    const [editedIdResidencePermitNumber, setEditedIdResidencePermitNumber] = useState("");
    const [editedRectoCni, setEditedRectoCni] = useState("");
    const [editedVersoCni, setEditedVersoCni] = useState("");
    const [editedIdPassportFront, setEditedIdPassportFront] = useState("");
    const [editedIdResidencePermitFront, setEditedIdResidencePermitFront] = useState("");
    const [editedCommerceRegNumber, setEditedCommerceRegNumber] = useState("");
    const [editedRectoCommerceReg, setEditedRectoCommerceReg] = useState("");
    const [editedOffer, setEditedOffer] = useState("");

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'commercial_camtel') {
            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/activation/comcamtel/` + localStorage.getItem('userId'),
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setOrders(allSheetData);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [showCommercialCamtelForm, token]);

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredOrders = Object.values(orders).filter((entry) => {
        const isActivated = entry.activationData.some(activation => activation.idActivation !== null && activation.idActivation !== '' && activation.idActivation !== 0);
        const hasSuccessfulPayment = entry.paymentData.some(payment => payment.status === 'SUCCESSFULL');

        const matchesStatus = (filterStatus === 'ALL') ||
            (filterStatus === 'PAID' && (isActivated || hasSuccessfulPayment)) ||
            (filterStatus === 'UNPAID' && !isActivated && !hasSuccessfulPayment);

        const matchesSearch = entry.preStudyOrder[0].nameOrSocialName.toLowerCase().includes(searchQuery);

        return matchesStatus && matchesSearch;
    });

    const showOrderDetails = async (order) => {
        localStorage.removeItem('customerData');

        setCni({ idSheet: null, idCardNumber: null, rectoCni: null, versoCni: null });
        setPassport({ idSheet: null, idPassportNumber: null, idPasseportFront: null });
        setResidentPermit({ idSheet: null, idResidencePermitNumber: null, idResidencePermitFront: null });

        setTitle('');
        setGender('');
        setDateOfBirth('');
        setIssueDate('');
        setExpirationDate('');

        setSelectedPreStudyOrder(null);
        setSelectedActivationData(null);
        setSelectedInstallationData(null);
        setSelectedPaymentData(null);

        setTechCamtelName([]);
        setTechPartnerName([]);
        setComCamtelName([]);
        setComPartnerName([]);

        setSelectedOrder(order);

        setIsDetailsVisible(false);

        if (token && order.idPartnerTechnician && order.idPartnerTechnician !== 0) {
            const techPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerTechnician, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setTechPartnerName(techPartnerProfile.data.profile[0]);
        } else {
            console.log('Pas de technicien assigné à cette commande.');
        }

        if (token && order && order.idPartnerCommercial && order.idPartnerCommercial !== 0) {
            const comPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerCommercial, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setComPartnerName(comPartnerProfile.data.profile[0]);
        } else {
            console.log('Commande autoprospectée ou par CAMTEL.');
        }

        if (token) {
            try {
                const response = await axios.get(`${BASE_URL}/api/orders/details/` + order.idSheet, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });

                const appointmentData = response.data[order.idSheet].appointmentData[0];
                setSelectedAppointmentOrder(appointmentData);

                const orderData = response.data[order.idSheet].preStudyOrderData[0];
                setSelectedPreStudyOrder(orderData);

                if (orderData.idTechnicienCamtel && orderData.idTechnicienCamtel !== 0) {
                    const techCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + orderData.idTechnicienCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setTechCamtelName(techCamtelProfile.data.profile[0]);
                } else {
                    console.log('Pré-étude de la commande non validée par le Pool Technique de CAMTEL.');
                }

                setSelectedInstallationData(response.data[order.idSheet].installationData[0]);
                setSelectedActivationData(response.data[order.idSheet].activationData[0]);
                setSelectedPaymentData(response.data[order.idSheet].paymentData[0]);

                if (response.data[order.idSheet].activationData[0] && response.data[order.idSheet].activationData[0].idCommercialCamtel !== 0 && response.data[order.idSheet].activationData[0].idCommercialCamtel !== null) {
                    const comCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + response.data[order.idSheet].activationData[0].idCommercialCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setComCamtelName(comCamtelProfile.data.profile[0]);
                } else {
                    console.log('Commande non activée par le Pool Commercial de CAMTEL.');
                }

                const idBindedEntity = orderData.idBindedEntity;
                const entityResponse = await axios.get(`${BASE_URL}/api/entities/details/` + idBindedEntity, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                const entityData = entityResponse.data.entities;
                setDetails(entityData[0]);

            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
            }
        };
    }

    const handleDateChange = (date) => {
        setPaymentDate(date);
    };

    // CAMTEL
    const pursueCommercialCamtelProcedure = async (order) => {
        // setLoading(true);

        // const ciData = {
        //     "cniId": order.idCardNumber,
        //     "passportId": order.idPassportNumber,
        //     "residenceId": order.idResidencePermitNumber
        // }

        // const response = await axios.post(`${BASE_URL}/api/crm/customer-info`, ciData);

        // if (response.data.error) {
        //     alert(response.data.error);
        //     setLoading(false);
        // } else {
        //     localStorage.setItem('customerNoCreated', JSON.stringify({ idSheet: order.idSheet, customerNo: response.data.customerNo }));

        setSelectedOrder(null);
        setSelectedPreStudyOrder(null);
        setSelectedActivationData(null);

        setSelectedOrderId(order.idSheet);
        setSelectedOrderEmail(order.email);
        setSelectedOrderEmailEntity(order.emailEntity);
        setSelectedOrderNameEntity(order.nameEntity);
        setSelectedOrderName(order.nameOrSocialName);
        setSelectedOrderOffer(order.offerBlueMaxSelected);
        setSelectedOrderLocalisation(order.localisation);

        setIsAddctivationVisible(false);

        setShowCommercialCamtelForm(true);

        // setLoading(false);
        // }
    }

    const handleComCamtelFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = {
            idSheet: selectedOrderId,
            cbsOrderId: e.target.cbsOrderId ? e.target.cbsOrderId.value : '',
            emailEntity: selectedOrderEmailEntity,
            nameEntity: selectedOrderNameEntity,
            name: selectedOrderName,
            offer: selectedOrderOffer,
            localisation: selectedOrderLocalisation,
            idCommercialCamtel: localStorage.getItem('profileId'),
            crmCustomerCode: e.target.crmCustomerCode ? e.target.crmCustomerCode.value : '',
            billingAccount: e.target.billingAccount ? e.target.billingAccount.value : '',
            phoneNumberActivated: e.target.phoneNumberActivated ? e.target.phoneNumberActivated.value : '',
            ontNumber: e.target.ontNumber ? e.target.ontNumber.value : '',
            paymentRef: e.target.paymentRef ? e.target.paymentRef.value : '',
            paymentDate: paymentDate ? format(paymentDate, 'yyyy-MM-dd HH:mm:ss') : null,
            stbIpStickNumber: e.target.stbIpStickNumber ? e.target.stbIpStickNumber.value : ''
        };

        if (token) {
            try {
                axios.post(`${BASE_URL}/api/activation`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                setShowCommercialCamtelForm(false);

                setSelectedOrder(null);
                setSelectedOrderId(null);
                setSelectedOrderEmailEntity(null);
                setSelectedOrderNameEntity(null);
                setSelectedOrderName(null);
                setSelectedOrderOffer(null);
                setSelectedOrderLocalisation(null);

                setIsAddctivationVisible(true);

                setIsDetailsVisible(true);
                setLoading(false);

                alert('Données d\'activation inserée avec succès.');
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire :', error);
                setLoading(false);

                alert('Echec de l\'insertion des données d\'activation.');
            }
        }
    };

    const handleEditDateChange = (date) => {
        setEditedPaymentDate(date);
    };

    const formatDateForDatePicker = (dateString) => {
        const date = new Date(dateString);
        return date;
    };

    const handleEdit = (order) => {
        setSelectedOrder(null);
        setSelectedPreStudyOrder(null)
        setSelectedActivationData(null);
        setSelectedInstallationData(null)

        setEditingActivation(order);
        setEditedCbsOrderId(order.cbsOrderId);
        setEditedCrmCustomerCode(order.crmCustomerCode);
        setEditedBillingAccount(order.billingAccount);
        setEditedPhoneNumberActivated(order.phoneNumberActivated);
        setEditedOntNumber(order.ontNumber);
        setEditedPaymentRef(order.paymentRef);
        setEditedPaymentDate(formatDateForDatePicker(order.paymentDate));
        setEditedStbIpStickNumber(order.stbIpStickNumber);

        setIsEditActivationVisible(false);
        setShowActivationEditForm(true);
    }

    const handleSelectChange = (e) => {
        const newValue = e.target.value;
        setEditedRegion(newValue);
    }

    const handleSelectChangeOffer = (e) => {
        const newValue = e.target.value;
        setEditedOffer(newValue);
    }

    const handleEditOrder = (order) => {
        setEditingOrder(order);
        setEditedCustomerPhoto(order.customerPhoto);
        setOldCustomerPhotoFileName(order.customerPhoto);

        setEditedNameOrSocialName(order.nameOrSocialName);
        setEditedCustomerType(order.customerType);
        setEditedRegion(order.region);
        setEditedCity(order.city);
        setEditedLocalisation(order.localisation);
        setEditedLieuDit(order.lieuDit);
        setEditedCustomerGpsCoordinates(order.customerGpsCoordinates);
        setEditedImageLocalisation(order.imageLocalisation);
        setOldImageLocalisationFileName(order.imageLocalisation);

        setEditedEmail(order.email);
        setEditedMobile(order.mobile);
        setEditedIdCardNumber(order.idCardNumber);
        setEditedIdPassportNumber(order.idPassportNumber);
        setEditedIdResidencePermitNumber(order.idResidencePermitNumber);
        setEditedIdResidencePermitFront(order.idResidencePermitFront);
        setOldIdResidencePermitFrontFileName(order.idResidencePermitFront);

        setEditedRectoCni(order.rectoCni);
        setEditedVersoCni(order.versoCni);
        setOldRectoCniFileName(order.rectoCni);
        setOldVersoCniFileName(order.versoCni);

        setEditedIdPassportFront(order.idPasseportFront);
        setOldIdPassportFrontFileName(order.idPasseportFront);

        setEditedCommerceRegNumber(order.commerceRegNumber);
        setEditedRectoCommerceReg(order.rectoCommerceReg);
        setOldRectoCommerceRegFileName(order.rectoCommerceReg);

        setEditedOffer(order.offerBlueMaxSelected);

        setIsDetailsVisible(true);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setDisplayCustomerPhoto(imageUrl);
            setEditedCustomerPhoto(file);
        }
    }

    const handleFileChangeLocalisation = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayImageLocalisation(fileToDisplay);
            setEditedImageLocalisation(file);
        }
    }

    const handleFileChangeRectoCni = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayRectoCni(fileToDisplay);
            setEditedRectoCni(file);
        }
    }

    const handleFileChangeVersoCni = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayVersoCni(fileToDisplay);
            setEditedVersoCni(file);
        }
    }

    const handleFileChangePassport = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayIdPassportFront(fileToDisplay);
            setEditedIdPassportFront(file);
        }
    };

    const handleFileChangeIdResidencePermit = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayIdResidencePermitFront(fileToDisplay);
            setEditedIdResidencePermitFront(file);
        }
    };

    const handleFileChangeReg = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayRectoCommerceReg(fileToDisplay);
            setEditedRectoCommerceReg(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = {
            oldCustomerPhoto: oldCustomerPhotoFileName,
            customerPhoto: editedCustomerPhoto,
            nameOrSocialName: editedNameOrSocialName,
            customerType: editedCustomerType,
            region: editedRegion,
            city: editedCity,
            editedLocalisation: editedLocalisation,
            lieuDit: editedLieuDit,
            customerGpsCoordinates: editedCustomerGpsCoordinates,
            oldPreciseLocationFile: oldImageLocalisationFileName,
            preciseLocationFile: editedImageLocalisation,
            email: editedEmail,
            mobile: editedMobile,
            idCardNumber: editedIdCardNumber,
            idPassportNumber: editedIdPassportNumber,
            idResidencePermitNumber: editedIdResidencePermitNumber,
            oldIdCardFront: oldRectoCniFileName,
            oldIdCardBack: oldVersoCniFileName,
            idCardFront: editedRectoCni,
            idCardBack: editedVersoCni,
            idPasseportFront: editedIdPassportFront,
            oldIdPasseportFront: oldIdPassportFrontFileName,
            idResidencePermitFront: editedIdResidencePermitFront,
            oldIdResidencePermitFront: oldIdResidencePermitFrontFileName,
            regComNumber: editedCommerceRegNumber,
            oldCommerceRegistryFront: oldRecotCommerceRegFileName,
            commerceRegistryFront: editedRectoCommerceReg,
            offerBlueMaxSelected: editedOffer
        }

        if (token) {
            try {
                const response = await axios.patch(`${BASE_URL}/api/orders/superadmin/${editingOrder.idSheet}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLoading(false);
                alert('Commande mis à jour avec succès.');
                setIsDetailsVisible(false);
                setSelectedOrder(null);
                setIsDetailsVisible(true);
            } catch (error) {
                setLoading(false);
                alert('Erreur lors de la mise à jour de la commande.');
                console.error("Erreur lors de la mise à jour de la commande: ", error);
            }
        }
    }

    function convertDateFormat(dateString) {
        const [day, month, year] = dateString.split('/');
        return `${month}/${day}/${year}`;
    }

    const handleFormEditSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const editedPaymentDateValue = e.target.editedPaymentDate.value;
        const convertedDate = convertDateFormat(editedPaymentDateValue);
        const dateObject = new Date(convertedDate);

        const formData = {
            idSheet: e.target.orderIdSheet.value,
            cbsOrderId: e.target.editedCbsOrderId.value,
            crmCustomerCode: e.target.editedCrmCustomerCode.value,
            billingAccount: e.target.editedBillingAccount.value,
            phoneNumberActivated: e.target.editedPhoneNumberActivated.value,
            ontNumber: e.target.editedOntNumber.value,
            paymentRef: e.target.editedPaymentRef.value,
            paymentDate: dateObject ? format(dateObject, 'yyyy-MM-dd HH:mm:ss') : null,
            stbIpStickNumber: e.target.editedStbIpStickNumber.value
        };

        try {
            await axios.patch(`${BASE_URL}/api/activation/`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            setIsDetailsVisible(true);
            setShowActivationEditForm(false);
            setIsEditActivationVisible(true);

            setLoading(false);
            alert('Activation modifiée avec succès.');

        } catch (error) {
            setLoading(false);
            console.log('Erreur lors de la soumission du formulaire :', error);
            alert('Erreur lors de la modification de l\'activation. Veuillez réessayer...');
        }
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    const displayDocId = (order) => {

        const customerData = {
            "idSheet": order.idSheet,
            "cniId": order.idCardNumber,
            "passportId": order.idPassportNumber,
            "residenceId": order.idResidencePermitNumber,
            "customerName": order.nameOrSocialName,
            "email": order.email,
            "phoneNumber": order.mobile,
            "region": order.region,
            "preciseLocation": order.localisation,
            "lieuDit": order.lieuDit
        }

        localStorage.setItem('customerData', JSON.stringify(customerData));

        if (order.idCardNumber) {
            setCni({ idSheet: order.idSheet, idCardNumber: order.idCardNumber, rectoCni: order.rectoCni, versoCni: order.versoCni });
            setModalIsOpen(true);
        } else if (order.idPassportNumber) {
            setPassport({ idSheet: order.idSheet, idPassportNumber: order.idPassportNumber, idPasseportFront: order.idPasseportFront });
            setModalIsOpen(true);
        } else if (order.idResidencePermitNumber) {
            setResidentPermit({ idSheet: order.idSheet, idResidencePermitNumber: order.idResidencePermitNumber, idResidencePermitFront: order.idResidencePermitFront });
            setModalIsOpen(true);
        } else {
            alert('Aucun document d\'identification trouvé pour cette demande.');
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    };

    const handleSubmitToCrm = async (e) => {
        e.preventDefault();

        setLoading(true);

        const customerData = JSON.parse(localStorage.getItem('customerData'));

        const formattedDateOfBirth = formatDate(dateOfBirth);
        const formattedIssueDate = formatDate(issueDate);
        const formattedExpirationDate = formatDate(expirationDate);

        const data = {
            "cniId": customerData.cniId,
            "passportId": customerData.passportId,
            "residenceId": customerData.residenceId,
            "customerName": customerData.customerName,
            "email": customerData.email,
            "phoneNumber": customerData.phoneNumber,
            "region": customerData.region,
            "preciseLocation": customerData.preciseLocation,
            "lieuDit": customerData.lieuDit,
            "title": title,
            "gender": gender,
            "dateOfBirth": formattedDateOfBirth,
            "issueDate": formattedIssueDate,
            "expirationDate": formattedExpirationDate,
        }

        if (token) {
            const response = await axios.post(`${BASE_URL}/api/crm/create-customer`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.code === '405000000') {
                const ciData = {
                    "cniId": customerData.cniId,
                    "passportId": customerData.passportId,
                    "residenceId": customerData.residenceId
                }

                const response = await axios.post(`${BASE_URL}/api/crm/customer-info`, ciData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                localStorage.setItem('customerNoCreated', JSON.stringify({ idSheet: customerData.idSheet, customerNo: response.data.customerNo }));

                alert('Client inséré avec succès dans le CRM.');
                setLoading(false);
                setModalIsOpen(false);
            } else if (response.data.code === "500000002") {
                alert("Ce client existe déjà. Veuillez mettre à jour ses informations directement dans le CRM.");
                setLoading(false);
                setModalIsOpen(false);
            } else {
                alert("Erreur inattendue. Veuillez vérifiez votre connexion internet et assurez d'être dans le réseau d'entreprise.");
                setLoading(true);
            }
        }
    };

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                        <div className="w-full">
                            {isDetailsVisible && isAddActivationVisible && isEditActivationVisible && !editingOrder && (
                                <>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des commandes</h1>

                                    <div className="flex flex-col md:flex-row justify-between mb-4">
                                        <div className="flex items-center mb-4 md:mb-0">
                                            <div className="font-bold mr-2">Filtrer par: </div>
                                            <select
                                                className="border border-gray-300 py-2 px-4 rounded-lg"
                                                value={filterStatus}
                                                onChange={handleFilterChange}
                                            >
                                                <option value="ALL">Tous</option>
                                                <option value="PAID">Commande payée</option>
                                                <option value="UNPAID">Commande non payée</option>
                                            </select>
                                            <span className="ml-2 text-gray-600">({filteredOrders.length})</span>
                                        </div>

                                        <div className="w-full md:w-[50%]">
                                            <label className="relative block">
                                                <span className="sr-only">Search</span>
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search">
                                                        <circle cx="11" cy="11" r="8" />
                                                        <path d="m21 21-4.3-4.3" />
                                                    </svg>
                                                </span>
                                                <input
                                                    className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                    type="text"
                                                    placeholder="Rechercher par nom du client"
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                            </label>
                                        </div>
                                    </div>


                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-2 py-2">Date de souscription</th>
                                                <th className="px-2 py-2">Nom du partenaire</th>
                                                <th className="px-2 py-2">Nom du client</th>
                                                <th className="px-2 py-2">Statut de la pré-étude</th>
                                                <th className="px-2 py-2">Statut de l'étude</th>
                                                <th className="px-2 py-2">Statut du paiement</th>
                                                <th className="px-2 py-2">Statut de l'activation</th>
                                                <th colSpan={2} className="px-4 py-2">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {filteredOrders.length > 0 ? (
                                                filteredOrders.map((entry, key) => {
                                                    const isActivated = entry.activationData.some(activation => activation.idActivation !== null && activation.idActivation !== '' && activation.idActivation !== 0);
                                                    const hasSuccessfulPayment = entry.paymentData.some(payment => payment.status === 'SUCCESSFULL');

                                                    return (
                                                        <tr key={key} className="border-b hover:bg-gray-100">
                                                            <td className="px-2 py-2">{<FormatDate dateStr={entry.preStudyOrder[0].sheetCreatedAt} />}</td>
                                                            <td className="px-2 py-2">{entry.preStudyOrder[0].nameEntity}</td>
                                                            <td className="px-2 py-2">{entry.preStudyOrder[0].nameOrSocialName}</td>
                                                            <td className="px-2 py-2">{entry.preStudyOrder[0].result || 'Pré-Étude non réalisée'}</td>
                                                            <td className="px-2 py-2">
                                                                {entry.preStudyOrder[0].validation || (entry.preStudyOrder[0].result ? 'Étude non réalisée' : 'En attente de la réalisation de la pré-étude')}
                                                            </td>
                                                            <td className={`px-2 py-2 text-center ${isActivated || hasSuccessfulPayment ? 'text-green-500 font-bold' : 'text-red-500 font-bold'}`}>
                                                                {isActivated
                                                                    ? 'Commande activée et payée'
                                                                    : hasSuccessfulPayment
                                                                        ? 'Commande payée'
                                                                        : 'Commande en attente de paiement'}
                                                            </td>
                                                            <td className="px-2 py-2 text-center">
                                                                {entry.activationData[0] ? (entry.activationData[0].idActivation ? 'Service activée' : 'Service non activée') : 'Service non activée'}
                                                            </td>
                                                            <td className="px-2 py-2 text-center">
                                                                {entry.preStudyOrder && entry.preStudyOrder.length > 0 ? (
                                                                    <button
                                                                        onClick={() => { showOrderDetails(entry.preStudyOrder[0]); }}
                                                                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 m-3"
                                                                    >
                                                                        Détails
                                                                    </button>
                                                                ) : null}
                                                                {entry.installationData.length > 0 && entry.installationData[0].idInstallation && (
                                                                    <Link
                                                                        to={`/fiche/${entry.installationData[0].idSheet}`}
                                                                        className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 m-3 whitespace-nowrap"
                                                                    >
                                                                        Prévisualiser la fiche
                                                                    </Link>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center p-4">Aucune commande disponible pour ce filtre.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            )}

                            {selectedOrder && !isDetailsVisible && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end mb-2">
                                        <div>
                                            {/* <button
                                                onClick={() => {
                                                    displayDocId(selectedOrder);
                                                }}
                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold p-2 m-1"
                                            >
                                                Envoyer au CRM
                                            </button> */}

                                            <Modal
                                                isOpen={modalIsOpen}
                                                onRequestClose={() => setModalIsOpen(false)}
                                                contentLabel="Informations supplémentaires CRM"
                                            >
                                                <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">Informations supplémentaires CRM</h2>
                                                <div className="font-montserrat flex flex-col items-center space-y-4">
                                                    <p className="text-center">Veuillez compléter les informations supplémentaires de création de l'utilisateur CRM en renseignant les champs ci-dessous:</p>
                                                    {cni.idCardNumber ? (
                                                        <form className="bg-white shadow-2xl rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md" onSubmit={handleSubmitToCrm}>
                                                            <img src={cni.rectoCni} height={300} width={400} alt="Image du recto CNI" className="my-3 border" />
                                                            <img src={cni.versoCni} height={300} width={400} alt="Image du verso CNI" className="my-3 border" />

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="title">
                                                                    Civilité <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="title"
                                                                    value={title}
                                                                    onChange={(e) => setTitle(e.target.value)}
                                                                    required
                                                                >
                                                                    <option value="">Sélectionner</option>
                                                                    <option value="500201604">M.</option>
                                                                    <option value="500201602">Mme</option>
                                                                    <option value="500201603">Mlle</option>
                                                                    <option value="500201605">Dr</option>
                                                                    <option value="500201606">Rév</option>
                                                                    <option value="500201601">Prof</option>
                                                                    <option value="500201600">Hon</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="gender">
                                                                    Sexe <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <div className="flex items-center">
                                                                    <label className="mr-4">
                                                                        <input
                                                                            className="mr-2"
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="M"
                                                                            checked={gender === 'M'}
                                                                            onChange={(e) => setGender(e.target.value)}
                                                                            required
                                                                        />
                                                                        Masculin
                                                                    </label>
                                                                    <label>
                                                                        <input
                                                                            className="mr-2"
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="F"
                                                                            checked={gender === 'F'}
                                                                            onChange={(e) => setGender(e.target.value)}
                                                                        />
                                                                        Féminin
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="dateOfBirth">
                                                                    Date de naissance <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="dateOfBirth"
                                                                    type="date"
                                                                    value={dateOfBirth}
                                                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="issueDate">
                                                                    Date de délivrance de la CNI <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="issueDate"
                                                                    type="date"
                                                                    value={issueDate}
                                                                    onChange={(e) => setIssueDate(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="expirationDate">
                                                                    Date d'expiration d'expiration de la CNI <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="expirationDate"
                                                                    type="date"
                                                                    value={expirationDate}
                                                                    onChange={(e) => setExpirationDate(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="flex items-center justify-end">
                                                                <button
                                                                    className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    type="submit"
                                                                >
                                                                    Envoyer
                                                                </button>
                                                            </div>
                                                        </form>
                                                    ) : passport.idPassportNumber ? (
                                                        <form className="bg-white shadow-2xl rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md" onSubmit={handleSubmitToCrm}>
                                                            <img src={passport.idPasseportFront} height={300} width={400} alt="Image du passeport" className="my-3 border" />

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="title">
                                                                    Civilité <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="title"
                                                                    value={title}
                                                                    onChange={(e) => setTitle(e.target.value)}
                                                                    required
                                                                >
                                                                    <option value="">Sélectionner</option>
                                                                    <option value="500201604">M.</option>
                                                                    <option value="500201602">Mme</option>
                                                                    <option value="500201603">Mlle</option>
                                                                    <option value="500201605">Dr</option>
                                                                    <option value="500201606">Rév</option>
                                                                    <option value="500201601">Prof</option>
                                                                    <option value="500201600">Hon</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="gender">
                                                                    Sexe <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <div className="flex items-center">
                                                                    <label className="mr-4">
                                                                        <input
                                                                            className="mr-2"
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="M"
                                                                            checked={gender === 'M'}
                                                                            onChange={(e) => setGender(e.target.value)}
                                                                            required
                                                                        />
                                                                        Masculin
                                                                    </label>
                                                                    <label>
                                                                        <input
                                                                            className="mr-2"
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="F"
                                                                            checked={gender === 'F'}
                                                                            onChange={(e) => setGender(e.target.value)}
                                                                        />
                                                                        Féminin
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="dateOfBirth">
                                                                    Date de naissance <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="dateOfBirth"
                                                                    type="date"
                                                                    value={dateOfBirth}
                                                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="issueDate">
                                                                    Date de délivrance du passeport <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="issueDate"
                                                                    type="date"
                                                                    value={issueDate}
                                                                    onChange={(e) => setIssueDate(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="expirationDate">
                                                                    Date d'expiration du passeport <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="expirationDate"
                                                                    type="date"
                                                                    value={expirationDate}
                                                                    onChange={(e) => setExpirationDate(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="flex items-center justify-end">
                                                                <button
                                                                    className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    type="submit"
                                                                >
                                                                    Envoyer
                                                                </button>
                                                            </div>
                                                        </form>
                                                    ) : residentPermit.idResidencePermitNumber ? (
                                                        <form className="bg-white shadow-2xl rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md" onSubmit={handleSubmitToCrm}>
                                                            <img src={residentPermit.idResidencePermitFront} height={300} width={400} alt="Image de la Carte de résidence" className="my-3 border" />

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="title">
                                                                    Civilité <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="title"
                                                                    value={title}
                                                                    onChange={(e) => setTitle(e.target.value)}
                                                                    required
                                                                >
                                                                    <option value="">Sélectionner</option>
                                                                    <option value="500201604">M.</option>
                                                                    <option value="500201602">Mme</option>
                                                                    <option value="500201603">Mlle</option>
                                                                    <option value="500201605">Dr</option>
                                                                    <option value="500201606">Rév</option>
                                                                    <option value="500201601">Prof</option>
                                                                    <option value="500201600">Hon</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="gender">
                                                                    Sexe <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <div className="flex items-center">
                                                                    <label className="mr-4">
                                                                        <input
                                                                            className="mr-2"
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="M"
                                                                            checked={gender === 'M'}
                                                                            onChange={(e) => setGender(e.target.value)}
                                                                            required
                                                                        />
                                                                        Masculin
                                                                    </label>
                                                                    <label>
                                                                        <input
                                                                            className="mr-2"
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="F"
                                                                            checked={gender === 'F'}
                                                                            onChange={(e) => setGender(e.target.value)}
                                                                        />
                                                                        Féminin
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="dateOfBirth">
                                                                    Date de naissance <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="dateOfBirth"
                                                                    type="date"
                                                                    value={dateOfBirth}
                                                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="issueDate">
                                                                    Date de délivrance de la carte de résidence <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="issueDate"
                                                                    type="date"
                                                                    value={issueDate}
                                                                    onChange={(e) => setIssueDate(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="expirationDate">
                                                                    Date d'expiration d'expiration de la carte de résidence <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="expirationDate"
                                                                    type="date"
                                                                    value={expirationDate}
                                                                    onChange={(e) => setExpirationDate(e.target.value)}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="flex items-center justify-end">
                                                                <button
                                                                    className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                    type="submit"
                                                                >
                                                                    Envoyer
                                                                </button>
                                                            </div>
                                                        </form>
                                                    ) : null}
                                                </div>
                                            </Modal>

                                            {selectedActivationData && selectedActivationData.crmCustomerCode !== null && selectedActivationData.crmCustomerCode !== '' && selectedInstalationData.idInstallation === null && (
                                                <button
                                                    onClick={() => {
                                                        handleEdit(selectedActivationData)
                                                    }}
                                                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold p-2 mr-2"
                                                >
                                                    Modifier l'activation
                                                </button>
                                            )}

                                            {selectedPaymentData && selectedPaymentData.idPaidOrder && selectedPaymentData.status === 'SUCCESSFULL' && (
                                                <button
                                                    className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 m-1"
                                                    onClick={() => window.open(`/receipt/${selectedPaymentData.payToken}`, '_blank', 'noopener,noreferrer')}
                                                >
                                                    Visualiser le reçu de paiement
                                                </button>
                                            )}

                                            {selectedPreStudyOrder && selectedPreStudyOrder.validation === 'Favorable' && !selectedActivationData && (
                                                <button
                                                    onClick={() => { pursueCommercialCamtelProcedure(selectedOrder); }}
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 mr-2"
                                                >
                                                    Poursuivre la procédure
                                                </button>
                                            )}

                                            <button
                                                onClick={() => handleEditOrder(selectedOrder)}
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 mr-2"
                                            >
                                                Modifier
                                            </button>

                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => setIsDetailsVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-3xl font-bold mb-4 uppercase text-center">Détails de la commande</h2>
                                    {/* {localStorage.getItem('userRole') === 'admin_partner' && ( */}
                                    <>
                                        {comPartnerName && comPartnerName.nom ? <p><strong>Commercial du partenaire ayant prospecté la commande: </strong> {comPartnerName.nom + ' ' + comPartnerName.prenom}</p> : <p><strong>Client assignée au partenaire par CAMTEL.</strong></p>}
                                        {techPartnerName && techPartnerName.nom ? <p><strong>Technicien du partenaire en charge de la pré-étude et de l'installation de la commande :</strong> {techPartnerName.nom + ' ' + techPartnerName.prenom}</p> : <p><strong>Commande non assignée à un technicien (Partenaire) pour pré-étude.</strong></p>}
                                    </>
                                    {/* )} */}
                                    {/* {localStorage.getItem('userRole') === 'admin_camtel' && ( */}
                                    <>
                                        {techCamtelName && techCamtelName.nom ? <p><strong>Pool Technique CAMTEL en charge de l'étude: </strong> {techCamtelName.nom + ' ' + techCamtelName.prenom}</p> : <p><strong>Étude pas encore validée par le Pool Technique CAMTEL</strong></p>}
                                        {comCamtelName && comCamtelName.nom ? <p><strong>Pool Commercial CAMTEL en charge de l'activation de cette commande: </strong> {comCamtelName.nom + ' ' + comCamtelName.prenom}</p> : <p><strong>Commande non activée par le Pool Commercial CAMTEL.</strong></p>}
                                    </>
                                    {/* )} */}

                                    <p><strong>Date de souscription du client: </strong><FormatDate dateStr={selectedOrder.sheetCreatedAt} /></p>
                                    <p><strong>Date de RDV d'installation du client: </strong>{selectedAppointmentOrder ? <FormatDate dateStr={selectedAppointmentOrder.appointmentDateTime} /> : 'Installation non programmée par le client.'}</p>

                                    {/* Afficher la photo du client */}
                                    {selectedOrder.customerPhoto && (
                                        <div>
                                            <strong>Photo du client:</strong>
                                            <img
                                                src={selectedOrder.customerPhoto}
                                                alt="Photo du client"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 200, width: 200 }}
                                            />
                                        </div>
                                    )}
                                    <h3 className="text-2xl font-bold mb-2">INFORMATIONS CLIENT</h3>
                                    <p><strong>N°ID Souscription :</strong> {selectedOrder.idSubscription + selectedOrder.idSheet}</p>
                                    <p><strong>Nom et Prénoms / Raison Sociale :</strong> {selectedOrder.nameOrSocialName}</p>
                                    <p><strong>Type de Client:</strong> {selectedOrder.customerType}</p>
                                    <p>
                                        <strong>Région:</strong>
                                        {
                                            selectedOrder.region === 'CE' ? 'Centre' :
                                                selectedOrder.region === 'LT' ? 'Littoral' :
                                                    selectedOrder.region === 'NO' ? 'Nord' :
                                                        selectedOrder.region === 'EN' ? 'Extrême-Nord' :
                                                            selectedOrder.region === 'AD' ? 'Adamaoua' :
                                                                selectedOrder.region === 'ES' ? 'Est' :
                                                                    selectedOrder.region === 'SU' ? 'SUD' :
                                                                        selectedOrder.region === 'OU' ? 'Ouest' :
                                                                            selectedOrder.region === 'NW' ? 'Nord-Ouest' :
                                                                                'Sud-Ouest'
                                        }
                                    </p>
                                    <p><strong>Ville:</strong> {selectedOrder.city}</p>
                                    <p><strong>Localisation Précise:</strong> {selectedOrder.localisation}</p>
                                    <p><strong>Lieu-dit:</strong> {selectedOrder.lieuDit}</p>
                                    {selectedOrder.customerGpsCoordinates && (<p><strong>Coordonnées GPS: </strong>{selectedOrder.customerGpsCoordinates}</p>)}
                                    <p>
                                        <strong>Plan de localisation:</strong>
                                        {selectedOrder.imageLocalisation && (selectedOrder.imageLocalisation.endsWith('.jpg') || selectedOrder.imageLocalisation.endsWith('.png')) ? (
                                            <img
                                                src={selectedOrder.imageLocalisation}
                                                alt="Plan de localisation"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 500, width: '100%' }}
                                            />
                                        ) : (
                                            <iframe
                                                src={selectedOrder.imageLocalisation}
                                                width="100%"
                                                height="500"
                                                title="PDF Viewer"
                                            ></iframe>
                                        )}
                                    </p>
                                    <p><strong>Email:</strong> {selectedOrder.email}</p>
                                    <p><strong>N° Mobile:</strong> {selectedOrder.mobile}</p>
                                    {selectedOrder.idCardNumber !== null && (
                                        <>
                                            <p><strong>Numéro de la CNI:</strong> {selectedOrder.idCardNumber}</p>
                                            <p>
                                                <strong>Carte Nationnale d'identité:</strong>
                                                <div className="flex">
                                                    {selectedOrder.rectoCni && (selectedOrder.rectoCni.endsWith('.jpg') || selectedOrder.rectoCni.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.rectoCni}
                                                            alt="Image Recto de la CNI"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.rectoCni}
                                                            width="50%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}

                                                    {selectedOrder.versoCni && (selectedOrder.versoCni.endsWith('.jpg') || selectedOrder.versoCni.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.versoCni}
                                                            alt="Image Verso de la CNI"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.versoCni}
                                                            width="50%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.idPassportNumber !== null && selectedOrder.idPassportNumber !== '' && (
                                        <>
                                            <p><strong>Numéro du passeport:</strong> {selectedOrder.idPassportNumber}</p>
                                            <p>
                                                <strong>Passeport:</strong>
                                                {selectedOrder.idPasseportFront && (selectedOrder.idPasseportFront.endsWith('.jpg') || selectedOrder.idPasseportFront.endsWith('.png')) ? (
                                                    <img
                                                        src={selectedOrder.idPasseportFront}
                                                        alt="Image Recto du passeport"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 250, width: '50%' }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={selectedOrder.idPasseportFront}
                                                        width="100%"
                                                        height="300"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.idResidencePermitNumber !== null && (
                                        <>
                                            <p><strong>Numéro de la carte de séjour:</strong> {selectedOrder.idResidencePermitNumber}</p>
                                            <p>
                                                <strong>Carte de séjour:</strong>
                                                <div className="flex">
                                                    {selectedOrder.idResidencePermitFront && (selectedOrder.idResidencePermitFront.endsWith('.jpg') || selectedOrder.idResidencePermitFront.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.idResidencePermitFront}
                                                            alt="Image Recto de la carte de séjour"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.idResidencePermitFront}
                                                            width="100%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.commerceRegNumber !== '' && (
                                        <>
                                            <p><strong>N°Régistre Commerce :</strong> {selectedOrder.commerceRegNumber}</p>
                                            <p>
                                                <strong>Image du registre de commerce:</strong>
                                                <div className="flex">
                                                    {selectedOrder.rectoCommerceReg && (selectedOrder.rectoCommerceReg.endsWith('.jpg') || selectedOrder.rectoCommerceReg.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.rectoCommerceReg}
                                                            alt="Image du registre de commerce"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 'auto', width: '100%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.rectoCommerceReg}
                                                            width="100%"
                                                            height="500"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}

                                    <h3 className="text-2xl font-bold mt-2">TYPE D’OPERATION</h3>
                                    <p><strong>Formule d’abonnement choisie:</strong> {selectedOrder.offerBlueMaxSelected}</p>
                                </div>
                            )}

                            {selectedPreStudyOrder && !isDetailsVisible && (
                                <>
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-3xl font-bold mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                                        <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                                        <p><strong>Nom du prestataire :</strong> {selectedPreStudyOrder.name}</p>
                                        <p><strong>Tel :</strong> {selectedPreStudyOrder.telephoneEntity}</p>
                                        <p><strong>Email :</strong> {selectedPreStudyOrder.emailEntity}</p>
                                        {
                                            details && (
                                                <>
                                                    <p><strong>Zone d’intervention :</strong> {details.interventionZone}</p>
                                                    <p><strong>Agence Commerciale :</strong> {details.name}</p>
                                                </>
                                            )
                                        }
                                        <p><strong>Référence du FDT :</strong> {selectedPreStudyOrder.fdtRef}</p>
                                        <p><strong>Référence du FAT :</strong> {selectedPreStudyOrder.fatRef}</p>
                                        <p><strong>Distance FAT-Client (m) :</strong> {selectedPreStudyOrder.dFatCustomer}</p>
                                        <p><strong>Nombre de poteau à installer :</strong> {selectedPreStudyOrder.nbPoToInstall}</p>
                                    </div>
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-3xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE LA PRE-ETUDE</h3>
                                        <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                                        <p><strong>Statut de la validation par le technicien du prestataire:</strong> {selectedPreStudyOrder.result}</p>
                                        <p><strong>Observations fait par le technicien du prestataire:</strong> {selectedPreStudyOrder.preEtudeObs}</p>
                                    </div>
                                    {
                                        (selectedPreStudyOrder.validation !== '' || selectedPreStudyOrder.etudeObs !== '') && (
                                            <div className="mt-4 border p-4">
                                                <h3 className="text-3xl font-bold mb-2 mt-2 text-center">VALIDATION ET OBSERVATIONS DE L’ETUDE</h3>
                                                <p><strong>Date de validation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                                                {selectedPreStudyOrder.validation !== '' && (
                                                    <p><strong>Statut de la validation par le technicien de CAMTEL:</strong> {selectedPreStudyOrder.validation}</p>
                                                )}
                                                {selectedPreStudyOrder.etudeObs !== '' && (
                                                    <p><strong>Observations de l'étude du technicien de CAMTEL:</strong> {selectedPreStudyOrder.etudeObs}</p>
                                                )}
                                            </div>
                                        )
                                    }
                                </>
                            )}

                            {selectedActivationData && selectedActivationData.crmCustomerCode !== null && selectedActivationData.crmCustomerCode !== '' && !isDetailsVisible && (
                                <div className="mt-4 border p-4">
                                    <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">ACTIVATION</h3>
                                    <p><strong>Date de l'activation: </strong><FormatDate dateStr={selectedActivationData.activationCreatedAt} /></p>
                                    <p><strong>Numéro de la facture d'installation :</strong> {selectedActivationData.cbsOrderId}</p>
                                    <p><strong>Code Client CRM :</strong> {selectedActivationData.crmCustomerCode}</p>
                                    <p><strong>Compte de facturation :</strong> {selectedActivationData.billingAccount}</p>
                                    <p><strong>Numéro fixe de la ligne FTTH du Client :</strong> {selectedActivationData.phoneNumberActivated}</p>
                                    <p><strong>Numéro ONT :</strong> {selectedActivationData.ontNumber}</p>
                                    <p><strong>Référence d'encaissement :</strong> {selectedActivationData.paymentRef}</p>
                                    <p><strong>Date d'encaissement :</strong><FormatDate dateStr={selectedActivationData.paymentDate} /></p>
                                    <p><strong>Numéro STB/IP Stick :</strong> {selectedActivationData.stbIpStickNumber}</p>
                                </div>
                            )}

                            {selectedInstalationData && !isDetailsVisible && (
                                <>
                                    {selectedInstalationData.idTechPartner !== null && (
                                        <div className="mt-4 border p-4">
                                            <h3 className="text-3xl font-bold mb-2 mt-2 text-center">INSTALLATION</h3>
                                            <p><strong>Date de l'installation: </strong><FormatDate dateStr={selectedInstalationData.installationCreatedAt} /></p>
                                            <p><strong>Nombre de Balancelle :</strong> {selectedInstalationData.nbBalancel}</p>
                                            <p><strong>Métrage câble entrée FAT :</strong> {selectedInstalationData.cableMeterEntryFat}</p>
                                            <p><strong>Longueur Câble utilisée (m) :</strong> {selectedInstalationData.usedCableLong}</p>
                                            <p><strong>Nombre d’ATB :</strong> {selectedInstalationData.atbNumber}</p>
                                            <p><strong>Nombre Crochet d’Arrêt :</strong> {selectedInstalationData.nbCrochetArret}</p>
                                            <p><strong>Métrage câble chez l’abonné :</strong> {selectedInstalationData.clientCableMeter}</p>
                                            <p><strong>Nombre de poteau armé installé :</strong> {selectedInstalationData.nbArmedInstalledPoto}</p>
                                            <p><strong>Numéro ONT :</strong> {selectedInstalationData.ontNumber}</p>
                                            <p><strong>Nombre de STB/ Stick :</strong> {selectedInstalationData.stbStickNumber}</p>
                                            <p><strong>Nombre de TB :</strong> {selectedInstalationData.tbNumber}</p>
                                            <p><strong>Nombre de Jarretière :</strong> {selectedInstalationData.nbGarter}</p>
                                            <p><strong>Nombre de Smoove :</strong> {selectedInstalationData.nbSmoove}</p>
                                        </div>
                                    )}
                                </>
                            )}

                            {showCommercialCamtelForm && !isAddActivationVisible && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end">
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => { setIsAddctivationVisible(true); setIsDetailsVisible(true); }}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">ACTIVATION</h3>
                                    <form onSubmit={handleComCamtelFormSubmit}>
                                        <input
                                            type="hidden"
                                            id="orderID"
                                            name="orderID"
                                            value={selectedOrderId}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderEmail"
                                            name="orderEmail"
                                            value={selectedOrderEmail}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderEmailEntity"
                                            name="orderEmailEntity"
                                            value={selectedOrderEmailEntity}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderNameEntity"
                                            name="orderNameEntity"
                                            value={selectedOrderNameEntity}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderName"
                                            name="orderName"
                                            value={selectedOrderName}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderOffer"
                                            name="orderOffer"
                                            value={selectedOrderOffer}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderLocalisation"
                                            name="orderLocalisation"
                                            value={selectedOrderLocalisation}
                                            readOnly
                                        />

                                        <div className="mb-2">
                                            <label htmlFor="cbsOrderId" className="block text-gray-700 font-bold">
                                                Numéro de la facture d'installation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="cbsOrderId"
                                                name="cbsOrderId"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="crmCustomerCode" className="block text-gray-700 font-bold">
                                                Code Client CRM <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="crmCustomerCode"
                                                name="crmCustomerCode"
                                                className="border rounded w-full py-2 px-3"
                                                // value={selectedOrderId === JSON.parse(localStorage.getItem('customerNoCreated')).idSheet ? JSON.parse(localStorage.getItem('customerNoCreated')).customerNo : null}
                                                required
                                            // readOnly
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="billingAccount" className="block text-gray-700 font-bold">
                                                Compte de facturation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="billingAccount"
                                                name="billingAccount"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="phoneNumberActivated" className="block text-gray-700 font-bold">
                                                Numéro fixe de la ligne FTTH du Client <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="phoneNumberActivated"
                                                name="phoneNumberActivated"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="ontNumber" className="block text-gray-700 font-bold">
                                                Numéro ONT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="ontNumber"
                                                name="ontNumber"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="paymentRef" className="block text-gray-700 font-bold">
                                                Référence d'encaissement <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="paymentRef"
                                                name="paymentRef"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="paymentDate" className="block text-gray-700 font-bold">
                                                Date d'encaissement <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <DatePicker
                                                selected={paymentDate}
                                                onChange={handleDateChange}
                                                id="paymentDate"
                                                name="paymentDate"
                                                className="border rounded w-full py-2 px-3"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                required
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="stbIpStickNumber" className="block text-gray-700 font-bold">
                                                Numéro STB/IP Stick <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="stbIpStickNumber"
                                                name="stbIpStickNumber"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Valider
                                        </button>
                                    </form>
                                </div>
                            )}

                            {showActivationEditForm && !isEditActivationVisible && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end">
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => { setIsEditActivationVisible(true); setIsDetailsVisible(true); }}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">MODIFICATION DE L'ACTIVATION</h3>
                                    <form onSubmit={handleFormEditSubmit}>
                                        <input
                                            type="hidden"
                                            id="orderIdSheet"
                                            name="orderIdSheet"
                                            value={editingActivation.idSheet}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderEmail"
                                            name="orderEmail"
                                            value={editingActivation.email}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderEmailEntity"
                                            name="orderEmailEntity"
                                            value={editingActivation.emailEntity}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderNameEntity"
                                            name="orderNameEntity"
                                            value={editingActivation.nameEntity}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderName"
                                            name="orderName"
                                            value={editingActivation.nameOrSocialName}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderOffer"
                                            name="orderOffer"
                                            value={editingActivation.offerBlueMaxSelected}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderLocalisation"
                                            name="orderLocalisation"
                                            value={editingActivation.localisation}
                                            readOnly
                                        />
                                        <div className="mb-2">
                                            <label htmlFor="editedCbsOrderId" className="block text-gray-700 font-bold">
                                                Numéro de la facture d'installation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedCbsOrderId"
                                                name="editedCbsOrderId"
                                                value={editedCbsOrderId}
                                                onChange={(e) => setEditedCbsOrderId(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedCrmCustomerCode" className="block text-gray-700 font-bold">
                                                Code Client CRM <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedCrmCustomerCode"
                                                name="editedCrmCustomerCode"
                                                value={editedCrmCustomerCode}
                                                onChange={(e) => setEditedCrmCustomerCode(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedBillingAccount" className="block text-gray-700 font-bold">
                                                Compte de facturation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedBillingAccount"
                                                name="editedBillingAccount"
                                                value={editedBillingAccount}
                                                onChange={(e) => setEditedBillingAccount(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedPhoneNumberActivated" className="block text-gray-700 font-bold">
                                                Numéro de Telephone <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedPhoneNumberActivated"
                                                name="editedPhoneNumberActivated"
                                                value={editedPhoneNumberActivated}
                                                onChange={(e) => setEditedPhoneNumberActivated(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedOntNumber" className="block text-gray-700 font-bold">
                                                Numéro ONT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedOntNumber"
                                                name="editedOntNumber"
                                                value={editedOntNumber}
                                                onChange={(e) => setEditedOntNumber(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedPaymentRef" className="block text-gray-700 font-bold">
                                                Référence paiement <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedPaymentRef"
                                                name="editedPaymentRef"
                                                value={editedPaymentRef}
                                                onChange={(e) => setEditedPaymentRef(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedPaymentDate" className="block text-gray-700 font-bold">
                                                Date de paiement <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <DatePicker
                                                selected={editedPaymentDate}
                                                onChange={handleEditDateChange}
                                                id="editedPaymentDate"
                                                name="editedPaymentDate"
                                                className="border rounded w-full py-2 px-3"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedStbIpStickNumber" className="block text-gray-700 font-bold">
                                                Numéro STB/IP Stick <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedStbIpStickNumber"
                                                name="editedStbIpStickNumber"
                                                value={editedStbIpStickNumber}
                                                onChange={(e) => setEditedStbIpStickNumber(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Valider
                                        </button>
                                    </form>
                                </div>
                            )}

                            {editingOrder && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end">
                                        <div>
                                            <button
                                                className="close-button bg-yellow-500 hover:bg-yellow-700 p-2 text-white"
                                                onClick={() => setEditingOrder(null)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>

                                    <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Modifier la commande</h2>
                                    <form enctype="multipart/form-data">
                                        <div className="mb-2">
                                            <div>
                                                <strong>Photo du client :</strong>
                                                {displayCustomerPhoto ? (
                                                    <img
                                                        src={displayCustomerPhoto}
                                                        alt="Photo du client"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 200, width: 200 }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={editedCustomerPhoto}
                                                        alt="Photo du client"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 200, width: 200 }}
                                                    />
                                                )}
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/jpeg, image/jpg, image/png"
                                                id="customerPhoto"
                                                name="customerPhoto"
                                                onChange={handleFileChange}
                                                className="rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedNameOrSocialName" className="block text-gray-700 font-bold">
                                                Nom et Prénoms / Raison Sociale
                                            </label>
                                            <input
                                                type="text"
                                                id="editedNameOrSocialName"
                                                name="editedNameOrSocialName"
                                                value={editedNameOrSocialName}
                                                onChange={(e) => setEditedNameOrSocialName(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div>
                                            <div className="mb-2">
                                                <label htmlFor="editedCustomerType" className="block text-gray-700 font-bold">
                                                    Type de Client
                                                </label>
                                                <select
                                                    id="editedCustomerType"
                                                    name="editedCustomerType"
                                                    className="border rounded w-full py-2 px-3"
                                                    value={editedCustomerType}
                                                    onChange={(e) => { setEditedCustomerType(e.target.value); }}
                                                >
                                                    <option value="">Selectionnez le type de client</option>
                                                    <option value="Particulier">Particulier</option>
                                                    <option value="Entreprise">Entreprise</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <div>
                                                <label htmlFor="editedRegion" className="block text-gray-700 font-bold">
                                                    Région
                                                </label>
                                            </div>
                                            <select
                                                id="region"
                                                name="region"
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                value={editedRegion}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="">Choisissez la region</option>
                                                <option value="EN">Extrême-Nord</option>
                                                <option value="NO">Nord</option>
                                                <option value="AD">Adamaoua</option>
                                                <option value="CE">Centre</option>
                                                <option value="LT">Littoral</option>
                                                <option value="SU">Sud</option>
                                                <option value="NW">Nord-Ouest</option>
                                                <option value="SW">Sud-Ouest</option>
                                                <option value="ES">Est</option>
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedCity" className="block text-gray-700 font-bold">
                                                Ville
                                            </label>
                                            <input
                                                type="email"
                                                id="editedCity"
                                                name="editedCity"
                                                value={editedCity}
                                                onChange={(e) => setEditedCity(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLocalisation" className="block text-gray-700 font-bold">
                                                Localisation Précise
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLocalisation"
                                                name="editedLocalisation"
                                                value={editedLocalisation}
                                                onChange={(e) => setEditedLocalisation(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLieuDit" className="block text-gray-700 font-bold">
                                                Lieu-dit
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLieuDit"
                                                name="editedLieuDit"
                                                value={editedLieuDit}
                                                onChange={(e) => setEditedLieuDit(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedCustomerGpsCoordinates" className="block text-gray-700 font-bold">
                                                Coordonnées GPS
                                            </label>
                                            <input
                                                type="text"
                                                id="editedCustomerGpsCoordinates"
                                                name="editedCustomerGpsCoordinates"
                                                value={editedCustomerGpsCoordinates}
                                                onChange={(e) => setEditedCustomerGpsCoordinates(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedImageLocalisation" className="block text-gray-700 font-bold">
                                                Plan de localisation
                                            </label>
                                            <div className="mb-2">
                                                <div>
                                                    {displayImageLocalisation ? (
                                                        <img
                                                            src={displayImageLocalisation}
                                                            alt="Plan de localisation"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 500, width: '100%' }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {editedImageLocalisation && editedImageLocalisation.endsWith('.jpg') || editedImageLocalisation && editedImageLocalisation.endsWith('.png') ? (
                                                                <img
                                                                    src={editedImageLocalisation}
                                                                    alt="Plan de localisation"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: 500, width: '100%' }}
                                                                />
                                                            ) : (
                                                                <iframe
                                                                    src={editedImageLocalisation}
                                                                    width="100%"
                                                                    height="500"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div className="flex m-2">
                                                    <strong className="whitespace-nowrap my-2">Modifier le plan de localisation:</strong>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        id="preciseLocationFile"
                                                        name="preciseLocationFile"
                                                        onChange={handleFileChangeLocalisation}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedEmail" className="block text-gray-700 font-bold">
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                id="editedEmail"
                                                name="editedEmail"
                                                value={editedEmail}
                                                onChange={(e) => setEditedEmail(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedMobile" className="block text-gray-700 font-bold">
                                                N° Mobile
                                            </label>
                                            <input
                                                type="text"
                                                id="editedMobile"
                                                name="editedMobile"
                                                value={editedMobile}
                                                onChange={(e) => setEditedMobile(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        {editedIdCardNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedIdCardNumber" className="block text-gray-700 font-bold">
                                                        Numéro de la CNI
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedIdCardNumber"
                                                        name="editedIdCardNumber"
                                                        value={editedIdCardNumber}
                                                        onChange={(e) => setEditedIdCardNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="cni" className="block text-gray-700 font-bold">
                                                        Carte Nationnale d'identité (Recto & Verso)
                                                    </label>
                                                    <div className="flex">
                                                        {displayRectoCni ? (
                                                            <img
                                                                src={displayRectoCni}
                                                                alt="Image Recto de la CNI"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 250, width: '50%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedRectoCni && editedRectoCni.endsWith('.jpg') || editedRectoCni && editedRectoCni.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedRectoCni}
                                                                        alt="Image Recto de la CNI"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 250, width: '50%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedRectoCni}
                                                                        width="50%"
                                                                        height="300"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}

                                                        {displayVersoCni ? (
                                                            <img
                                                                src={displayVersoCni}
                                                                alt="Image Verso de la CNI"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 250, width: '50%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedVersoCni && editedVersoCni.endsWith('.jpg') || editedVersoCni && editedVersoCni.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedVersoCni}
                                                                        alt="Image Verso de la CNI"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 250, width: '50%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedVersoCni}
                                                                        width="50%"
                                                                        height="300"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <strong className="whitespace-nowrap my-2">Modifier le recto du CNI:</strong>
                                                    <input
                                                        type="file"
                                                        accept=".jpg, .jpeg, .png, .pdf"
                                                        id="idCardFront"
                                                        name="idCardFront"
                                                        onChange={handleFileChangeRectoCni}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>

                                                <div className="flex">
                                                    <strong className="whitespace-nowrap my-2">Modifier le verso du CNI:</strong>
                                                    <input
                                                        type="file"
                                                        accept=".jpg, .jpeg, .png, .pdf"
                                                        id="idCardBack"
                                                        name="idCardBack"
                                                        onChange={handleFileChangeVersoCni}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {editedIdPassportNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedIdPassportNumber" className="block text-gray-700 font-bold">
                                                        Numéro du passeport
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedIdPassportNumber"
                                                        name="editedIdPassportNumber"
                                                        value={editedIdPassportNumber}
                                                        onChange={(e) => setEditedIdPassportNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                    <p className="mt-2 bold">Passeport:</p>
                                                    {displayIdPassportFront ? (
                                                        <img
                                                            src={displayIdPassportFront}
                                                            alt="Image Recto du passeport"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {editedIdPassportFront && editedIdPassportFront.endsWith('.jpg') || editedIdPassportFront && editedIdPassportFront.endsWith('.png') ? (
                                                                <img
                                                                    src={editedIdPassportFront}
                                                                    alt="Image Recto du passeport"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: 250, width: '50%' }}
                                                                />
                                                            ) : (
                                                                <iframe
                                                                    src={editedIdPassportFront}
                                                                    width="100%"
                                                                    height="300"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="flex m-2">
                                                        <strong className="whitespace-nowrap my-2">Modifier le passeport :</strong>
                                                        <input
                                                            type="file"
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            id="idPasseportFront"
                                                            name="idPasseportFront"
                                                            onChange={handleFileChangePassport}
                                                            className="rounded w-full py-2 px-3"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {editedIdResidencePermitNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedIdResidencePermitNumber" className="block text-gray-700 font-bold">
                                                        Numéro de la carte de séjour
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedIdResidencePermitNumber"
                                                        name="editedIdResidencePermitNumber"
                                                        value={editedIdResidencePermitNumber}
                                                        onChange={(e) => setEditedIdResidencePermitNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                                <div>
                                                    <strong>Carte de séjour:</strong>
                                                    <div className="flex">
                                                        {displayIdResidencePermitFront ? (
                                                            <img
                                                                src={displayIdResidencePermitFront}
                                                                alt="Image Recto de la carte de séjour"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 250, width: '50%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedIdResidencePermitFront && editedIdResidencePermitFront.endsWith('.jpg') || editedIdResidencePermitFront && editedIdResidencePermitFront.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedIdResidencePermitFront}
                                                                        alt="Image Recto de la carte de séjour"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 250, width: '50%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedIdResidencePermitFront}
                                                                        width="100%"
                                                                        height="300"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="flex m-2">
                                                        <strong className="whitespace-nowrap my-2">Modifier la carte de séjour :</strong>
                                                        <input
                                                            type="file"
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            id="idResidencePermitFront"
                                                            name="idResidencePermitFront"
                                                            onChange={handleFileChangeIdResidencePermit}
                                                            className="rounded w-full py-2 px-3"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {editedCommerceRegNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedCommerceRegNumber" className="block text-gray-700 font-bold">
                                                        N° Régistre Commerce
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedCommerceRegNumber"
                                                        name="editedCommerceRegNumber"
                                                        value={editedCommerceRegNumber}
                                                        onChange={(e) => setEditedCommerceRegNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="editedRectoCommerceReg" className="block text-gray-700 font-bold">
                                                        Image du registre de commerce
                                                    </label>
                                                    <div className="flex">
                                                        {displayRecotCommerceReg ? (
                                                            <img
                                                                src={displayRecotCommerceReg}
                                                                alt="Image du registre de commerce"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 'auto', width: '100%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedRectoCommerceReg && editedRectoCommerceReg.endsWith('.jpg') || editedRectoCommerceReg && editedRectoCommerceReg.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedRectoCommerceReg}
                                                                        alt="Image du registre de commerce"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 'auto', width: '100%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedRectoCommerceReg}
                                                                        width="100%"
                                                                        height="500"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex m-2">
                                                    <strong className="whitespace-nowrap my-2">Modifier l'image du registre de commerce :</strong>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/jpg, image/png"
                                                        id="commerceRegistryFront"
                                                        name="commerceRegistryFront"
                                                        onChange={(e) => handleFileChangeReg}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <div className="mb-2">
                                            <label htmlFor="editedOffer" className="block text-gray-700 font-bold mt-2">
                                                Formule d’abonnement choisie
                                            </label>

                                            <select
                                                id="editedOffer"
                                                name="editedOffer"
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                value={editedOffer}
                                                onChange={handleSelectChangeOffer}
                                            >
                                                <option value="">Sélectionnez une offre pour modifier</option>
                                                <option value="Blue Max S">Blue Max S</option>
                                                <option value="Blue Max M">Blue Max M</option>
                                                <option value="Blue Max L">Blue Max L</option>
                                                <option value="Blue Max XL">Blue Max XL</option>
                                            </select>
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Enregistrer
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Activation;