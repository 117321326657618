import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "./components/FormatDate";

const PreStudyOrder = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedOrderEmail, setSelectedOrderEmail] = useState(null);

    const [showTechCamtelForm, setShowTechCamtelForm] = useState(false);
    const [showTechCamtelEditForm, setShowTechCamtelEditForm] = useState(false);

    const [showTechPartnerForm, setShowTechPartnerForm] = useState(false);

    const [selectedOption, setSelectedOption] = useState("");
    const [preStudyOrder, setPreStudyOrder] = useState([]);

    const [editingPreStudyOrder, setEditingPreStudyOrder] = useState(null);
    const [editedFdtRef, setEditedFdtRef] = useState("");
    const [editedFatRef, setEditedFatRef] = useState("");
    const [editedDfatCustomer, setEditedDfatCustomer] = useState("");
    const [editedOltSfp, setEditedOltSfp] = useState("");
    const [editedPortFat, setEditedPortFat] = useState("");
    const [editedFatGpsCoordinates, setEditedFatGpsCoordinates] = useState("");
    const [editedCustomerGpsCoordinates, setEditedCustomerGpsCoordinates] = useState("");
    const [editedNbPoToInstall, setEditedNbPoToInstall] = useState("");
    const [editedPreEtudeObs, setEditedPreEtudeObs] = useState("");
    const [editedResult, setEditedResult] = useState("");

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isPreStudyVisible, setIsPreStudyVisible] = useState(true);
    const [isEditPreStudyVisible, setIsEditPreStudyVisible] = useState(true);
    const [isStudyVisible, setIsStudyVisible] = useState(true);

    const [selectedPreStudyOrder, setSelectedPreStudyOrder] = useState(null);
    const [selectedInstalationData, setSelectedInstallationData] = useState(null);
    const [selectedActivationData, setSelectedActivationData] = useState(null);

    const [comPartnerName, setComPartnerName] = useState([]);
    const [techPartnerName, setTechPartnerName] = useState([]);
    const [techCamtelName, setTechCamtelName] = useState([]);
    const [comCamtelName, setComCamtelName] = useState([]);

    const [details, setDetails] = useState(null);

    const [loading, setLoading] = useState(false);

    const [token, setToken] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    // LISTE DES COMMANDES DU TECHNICIEN PARTENAIRE
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'technicien_partner') {
            setLoading(true);
            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/prestudy/techpartner/` + localStorage.getItem('profileId'),
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            setOrders(response.data);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [preStudyOrder, editingPreStudyOrder, token]);

    // LISTE DES COMMANDES DU TECHNICIEN CAMTEL
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'technicien_camtel') {
            setLoading(true);
            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/prestudy/techcamtel/` + localStorage.getItem('userId'),
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            setOrders(response.data);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [preStudyOrder, token]);

    const filteredOrders = orders.preStudyOrder?.filter(order =>
        order.nameOrSocialName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const showOrderDetails = async (order) => {
        setSelectedInstallationData(null);
        setSelectedActivationData(null);

        setIsDetailsVisible(false);

        setSelectedOrder(order);
        setSelectedOrderId(order.idSheet);
        setSelectedOrderEmail(order.email);
        setShowTechCamtelForm(false);
        setEditingPreStudyOrder(null);

        setTechCamtelName([]);
        setTechPartnerName([]);
        setComCamtelName([]);
        setComPartnerName([]);

        if (token && order.idPartnerTechnician && order.idPartnerTechnician !== 0) {
            const techPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerTechnician, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${token}`
                },
            });
            setTechPartnerName(techPartnerProfile.data.profile[0]);
        } else {
            console.log('Pas de technicien assigné à cette commande.');
        }

        if (token && order && order.idPartnerCommercial && order.idPartnerCommercial !== 0) {
            const comPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerCommercial, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setComPartnerName(comPartnerProfile.data.profile[0]);
            console.log('comPartnerProfile: ', comPartnerProfile.data);
        } else {
            console.log('Commande autoprospectée ou par CAMTEL.');
        }

        if (token) {
            try {
                const response = await axios.get(`${BASE_URL}/api/orders/details/` + order.idSheet, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const orderData = response.data[order.idSheet].preStudyOrderData[0];

                setSelectedPreStudyOrder(orderData);

                if (orderData.idTechnicienCamtel && orderData.idTechnicienCamtel !== 0) {
                    const techCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + orderData.idTechnicienCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setTechCamtelName(techCamtelProfile.data.profile[0]);
                } else {
                    console.log('Pré-étude de la commande non validée par le Pool Technique de CAMTEL.');
                }

                setSelectedInstallationData(response.data[order.idSheet].installationData[0]);
                setSelectedActivationData(response.data[order.idSheet].activationData[0]);

                if (response.data[order.idSheet].activationData[0] && response.data[order.idSheet].activationData[0].idCommercialCamtel !== 0 && response.data[order.idSheet].activationData[0].idCommercialCamtel !== null) {
                    const comCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + response.data[order.idSheet].activationData[0].idCommercialCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setComCamtelName(comCamtelProfile.data.profile[0]);
                } else {
                    console.log('Commande non activée par le Pool Commercial de CAMTEL.');
                }

                const idBindedEntity = orderData.idBindedEntity;
                const entityResponse = await axios.get(`${BASE_URL}/api/entities/details/` + idBindedEntity, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                const entityData = entityResponse.data.entities;
                setDetails(entityData[0]);

            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
            }
        }
    };

    // CAMTEL
    const pursueTechCamtelProcedure = (order) => {
        setSelectedOrder(order);
        setSelectedOrderId(order.idSheet);
        setSelectedOrderEmail(order.email);
        setShowTechCamtelForm(true);

        setIsStudyVisible(false);
    };

    const editCamtelProcedure = (order) => {
        setSelectedOrder(order);
        setSelectedOrderId(order.idSheet);
        setSelectedOrderEmail(order.email);

        setIsDetailsVisible(true);
        setIsPreStudyVisible(true);
        setIsStudyVisible(false);

        setShowTechCamtelEditForm(true);
    };

    // PARTENAIRE
    const pursueTechPartnerProcedure = (order) => {
        setSelectedOrder(order);
        setSelectedOrderId(order.idSheet);
        setSelectedOrderEmail(order.email);
        setShowTechPartnerForm(true);
        setEditingPreStudyOrder(null);

        setIsPreStudyVisible(false);
    };

    // Gestion du select
    const handleSelectChange = (e) => {
        const newValue = e.target.value;
        setSelectedOption(newValue);
    };

    const handlePartnerFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const formData = {
            idSheet: selectedOrderId,
            idTechnicienPartner: localStorage.getItem('profileId'),
            idTechnicienCamtel: 0,
            idEntity: localStorage.getItem('userId'),
            fdtRef: e.target.fdtRef.value,
            fatRef: e.target.fatRef.value,
            dFatCustomer: e.target.dFatCustomer.value,
            oltSfp: e.target.oltSfp.value,
            portFat: e.target.portFat.value,
            fatGpsCoordinates: e.target.fatGpsCoordinates.value,
            customerGpsCoordinates: e.target.customerGpsCoordinates.value,
            nbPoToInstall: e.target.nbPoToInstall.value,
            result: selectedOption,
            validation: "",
            preEtudeUpdateByTechCamtel: null,
            preEtudeObs: e.target.preEtudeObs.value,
            etudeObs: "",
        };

        const data = formData;

        if (token) {
            try {
                const config = {
                    method: 'post',
                    url: `${BASE_URL}/api/prestudy`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    data: data
                };

                const response = await axios.request(config);

                setPreStudyOrder(response.data);

                e.target.reset();

                setSelectedOrder(null);
                setSelectedOrderId(null);
                setShowTechPartnerForm(false);

                setIsPreStudyVisible(true);

                setLoading(false);
                alert('Pré-étude réalisée avec succès.');
            } catch (error) {
                console.error("Erreur lors de la création de la pré-étude : ", error);
                setLoading(false);
                alert('Erreur lors de la réalisation de la pré-étude !');
            }
        }
    };

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const formData = {
            idTechnicienCamtel: localStorage.getItem('profileId'),
            orderName: e.target.orderName.value,
            orderOffer: e.target.orderOffer.value,
            validation: e.target.validation.value,
            etudeObs: e.target.etudeObs.value,
            preEtudeUpdateByTechCamtelAt: formattedDate,
            customerEmail: selectedOrderEmail,
            agency: localStorage.getItem('userEntity')
        };

        if (token) {
            try {
                const response = await axios.patch(`${BASE_URL}/api/prestudy/${selectedOrderId}`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                setPreStudyOrder(response.data);

                e.target.reset();

                setSelectedOrder(null);
                setSelectedOrderId(null);
                setShowTechCamtelForm(false);
                setIsStudyVisible(true);
                setLoading(false);
                alert('Étude validée avec succès.');
            } catch (error) {
                alert('Erreur lors de la validation de l\'étude.');
                console.error('Erreur lors de la soumission du formulaire :', error);
                setLoading(false);
                alert('Une erreur survenue lors de la validation de l\'étude. Veuillez réessayer...');
            }
        }
    };

    const handleEtudeObsChange = (e) => {
        setSelectedOrder({ ...selectedOrder, etudeObs: e.target.value });
    };

    const handleFormEditSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const formData = {
            idTechnicienCamtel: localStorage.getItem('profileId'),
            idSheet: e.target.orderIdSheet.value,
            orderName: e.target.orderName.value,
            orderOffer: e.target.orderOffer.value,
            validation: e.target.validation.value,
            etudeObs: e.target.etudeObs.value,
            preEtudeUpdateByTechCamtelAt: formattedDate,
            customerEmail: selectedOrderEmail,
            agency: localStorage.getItem('userEntity')
        };

        if (token) {
            try {
                const response = await axios.patch(`${BASE_URL}/api/prestudy/${formData.idSheet}`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                setSelectedOrder(null);
                setSelectedOrderId(null);
                setShowTechCamtelEditForm(false);
                setPreStudyOrder(response.data);
                setIsStudyVisible(true);

                setLoading(false);
                alert('Étude modifiée avec succès.');

            } catch (error) {
                alert('Erreur lors de la validation de l\'étude.');
                console.error('Erreur lors de la soumission du formulaire :', error);
                setLoading(false);
                alert('Une erreur survenue lors de la modification de l\'étude. Veuillez réessayer...');
            }
        }
    };

    const handleSelectChangeEditedResult = (e) => {
        const newValue = e.target.value;
        setEditedResult(newValue);
    };

    const handleEdit = (id) => {
        setIsDetailsVisible(true);
        setIsPreStudyVisible(true);
        setIsStudyVisible(true);
        setIsEditPreStudyVisible(false);

        setSelectedOrder(false);
        setShowTechPartnerForm(false);
        setShowTechCamtelForm(false);

        const singlePreStudyOrder = orders.preStudyOrder.find((order) => order.idSheet === id);
        setEditingPreStudyOrder(singlePreStudyOrder);
        setEditedFdtRef(singlePreStudyOrder.fdtRef);
        setEditedFatRef(singlePreStudyOrder.fatRef);
        setEditedDfatCustomer(singlePreStudyOrder.dFatCustomer);
        setEditedOltSfp(singlePreStudyOrder.oltSfp);
        setEditedPortFat(singlePreStudyOrder.portFat);
        setEditedFatGpsCoordinates(singlePreStudyOrder.fatGpsCoordinates);
        setEditedCustomerGpsCoordinates(singlePreStudyOrder.customerGpsCoordinates);
        setEditedNbPoToInstall(singlePreStudyOrder.nbPoToInstall);
        setEditedPreEtudeObs(singlePreStudyOrder.preEtudeObs);
        setEditedResult(singlePreStudyOrder.result);
    };

    const handlePartnerFormEditSubmit = () => {

        if (!editedFdtRef || !editedFatRef || !editedDfatCustomer || !editedOltSfp || !editedPortFat || !editedFatGpsCoordinates || !editedCustomerGpsCoordinates || !editedNbPoToInstall || !editedPreEtudeObs || !editedResult) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const updatedData = {
            ...editingPreStudyOrder,
            fdtRef: editedFdtRef,
            fatRef: editedFatRef,
            dFatCustomer: editedDfatCustomer,
            oltSfp: editedOltSfp,
            portFat: editedPortFat,
            fatGpsCoordinates: editedFatGpsCoordinates,
            customerGpsCoordinates: editedCustomerGpsCoordinates,
            nbPoToInstall: editedNbPoToInstall,
            preEtudeObs: editedPreEtudeObs,
            result: editedResult
        };

        if (token) {
            axios.patch(`${BASE_URL}/api/prestudy/patch/${editingPreStudyOrder.idSheet}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    console.log(response.data);
                    setEditingPreStudyOrder(null);
                    setSelectedOrder(null);

                    alert('Pré-étude modifiée avec succès.')

                    setIsEditPreStudyVisible(true);
                    setIsPreStudyVisible(true);
                })
                .catch((error) => {
                    alert("Erreur lors de la mise à jour de la pré-étude.");
                    console.error("Erreur lors de la mise à jour de la pré-étude : ", error);
                });
        }
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[75%] 2xl:w-[73%]'}`}>

                        {isDetailsVisible && isPreStudyVisible && isStudyVisible && isEditPreStudyVisible && (
                            <div>
                                <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des commandes</h1>

                                {localStorage.getItem('userRole') === 'technicien_partner' || localStorage.getItem('userRole') === 'technicien_camtel' ? (
                                    <div className="sm:w-[100%] md:w-[100%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] float-right">
                                        <label class="relative block">
                                            <span class="sr-only">Search</span>
                                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search"><circle cx="11" cy="11" r="8" /><path d="m21 21-4.3-4.3" /></svg>
                                            </span>
                                            <input
                                                class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                placeholder="Rechercher un client par son nom"
                                                type="text"
                                                value={searchTerm}
                                                onChange={e => setSearchTerm(e.target.value)}
                                            />
                                        </label>
                                    </div>) : null
                                }

                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <th className="px-2 py-2">Date de souscription</th>
                                            <th className="px-2 py-2">Nom du partenaire</th>
                                            <th className="px-2 py-2">Nom du client</th>
                                            <th className="px-2 py-2">Statut de la pré-étude</th>
                                            <th className="px-2 py-2">Statut de l'étude</th>
                                            <th colSpan={3} className="px-4 py-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredOrders && filteredOrders.length > 0 ? (
                                            filteredOrders.map((order) => (
                                                <tr key={order.id} className="border-b hover:bg-gray-100 text-center">
                                                    <td className="px-2 py-2">{<FormatDate dateStr={order.sheetCreatedAt} />}</td>
                                                    <td className="px-2 py-2">{order.name}</td>
                                                    <td className="px-2 py-2">{order.nameOrSocialName}</td>
                                                    <td className="px-2 py-2">
                                                        {(() => {
                                                            if (order.result === null || order.result === '') {
                                                                return 'Pré-Étude non réalisée';
                                                            } else {
                                                                return order.result;
                                                            }
                                                        })()}
                                                    </td>
                                                    <td className="px-2 py-2">
                                                        {(() => {
                                                            if (order.validation === null || order.validation === '') {
                                                                if (order.result === null || order.result === '') {
                                                                    return 'En attente de la réalisation de la pré-étude';
                                                                } else {
                                                                    return 'Étude non réalisée';
                                                                }
                                                            } else {
                                                                return order.validation;
                                                            }
                                                        })()}
                                                    </td>
                                                    <td className="text-center px-4 py-2 text-center">
                                                        <button
                                                            onClick={() => { showOrderDetails(order) }}
                                                            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 my-1 px-2 mr-2"
                                                        >
                                                            Détails
                                                        </button>
                                                        {(() => {
                                                            if (localStorage.getItem('userRole') === 'technicien_partner') {
                                                                if (order.result === null) {
                                                                    return (
                                                                        <button
                                                                            onClick={() => { pursueTechPartnerProcedure(order) }}
                                                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 my-1 px-2 mr-2"
                                                                        >
                                                                            Poursuivre la procédure
                                                                        </button>
                                                                    );
                                                                }
                                                                // else {
                                                                //     if (order.validation === '') {
                                                                //         return (
                                                                //             <button
                                                                //                 onClick={() => {
                                                                //                     { handleEdit(order.idSheet); }
                                                                //                 }}
                                                                //                 className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 my-1 px-2 mr-2"
                                                                //             >
                                                                //                 Modifier la pré-étude
                                                                //             </button>
                                                                //         )
                                                                //     } else {
                                                                //         return null;
                                                                //     }
                                                                // }
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (localStorage.getItem('userRole') === 'technicien_camtel') {
                                                                if (order.result === null) {
                                                                    return null;
                                                                } else {
                                                                    if (order.validation === '') {
                                                                        return (
                                                                            <button
                                                                                onClick={() => { pursueTechCamtelProcedure(order) }}
                                                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 my-1 px-2 mr-2"
                                                                            >
                                                                                Poursuivre la procédure
                                                                            </button>
                                                                        )
                                                                    }
                                                                    // else {
                                                                    //     if (!orders.idActivaton) {
                                                                    //         return (
                                                                    //             <button
                                                                    //                 onClick={() => { editCamtelProcedure(order) }}
                                                                    //                 className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 my-1 px-2 mr-2"
                                                                    //             >
                                                                    //                 Modifier l'étude
                                                                    //             </button>
                                                                    //         )
                                                                    //     } else {
                                                                    //         return null;
                                                                    //     }
                                                                    // }
                                                                }
                                                            }
                                                        })()}
                                                    </td>

                                                    {localStorage.getItem('role') === 'technicien_camtel' ?
                                                        order.idInstallation !== null && order.idInstallation !== '' && (
                                                            <td className="border text-center px-4 py-2 text-center">
                                                                <Link
                                                                    to={`/fiche/${order.idSheet}`}
                                                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 my-1 px-2 mr-2"
                                                                >
                                                                    Prévisualiser la fiche
                                                                </Link>
                                                            </td>
                                                        ) : null
                                                    }
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center">Aucune commande trouvée.</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {selectedOrder && !isDetailsVisible && (
                            <div className="mt-4 border p-4">
                                <div className="flex justify-end">
                                    <div>
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => setIsDetailsVisible(true)}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                </div>
                                <h2 className="text-3xl font-bold mb-2 uppercase text-center">Détails de la commande</h2>

                                {localStorage.getItem('userRole') === 'technicien_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' ? (
                                    <>
                                        {comPartnerName.nom ? <p><strong>Commercial du partenaire ayant prospecté la commande: </strong> {comPartnerName.nom + ' ' + comPartnerName.prenom}</p> : <p><strong>Client assignée au partenaire par CAMTEL.</strong></p>}
                                        {techPartnerName.nom ? <p><strong>Technicien du partenaire en charge de la pré-étude et de l'installation de la commande :</strong> {techPartnerName.nom + ' ' + techPartnerName.prenom}</p> : <p><strong>Commande non assignée à un technicien (Partenaire) pour pré-étude.</strong></p>}
                                        {techCamtelName.nom ? <p><strong>Pool Technique CAMTEL en charge de l'étude: </strong> {techCamtelName.nom + ' ' + techCamtelName.prenom}</p> : <p><strong>Étude pas encore validée par le Pool Technique CAMTEL</strong></p>}
                                        {comCamtelName.nom ? <p><strong>Pool Commercial CAMTEL en charge de l'activation de cette commande: </strong> {comCamtelName.nom + ' ' + comCamtelName.prenom}</p> : <p><strong>Commande non activée par le Pool Commercial CAMTEL.</strong></p>}
                                    </>
                                ) : null}

                                <p><strong>Date de souscription du client: </strong><FormatDate dateStr={selectedOrder.sheetCreatedAt} /></p>

                                {/* Afficher la photo du client */}
                                {selectedOrder.customerPhoto && (
                                    <div>
                                        <strong>Photo du client:</strong>
                                        <img
                                            src={selectedOrder.customerPhoto}
                                            alt="Photo du client"
                                            className="block w-32 h-auto mt-2 rounded"
                                            style={{ height: 200, width: 200 }}
                                        />
                                    </div>
                                )}
                                <p><strong>N°ID Souscription :</strong> {selectedOrder.idSubscription + selectedOrder.idSheet}</p>
                                <p><strong>Nom et Prénoms / Raison Sociale :</strong> {selectedOrder.nameOrSocialName}</p>
                                <p><strong>Type de Client:</strong> {selectedOrder.customerType}</p>
                                <p>
                                    <strong>Région:</strong>
                                    {/* {selectedOrder.region} */}
                                    {
                                        selectedOrder.region === 'CE' ? 'Centre' :
                                            selectedOrder.region === 'LT' ? 'Littoral' :
                                                selectedOrder.region === 'NO' ? 'Nord' :
                                                    selectedOrder.region === 'EN' ? 'Extrême-Nord' :
                                                        selectedOrder.region === 'AD' ? 'Adamaoua' :
                                                            selectedOrder.region === 'ES' ? 'Est' :
                                                                selectedOrder.region === 'SU' ? 'SUD' :
                                                                    selectedOrder.region === 'OU' ? 'Ouest' :
                                                                        selectedOrder.region === 'NW' ? 'Nord-Ouest' :
                                                                            'Sud-Ouest'
                                    }
                                </p>
                                <p><strong>Ville:</strong> {selectedOrder.city}</p>
                                <p><strong>Localisation Précise:</strong> {selectedOrder.localisation}</p>
                                <p><strong>Lieu-dit:</strong> {selectedOrder.lieuDit}</p>
                                {selectedOrder.customerGpsCoordinates && (<p><strong>Coordonnées GPS: </strong>{selectedOrder.customerGpsCoordinates}</p>)}
                                <p>
                                    <strong>Plan de localisation:</strong>
                                    {selectedOrder.imageLocalisation && selectedOrder.imageLocalisation.endsWith('.jpg') || selectedOrder.imageLocalisation && selectedOrder.imageLocalisation.endsWith('.png') ? (
                                        <img
                                            src={selectedOrder.imageLocalisation}
                                            alt="Plan de localisation"
                                            className="block w-32 h-auto mt-2 rounded"
                                            style={{ height: 500, width: '100%' }}
                                        />
                                    ) : (
                                        <iframe
                                            src={selectedOrder.imageLocalisation}
                                            width="100%"
                                            height="500"
                                            title="PDF Viewer"
                                        ></iframe>
                                    )}
                                </p>
                                <p><strong>Email:</strong> {selectedOrder.email}</p>
                                <p><strong>N° Mobile:</strong> {selectedOrder.mobile}</p>
                                {selectedOrder.idCardNumber !== null && (
                                    <>
                                        <p><strong>Numéro de la CNI:</strong> {selectedOrder.idCardNumber}</p>
                                        <p>
                                            <strong>Carte Nationnale d'identité:</strong>
                                            <div className="flex">
                                                <div className="flex flex-col md:flex-row">
                                                    {selectedOrder.rectoCni && selectedOrder.rectoCni.endsWith('.jpg') || selectedOrder.rectoCni && selectedOrder.rectoCni.endsWith('.png') ? (
                                                        <img
                                                            src={selectedOrder.rectoCni}
                                                            alt="Image Recto de la CNI"
                                                            className="w-full h-auto mt-2 rounded md:w-1/2"
                                                            style={{ height: 250 }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.rectoCni}
                                                            className="w-full md:w-1/2"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}

                                                    {selectedOrder.versoCni && selectedOrder.versoCni.endsWith('.jpg') || selectedOrder.versoCni && selectedOrder.versoCni.endsWith('.png') ? (
                                                        <img
                                                            src={selectedOrder.versoCni}
                                                            alt="Image Verso de la CNI"
                                                            className="w-full h-auto mt-2 rounded md:w-1/2"
                                                            style={{ height: 250 }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.versoCni}
                                                            className="w-full md:w-1/2"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </div>
                                        </p>
                                    </>
                                )}
                                {selectedOrder.idPassportNumber !== null && (
                                    <>
                                        <p><strong>Numéro du passeport:</strong> {selectedOrder.idPassportNumber}</p>
                                        <p>
                                            <strong>Passeport:</strong>
                                            <div className="flex">
                                                {selectedOrder.idPasseportFront && selectedOrder.idPasseportFront.endsWith('.jpg') || selectedOrder.idPasseportFront && selectedOrder.idPasseportFront.endsWith('.png') ? (
                                                    <img
                                                        src={selectedOrder.idPasseportFront}
                                                        alt="Image Recto du passeport"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 250, width: '50%' }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={selectedOrder.idPasseportFront}
                                                        width="100%"
                                                        height="300"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </div>
                                        </p>
                                    </>
                                )}
                                {selectedOrder.idResidencePermitNumber !== null && (
                                    <>
                                        <p><strong>Numéro dr la carte de séjour:</strong> {selectedOrder.idResidencePermitNumber}</p>
                                        <p>
                                            <strong>Carte de séjour:</strong>
                                            <div className="flex">
                                                {selectedOrder.idResidencePermitFront && selectedOrder.idResidencePermitFront.endsWith('.jpg') || selectedOrder.idResidencePermitFront && selectedOrder.idResidencePermitFront.endsWith('.png') ? (
                                                    <img
                                                        src={selectedOrder.idResidencePermitFront}
                                                        alt="Image Recto de la carte de séjour"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 250, width: '50%' }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={selectedOrder.idResidencePermitFront}
                                                        width="100%"
                                                        height="300"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </div>
                                        </p>
                                    </>
                                )}
                                {selectedOrder.commerceRegNumber !== '' && (
                                    <>
                                        <p><strong>N°Régistre Commerce :</strong> {selectedOrder.commerceRegNumber}</p>
                                        <p>
                                            <strong>Image du registre de commerce:</strong>
                                            <div className="flex">
                                                {selectedOrder.rectoCommerceReg && selectedOrder.rectoCommerceReg.endsWith('.jpg') || selectedOrder.rectoCommerceReg && selectedOrder.rectoCommerceReg.endsWith('.png') ? (
                                                    <img
                                                        src={selectedOrder.rectoCommerceReg}
                                                        alt="Image du registre de commerce"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 'auto', width: '100%' }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={selectedOrder.rectoCommerceReg}
                                                        width="100%"
                                                        height="500"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </div>
                                        </p>
                                    </>
                                )}

                                <h3 className="text-2xl font-bold mt-2">TYPE D’OPERATION</h3>
                                <p><strong>Formule d’abonnement choisie:</strong> {selectedOrder.offerBlueMaxSelected}</p>

                                {selectedOrder.result !== null && (
                                    <>
                                        <div className="mt-4 border p-4">
                                            <h3 className="text-2xl font-bold mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                                            <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedOrder.preEtudeCreatedAt} /></p>
                                            <p><strong>Nom du prestataire :</strong> {selectedOrder.name}</p>
                                            <p><strong>Tel :</strong> {selectedOrder.telephoneEntity}</p>
                                            <p><strong>Email :</strong> {selectedOrder.emailEntity}</p>
                                            <p><strong>Zone d’intervention :</strong>{selectedOrder.bindedEntity}</p>
                                            <p><strong>Agence Commerciale :</strong>{selectedOrder.interventionZone}</p>
                                            <p><strong>Référence du FDT :</strong> {selectedOrder.fdtRef}</p>
                                            <p><strong>Référence du FAT :</strong> {selectedOrder.fatRef}</p>
                                            <p><strong>Distance FAT-Client (m) :</strong> {selectedOrder.dFatCustomer}</p>
                                            <p><strong>OLT - F/S/P :</strong> {selectedOrder.oltSfp}</p>
                                            <p><strong>Port FAT :</strong> {selectedOrder.portFat}</p>
                                            <p><strong>Coordonnée GPS du FAT :</strong> {selectedOrder.fatGpsCoordinates}</p>
                                            <p><strong>Coordonnée GPS Client :</strong> {selectedOrder.customerGpsCoordinates}</p>
                                            <p><strong>Nombre de poteau à installer :</strong> {selectedOrder.nbPoToInstall}</p>
                                        </div>
                                        <div className="mt-4 border p-4">
                                            <h3 className="text-2xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE LA PRE-ETUDE</h3>
                                            <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedOrder.preEtudeCreatedAt} /></p>
                                            <p><strong>Statut de la validation par le technicien du prestataire:</strong> {selectedOrder.result}</p>
                                            <p><strong>Observations fait par le technicien du prestataire:</strong> {selectedOrder.preEtudeObs}</p>
                                        </div>
                                    </>
                                )}
                                {selectedOrder.validation !== null && (
                                    (selectedOrder.validation !== '' || selectedOrder.etudeObs !== '') && (
                                        <div className="mt-4 border p-4">
                                            <h3 className="text-2xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE L'ETUDE</h3>
                                            <p><strong>Date de validation de la pré-étude: </strong><FormatDate dateStr={selectedOrder.preEtudeCreatedAt} /></p>
                                            {selectedOrder.validation !== '' && (
                                                <p><strong>Statut de la validation par le technicien:</strong> {selectedOrder.validation}</p>
                                            )}
                                            {selectedOrder.etudeObs !== '' && (
                                                <p><strong>Observations de l'étude:</strong> {selectedOrder.etudeObs}</p>
                                            )}
                                        </div>
                                    )
                                )}

                                {selectedActivationData && selectedActivationData.crmCustomerCode !== null && selectedActivationData.crmCustomerCode !== '' && (
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">ACTIVATION</h3>
                                        <p><strong>Date de l'activation: </strong><FormatDate dateStr={selectedActivationData.activationCreatedAt} /></p>
                                        <p><strong>ID de la commande (CBS) :</strong> {selectedActivationData.cbsOrderId}</p>
                                        <p><strong>Code Client CRM :</strong> {selectedActivationData.crmCustomerCode}</p>
                                        <p><strong>Compte de facturation :</strong> {selectedActivationData.billingAccount}</p>
                                        <p><strong>Numéro de Telephone :</strong> {selectedActivationData.phoneNumberActivated}</p>
                                        <p><strong>Numéro ONT :</strong> {selectedActivationData.ontNumber}</p>
                                        <p><strong>Référence paiement :</strong> {selectedActivationData.paymentRef}</p>
                                        <p><strong>Date de paiement :</strong><FormatDate dateStr={selectedActivationData.paymentDate} /></p>
                                        <p><strong>Numéro STB/IP Stick :</strong> {selectedActivationData.stbIpStickNumber}</p>
                                    </div>
                                )}
                                {selectedInstalationData > 0 && (
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-3xl font-bold mb-2 mt-2 text-center">INSTALLATION</h3>
                                        <p><strong>Date de l'installation: </strong><FormatDate dateStr={selectedInstalationData.installationCreatedAt} /></p>
                                        <p><strong>Nombre de Balancelle :</strong> {selectedInstalationData.nbBalancel}</p>
                                        <p><strong>Métrage câble entrée FAT :</strong> {selectedInstalationData.cableMeterEntryFat}</p>
                                        <p><strong>Longueur Câble utilisée (m) :</strong> {selectedInstalationData.usedCableLong}</p>
                                        <p><strong>Nombre d’ATB :</strong> {selectedInstalationData.atbNumber}</p>
                                        <p><strong>Nombre Crochet d’Arrêt :</strong> {selectedInstalationData.nbCrochetArret}</p>
                                        <p><strong>Métrage câble chez l’abonné :</strong> {selectedInstalationData.clientCableMeter}</p>
                                        <p><strong>Nombre de poteau armé installé :</strong> {selectedInstalationData.nbArmedInstalledPoto}</p>
                                        <p><strong>Numéro ONT :</strong> {selectedInstalationData.ontNumber}</p>
                                        <p><strong>Nombre de STB/ Stick :</strong> {selectedInstalationData.stbStickNumber}</p>
                                        <p><strong>Nombre de TB :</strong> {selectedInstalationData.tbNumber}</p>
                                        <p><strong>Nombre de Jarretière :</strong> {selectedInstalationData.nbGarter}</p>
                                        <p><strong>Nombre de Smoove :</strong> {selectedInstalationData.nbSmoove}</p>
                                    </div>
                                )}
                            </div>
                        )}

                        {showTechCamtelForm && !isStudyVisible && (
                            <div className="mt-4 border p-4">
                                <div className="flex justify-end">
                                    <div>
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => setIsStudyVisible(true)}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold mt-2 mb-2">Validation de l'étude</h3>
                                <form onSubmit={handleFormSubmit}>
                                    <input
                                        type="hidden"
                                        id="orderID"
                                        name="orderID"
                                        value={selectedOrder.idSheet}
                                        readOnly
                                    />
                                    <input
                                        type="hidden"
                                        id="orderEmail"
                                        name="orderEmail"
                                        value={selectedOrder.email}
                                        readOnly
                                    />
                                    <input
                                        type="hidden"
                                        id="orderName"
                                        name="orderName"
                                        value={selectedOrder.nameOrSocialName}
                                        readOnly
                                    />
                                    <input
                                        type="hidden"
                                        id="orderOffer"
                                        name="orderOffer"
                                        value={selectedOrder.offerBlueMaxSelected}
                                        readOnly
                                    />
                                    <div className="mb-2">
                                        <label className="block text-gray-700 font-bold">Statut de l'étude <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                                        <label className="block text-gray-700">
                                            Favorable
                                            <input
                                                type="radio"
                                                name="validation"
                                                value="Favorable"
                                                className="ml-2"
                                                required
                                            />
                                        </label>
                                        <label className="block text-gray-700">
                                            Défavorable
                                            <input
                                                type="radio"
                                                name="validation"
                                                value="Défavorable"
                                                className="ml-2"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="etudeObs" className="block text-gray-700 font-bold">
                                            Observations Études <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <textarea
                                            id="etudeObs"
                                            name="etudeObs"
                                            rows="4"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>

                                    <button
                                        type="submit"
                                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                    >
                                        Valider
                                    </button>
                                </form>
                            </div>
                        )}

                        {showTechCamtelEditForm && !isStudyVisible && (
                            <div className="mt-4 border p-4">
                                <div className="flex justify-end">
                                    <div>
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => setIsStudyVisible(true)}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                </div>
                                <h3 className="text-3xl text-center font-bold mt-2 mb-2">Modification de l'étude</h3>
                                <form onSubmit={handleFormEditSubmit}>
                                    <input
                                        type="hidden"
                                        id="orderIdSheet"
                                        name="orderIdSheet"
                                        value={selectedOrder.idSheet}
                                        readOnly
                                    />
                                    <input
                                        type="hidden"
                                        id="orderEmail"
                                        name="orderEmail"
                                        value={selectedOrder.email}
                                        readOnly
                                    />
                                    <input
                                        type="hidden"
                                        id="orderName"
                                        name="orderName"
                                        value={selectedOrder.nameOrSocialName}
                                        readOnly
                                    />
                                    <input
                                        type="hidden"
                                        id="orderOffer"
                                        name="orderOffer"
                                        value={selectedOrder.offerBlueMaxSelected}
                                        readOnly
                                    />
                                    <div className="mb-2">
                                        <label className="block text-gray-700 font-bold">Statut de l'étude <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                                        <label className="block text-gray-700">
                                            Favorable
                                            <input
                                                type="radio"
                                                name="validation"
                                                value="Favorable"
                                                className="ml-2"
                                                checked={selectedOrder.validation === 'Favorable'}
                                                onChange={() => setSelectedOrder({ ...selectedOrder, validation: 'Favorable' })}
                                                required
                                            />
                                        </label>
                                        <label className="block text-gray-700">
                                            Défavorable
                                            <input
                                                type="radio"
                                                name="validation"
                                                value="Défavorable"
                                                className="ml-2"
                                                checked={selectedOrder.validation === 'Défavorable'}
                                                onChange={() => setSelectedOrder({ ...selectedOrder, validation: 'Défavorable' })}
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="etudeObs" className="block text-gray-700 font-bold">
                                            Observations Études <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <textarea
                                            id="etudeObs"
                                            name="etudeObs"
                                            rows="4"
                                            className="border rounded w-full py-2 px-3"
                                            value={selectedOrder.etudeObs}
                                            onChange={handleEtudeObsChange}
                                            required
                                        />
                                    </div>

                                    <button
                                        type="submit"
                                        // onClick={handleFormEditSubmit}
                                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                    >
                                        Valider
                                    </button>
                                </form>
                            </div>
                        )}

                        {showTechPartnerForm && !isPreStudyVisible && (
                            <div className="mt-4 border p-4">
                                <div className="flex justify-end">
                                    <div>
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => setIsPreStudyVisible(true)}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                </div>

                                <h3 className="text-3xl text-center font-bold mt-2 mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                                <form onSubmit={handlePartnerFormSubmit}>
                                    <input
                                        type="hidden"
                                        id="orderID"
                                        name="orderID"
                                        value={selectedOrderId}
                                        readOnly
                                    />
                                    <input
                                        type="hidden"
                                        id="orderEmail"
                                        name="orderEmail"
                                        value={selectedOrderEmail}
                                        readOnly
                                    />
                                    <div className="mb-2">
                                        <label htmlFor="fdtRef" className="block text-gray-700 font-bold">
                                            Référence du FDT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="fdtRef"
                                            name="fdtRef"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="fatRef" className="block text-gray-700 font-bold">
                                            Référence du FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="fatRef"
                                            name="fatRef"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="dFatCustomer" className="block text-gray-700 font-bold">
                                            Distance FAT-Client (m) <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="dFatCustomer"
                                            name="dFatCustomer"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="oltSfp" className="block text-gray-700 font-bold">
                                            OLT - F/S/P <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="oltSfp"
                                            name="oltSfp"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="portFat" className="block text-gray-700 font-bold">
                                            Port FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="portFat"
                                            name="portFat"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="fatGpsCoordinates" className="block text-gray-700 font-bold">
                                            Coordonnée GPS du FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="fatGpsCoordinates"
                                            name="fatGpsCoordinates"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="customerGpsCoordinates" className="block text-gray-700 font-bold">
                                            Coordonnée GPS Client <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="customerGpsCoordinates"
                                            name="customerGpsCoordinates"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="nbPoToInstall" className="block text-gray-700 font-bold">
                                            Nombre de poteau à installer <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="nbPoToInstall"
                                            name="nbPoToInstall"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        />
                                    </div>


                                    <div className="mb-2">
                                        <label htmlFor="result" className="block text-gray-700 font-bold">
                                            Résultat Pré-étude prestataire <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <select
                                            id="result"
                                            className="border rounded w-full py-2 px-3"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                            required
                                        >
                                            <option value="">Quel est le résultat de la pré-étude ?</option>
                                            <option value="Concluante">Concluante</option>
                                            <option value="Non Concluante">Non Concluante</option>
                                        </select>
                                    </div>
                                    {/* <p>{selectedOption}</p> */}
                                    <div className="mb-2">
                                        <label htmlFor="preEtudeObs" className="block text-gray-700 font-bold">
                                            Observations Pré-étude <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <textarea
                                            id="preEtudeObs"
                                            name="preEtudeObs"
                                            className="border rounded w-full py-2 px-3"
                                            required
                                        ></textarea>
                                    </div>

                                    <button
                                        type="submit"
                                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                    >
                                        Valider
                                    </button>
                                </form>
                            </div>
                        )}

                        {editingPreStudyOrder && !isEditPreStudyVisible && (
                            <div className="mt-4 border p-4">
                                <div className="flex justify-end">
                                    <div>
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => setIsEditPreStudyVisible(true)}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                </div>
                                <h3 className="text-3xl text-center font-bold mt-2 mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                                <form>
                                    <div className="mb-2">
                                        <label htmlFor="editedFdtRef" className="block text-gray-700 font-bold">
                                            Référence du FDT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedFdtRef"
                                            name="editedFdtRef"
                                            value={editedFdtRef}
                                            onChange={(e) => setEditedFdtRef(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedFatRef" className="block text-gray-700 font-bold">
                                            Référence du FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedFatRef"
                                            name="editedFatRef"
                                            value={editedFatRef}
                                            onChange={(e) => setEditedFatRef(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedDFatCustomer" className="block text-gray-700 font-bold">
                                            Distance FAT-Client (m) <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedDfatCustomer"
                                            name="editedDfatCustomer"
                                            value={editedDfatCustomer}
                                            onChange={(e) => setEditedDfatCustomer(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedOltSfp" className="block text-gray-700 font-bold">
                                            OLT - F/S/P <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedOltSfp"
                                            name="editedOltSfp"
                                            value={editedOltSfp}
                                            onChange={(e) => setEditedOltSfp(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedPortFat" className="block text-gray-700 font-bold">
                                            Port FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedPortFat"
                                            name="editedPortFat"
                                            value={editedPortFat}
                                            onChange={(e) => setEditedPortFat(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedFatGpsCoordinates" className="block text-gray-700 font-bold">
                                            Coordonnée GPS du FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedFatGpsCoordinates"
                                            name="editedFatGpsCoordinates"
                                            value={editedFatGpsCoordinates}
                                            onChange={(e) => setEditedFatGpsCoordinates(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedCustomerGpsCoordinates" className="block text-gray-700 font-bold">
                                            Coordonnée GPS Client <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedCustomerGpsCoordinates"
                                            name="editedCustomerGpsCoordinates"
                                            value={editedCustomerGpsCoordinates}
                                            onChange={(e) => setEditedCustomerGpsCoordinates(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedNbPoToInstall" className="block text-gray-700 font-bold">
                                            Nombre de poteau à installer <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="editedNbPoToInstall"
                                            name="editedNbPoToInstall"
                                            value={editedNbPoToInstall}
                                            onChange={(e) => setEditedNbPoToInstall(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <label htmlFor="editedResult" className="block text-gray-700 font-bold">
                                            Résultat Pré-étude prestataire <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <select
                                            id="editedResult"
                                            className="border rounded w-full py-2 px-3"
                                            value={editedResult}
                                            onChange={handleSelectChangeEditedResult}
                                        >
                                            <option value="">Quel est le résultat de la pré-étude ?</option>
                                            <option value="Concluante">Concluante</option>
                                            <option value="Non Concluante">Non Concluante</option>
                                        </select>
                                    </div>
                                    {/* <p>{selectedOption}</p> */}
                                    <div className="mb-2">
                                        <label htmlFor="editedPreEtudeObs" className="block text-gray-700 font-bold">
                                            Observations Pré-étude <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <textarea
                                            id="editedPreEtudeObs"
                                            name="editedPreEtudeObs"
                                            className="border rounded w-full py-2 px-3"
                                            onChange={(e) => setEditedPreEtudeObs(e.target.value)}
                                            value={editedPreEtudeObs}
                                        ></textarea>
                                    </div>

                                    <button
                                        type="button"
                                        onClick={handlePartnerFormEditSubmit}
                                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                    >
                                        Enregistrer
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

export default PreStudyOrder;