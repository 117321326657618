import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import WhatsAppButton from '../components/WhatsAppButton';

const Verify = () => {
    const [loading, setLoading] = useState(false)

    const { id } = useParams();

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [address, setAddress] = useState(null);
    const [test, setTest] = useState(false);
    const [isReallyUserLocation, setIsReallyUserLocation] = useState(false);
    const [userCoordinates, setUserCoordinates] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const checkCurrentUserPosition = () => {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude, accuracy } = position.coords;

            setLatitude(latitude);
            setLongitude(longitude);

            const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
            fetch(url).then(res => res.json()).then(data => {
                setAddress(data.address);
                setTest(true);
            }).catch(() => {
                console.log('Error fetching data from API');
            });

            setLoading(false);
        });
    }

    const handleChange = (event) => {
        setUserCoordinates(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const coordinatePattern = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;

        if (!userCoordinates) {
            setErrorMessage('Ce champ ne peut pas être vide.');
        } else if (!coordinatePattern.test(userCoordinates)) {
            setErrorMessage('Les coordonnées entrées ne sont pas valides. Utilisez le format: LATITUDE,LONGITUDE');
        } else {
            setErrorMessage('');
            const coordinates = userCoordinates.split(',');
            const latitude = coordinates[0];
            const longitude = coordinates[1];

            console.log('gps coordinates: ', latitude, longitude);

            //window.location.href = `/location/${id}`;
        }
    }

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../assets/img/icons/gif/loading-icon-animated.jpeg')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <main className="w-full flex">
                    <div
                        className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                        style={{ backgroundImage: `url(${require('../assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif')})` }}
                    ></div>
                    <div className="flex-1 flex items-center justify-center h-screen">
                        <div className="w-full max-w-md space-y-3 px-4 bg-white text-gray-600 sm:px-0">
                            <div className="text-center">
                                <img src={require('../assets/img/icons/favicon/favicon-camtel.png')} width={220} className="mx-auto" />
                                <div className="space-y-2">
                                    <h3 className="text-gray-800 text-2xl font-bold sm:text-4xl text-center">Bienvenue sur FIBERCONNECT !</h3>
                                </div>
                            </div>
                            {test === false ? (
                                <>
                                    <p className='text-center'>Veuillez tester votre éligibilité en cliquant sur le bouton ci-dessous.</p>
                                    <button
                                        onClick={checkCurrentUserPosition}
                                        className="w-full px-4 py-2 text-white font-medium bg-sky-500 hover:bg-sky-600 active:bg-sky-600 rounded-lg duration-150"
                                    >
                                        Tester mon éligibilité
                                    </button>
                                </>
                            ) : (
                                <>
                                    {address && (
                                        <>
                                            {isReallyUserLocation === false ? (
                                                <div className='text-center'>
                                                    <p className='text-red-500 font-bold'>Ces informations reflètent-elles l'emplacement où la fibre optique est sollicitée pour installation ?</p>
                                                    <p><span className='font-bold'>Pays:</span> {address.country}</p>
                                                    <p><span className='font-bold'>Région:</span> {address.state}</p>
                                                    <p><span className='font-bold'>Ville:</span> {address.city}</p>
                                                    <p><span className='font-bold'>Département:</span> {address.county}</p>
                                                    <p><span className='font-bold'>Arrondissement:</span> {address.city_district}</p>
                                                    <p><span className='font-bold'>Quartier:</span> {address.suburb}</p>
                                                    <p><span className='font-bold'>Rue:</span> {address.road}</p>
                                                    <div className='flex p-2 m-2'>
                                                        <Link to={`/location/${id}`}
                                                            className='w-full px-4 py-2 mx-2 text-white font-medium bg-green-500 hover:bg-green-600 active:bg-green-600 rounded-lg duration-150'
                                                        >
                                                            Oui
                                                        </Link>
                                                        <button
                                                            onClick={() => setIsReallyUserLocation(true)}
                                                            className='w-full px-4 py-2 mx-2 text-white font-medium bg-red-500 hover:bg-red-600 active:bg-red-600 rounded-lg duration-150'
                                                        >
                                                            Non
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <form onSubmit={handleSubmit} className="space-y-6">
                                                        <h2 for="coordinates" className="text-center">
                                                            Entrez manuellement vos coordonnées sous format: LATITUDE,LONGITUDE
                                                        </h2>
                                                        {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}
                                                        <input
                                                            type="text"
                                                            value={userCoordinates} onChange={handleChange}
                                                            placeholder='LATITUDE,LONGITUDE'
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                                        />
                                                        <input
                                                            type="submit"
                                                            value="Valider"
                                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 cursor-pointer"
                                                        />
                                                        <p className='text-red-500 text-center mt-2'>NB: N'oubliez surtout pas la virgule entre les coordonnées (latitude, longitude).</p>
                                                    </form>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </main >
            )
            }

            <WhatsAppButton />
        </>
    );
}

export default Verify;