import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../admin/components/Sidebar";
import Header from "../admin/components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "../admin/components/FormatDate";
import * as XLSX from 'xlsx';
import { FaTrophy, FaSmile, FaThumbsUp } from 'react-icons/fa';

const Payment = () => {

    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [filterAgency, setFilterAgency] = useState('ALL');
    const [filterDate, setFilterDate] = useState('');
    const [successMessage, setSuccessMessage] = useState("");

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    function formatNumberWithSpaces(number) {
        if (number == null) {
            return 'N/A';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    useEffect(() => {
        if (token) {
            const getPayments = async () => {
                setLoading(true);

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/payment/`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const paidOrders = response.data.paidOrders;
                            setPayments(paidOrders);
                            filterPayments(paidOrders, filterStatus);

                            const successfulPayments = paidOrders.filter(order => order.status === 'SUCCESSFULL').length;
                            const millions = Math.floor(successfulPayments / 20);

                            if (millions >= 1) {
                                if (successfulPayments % 20 === 0) {
                                    setSuccessMessage(`Félicitations! Nous avons atteint la barre de ${millions} million${millions > 1 ? 's' : ''} de FCFA des frais d'installations sur FIBERCONNECT!`);
                                } else {
                                    setSuccessMessage(`Félicitations! Nous avons dépassé la barre de ${millions} million${millions > 1 ? 's' : ''} de FCFA des frais d'installations sur FIBERCONNECT!`);
                                }
                            }

                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                    setLoading(false);
                }
            };

            getPayments();
        }
    }, [filterStatus, token]);

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    function getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    }

    useEffect(() => {
        filterPayments(payments);
    }, [filterStatus, filterAgency, filterDate, payments]);

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleAgencyFilterChange = (e) => {
        setFilterAgency(e.target.value);
    };

    const handleDateFilterChange = (e) => {
        setFilterDate(e.target.value);
    };

    const filterPayments = (payments) => {
        let filtered = payments;

        if (filterStatus !== 'ALL') {
            filtered = filtered.filter(payment => payment.status === filterStatus);
        }

        if (filterAgency !== 'ALL') {
            filtered = filtered.filter(payment => payment.agencyName === filterAgency);
        }

        if (filterDate) {
            filtered = filtered.filter(payment => {
                const paymentDate = new Date(payment.orderPaidAt);
                const formattedPaymentDate = `${paymentDate.getFullYear()}-${String(paymentDate.getMonth() + 1).padStart(2, '0')}-${String(paymentDate.getDate()).padStart(2, '0')}`;
                return formattedPaymentDate === filterDate;
            });
        }

        setFilteredPayments(filtered);
    };

    const countFilteredPaymentsByStatus = (status) => {
        return payments.filter(payment =>
            (filterAgency === 'ALL' || payment.agencyName === filterAgency) &&
            (!filterDate || new Date(payment.orderPaidAt).toISOString().split('T')[0] === filterDate) &&
            (status === 'ALL' || payment.status === status)
        ).length;
    };

    const countPaymentsByAgency = (agency) => {
        return payments.filter(payment =>
            (filterStatus === 'ALL' || payment.status === filterStatus) &&
            (!filterDate || new Date(payment.orderPaidAt).toISOString().split('T')[0] === filterDate) &&
            (agency === 'ALL' || payment.agencyName === agency)
        ).length;
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'PENDING':
                return 'text-yellow-500';
            case 'FAILED':
                return 'text-red-500';
            case 'SUCCESSFULL':
                return 'text-green-500';
            case 'EXPIRED':
                return 'text-black-500';
            case 'CANCELLED':
                return 'text-orange-500';
            case 'INITIATED':
                return 'text-blue-500';
            default:
                return '';
        }
    };

    const currentDate = getCurrentDate();

    // DOWNLOAD PAYMENTS
    const handleDownload = () => {
        setLoading(true);

        axios.get(`${BASE_URL}/api/payment`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data.paidOrders;

                data = data.map(item => ({
                    PAYMENT_ID: item.idPaidOrder,
                    EXTERNAL_ID: item.cbsOrderId,
                    PHONE_NUMBER: item.subscriberMsisdn,
                    DATE_CREATION: item.orderPaidAt,
                    COLLECTED_AMOUNT: item.installationAmount,
                    CURRENCY: '',
                    INIT_TXN_MSG: '',
                    CONFIRM_TXN_MSG: '',
                    SUBSCRIBER_MSISDN: item.subscriberMsisdn,
                    TRANSACTION_MODE: '',
                    PAY_TOKEN: item.payToken,
                    COLLECT_STATUS: item.status,
                    CALLED_METHOD: '',
                    REQUEST_ENV: '',
                    REASON: '',
                    PAYMENT_METHOD: item.paymentMethod,
                    STATUS: '',
                    PARTYID_TYPE: '',
                    PAYEE_NOTE: '',
                    TRANSACTION_ID: item.fibTxnId,
                    ACCT_ID: item.billingAccount,
                    CUSTOMER_CODE: item.crmCustomerCode,
                    INVOICE_NO: item.cbsOrderId,
                    INVOICE_AMOUNT: item.installationAmount,
                    ORDER_ID: '',
                    CREATE_TIME: '',
                    TXN_ID: item.transactionId,
                    INIT_TXN_STATUS: '',
                    CONFIRM_TXN_STATUS: '',
                    DESCRIPTION: '',
                    CHANNEL_USER_MSISDN: item.channelUserMsisdn,
                    FINANCIALTXN_ID: item.cbsOrderId,
                    CUSTOMER_NAME: item.customerName,
                    AGENCY: item.agencyName
                }));

                const worksheet = XLSX.utils.json_to_sheet(data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');

                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                const url = window.URL.createObjectURL(dataBlob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'FIBERCONNECT-TXN-' + currentDate + '.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données ou de la création du fichier Excel :', error);
                alert('Erreur lors de la récupération des données ou de la création du fichier Excel ');

                setLoading(false);
            });
    };

    const verifyPayment = async (idPaidOrder, idSheet) => {
        try {
            const response = await axios.patch(`${BASE_URL}/api/payment/update/status/${idPaidOrder}/${idSheet}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la vérification du paiement:', error);
            alert('Erreur lors de la vérification du paiement');
        }
    }

    const resetFilters = () => {
        setFilterStatus('ALL');
        setFilterAgency('ALL');
        setFilterDate('');
    };

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../../assets/img/icons/gif/loading-points.webp')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <>
                        <Header />

                        <Sidebar setHideText={setHideText} hideText={hideText} />

                        <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[75%] 2xl:w-[73%]'}`}>
                            <div>
                                <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des paiements</h1>

                                {successMessage && (
                                    <div className="flex items-center justify-center bg-green-100 text-green-700 font-bold py-4 px-6 mb-6 rounded-lg shadow-md">
                                        <FaTrophy className="mr-2" />
                                        <span>{successMessage}</span>
                                        <FaSmile className="ml-2" />
                                        <FaThumbsUp className="ml-2" />
                                    </div>
                                )}

                                <div className="flex justify-between mb-4">
                                    <div className="mb-4">
                                        <label htmlFor="filterStatus" className="mr-2 font-bold">Filtrer par statut :</label>
                                        <select id="filterStatus" value={filterStatus} onChange={handleFilterChange}>
                                            <option value="ALL">Tous les paiements ({countFilteredPaymentsByStatus('ALL')})</option>
                                            <option value="SUCCESSFULL">Paiements réussis ({countFilteredPaymentsByStatus('SUCCESSFULL')})</option>
                                            <option value="PENDING">Paiements en attente ({countFilteredPaymentsByStatus('PENDING')})</option>
                                            <option value="FAILED">Paiements échoués ({countFilteredPaymentsByStatus('FAILED')})</option>
                                            <option value="CANCELLED">Paiements annulés ({countFilteredPaymentsByStatus('CANCELLED')})</option>
                                            <option value="INITIATED">Paiements initiés ({countFilteredPaymentsByStatus('INITIATED')})</option>
                                            <option value="EXPIRED">Paiements expirés ({countFilteredPaymentsByStatus('EXPIRED')})</option>
                                        </select>
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="filterAgency" className="mr-2 font-bold">Filtrer par agence :</label>
                                        <select id="filterAgency" value={filterAgency} onChange={handleAgencyFilterChange}>
                                            <option value="ALL">Toutes les agences ({countPaymentsByAgency('ALL')})</option>
                                            {Array.from(new Set(payments.map(payment => payment.agencyName)))
                                                .map(agency => (
                                                    <option key={agency} value={agency}>
                                                        {agency} ({countPaymentsByAgency(agency)})
                                                    </option>
                                                ))}
                                        </select>
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="filterDate" className="mr-2 font-bold">Filtrer par date de paiement :</label>
                                        <input
                                            type="date"
                                            id="filterDate"
                                            value={filterDate}
                                            onChange={handleDateFilterChange}
                                            className="border border-gray-300 rounded px-2 py-1"
                                        />
                                    </div>

                                    <button
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-5 mb-4 rounded-full"
                                        onClick={resetFilters}
                                        title='Effacer le filtre'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-filter-x"><path d="M13.013 3H2l8 9.46V19l4 2v-8.54l.9-1.055" /><path d="m22 3-5 5" /><path d="m17 3 5 5" /></svg>
                                    </button>


                                    <button
                                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-5 ml-2 mb-4 rounded-full"
                                        onClick={handleDownload}
                                        title='Télécharger'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-cloud-download"><path d="M12 13v8l-4-4" /><path d="m12 21 4-4" /><path d="M4.393 15.269A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.436 8.284" /></svg>
                                    </button>

                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-5 ml-2 mb-4 rounded-full"                                        
                                    >
                                        <a href="/verif-payment" title='Vérifier les paiements'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-check"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="m9 12 2 2 4-4" /></svg>
                                        </a>
                                    </button>
                                </div>

                                <div className="mt-2 overflow-x-auto">
                                    <table className="w-full table-auto">
                                        <thead>
                                            <tr className="text-center">
                                                <th className="px-2 py-2">Id de la souscription</th>
                                                <th className="px-2 py-2">Nom du client</th>
                                                <th className="px-2 py-2">Agence d'attache</th>
                                                <th className="px-2 py-2">Code client CRM</th>
                                                <th className="px-2 py-2">Numéro facture d'installation (CBS)</th>
                                                <th className="px-2 py-2">Montant de la transaction</th>
                                                <th className="px-4 py-2">Numéro de téléphone payeur</th>
                                                <th className="px-2 py-2">Méthode de paiement</th>
                                                <th className="px-2 py-2">Statut de paiement</th>
                                                <th className="px-2 py-2">Statut de paiement CBS</th>
                                                <th className="px-2 py-2">Transaction ID (Opérateur)</th>
                                                <th className="px-2 py-2">Transaction ID (CAMTEL)</th>
                                                <th className="px-2 py-2">Token de paiement (PayToken)</th>
                                                <th className="px-2 py-2">Date de paiement</th>
                                                <th className="px-2 py-2">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-600 divide-y">
                                            {filteredPayments.map((payment) => {
                                                const idSheet = payment.subscriptionId.split('-')[1];

                                                return (
                                                    <tr key={payment.idPaidOrder} className="border-b hover:bg-gray-100">
                                                        <td className="px-2 py-2 text-center">{payment.subscriptionId}</td>
                                                        <td className="px-2 py-2 text-center">{payment.customerName}</td>
                                                        <td className="px-2 py-2 text-center">{payment.agencyName}</td>
                                                        <td className="px-2 py-2 text-center">{payment.crmCustomerCode ? payment.crmCustomerCode : 'Client pas encore créé dans le CRM'}</td>
                                                        <td className="px-2 py-2 text-center">{payment.cbsOrderId ? payment.cbsOrderId : 'Client pas encore pris en facturation'}</td>
                                                        <td className="px-2 py-2 text-center">{formatNumberWithSpaces(payment.installationAmount)} FCFA</td>
                                                        <td className="px-2 py-2 text-center">{payment.subscriberMsisdn}</td>
                                                        <td className="px-2 py-2 text-center">{payment.paymentMethod === 'ORANGE' ? 'ORANGE Money' : 'MTN Mobile Money'}</td>
                                                        <td className={`px-2 py-2 text-center font-bold ${getStatusColor(payment.status)}`}>{payment.status}</td>
                                                        <td className={`px-2 py-2 text-center font-bold`}>{payment.cbsStatus === 'VERIFIED' ? 'AUTHENTIFIÉ' : 'NON AUTHENTIFIÉ'}</td>
                                                        <td className="px-2 py-2 text-center">{payment.transactionId}</td>
                                                        <td className="px-2 py-2 text-center">{payment.fibTxnId}</td>
                                                        <td className="px-2 py-2 text-center">{payment.payToken}</td>
                                                        <td className="px-2 py-2 text-center"><FormatDate dateStr={payment.orderPaidAt} /></td>
                                                        <td className={`px-2 py-2 text-center font-bold`}>
                                                            {payment.status === 'PENDING' ? (
                                                                <button
                                                                    onClick={() => verifyPayment(payment.idPaidOrder, idSheet)}
                                                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                                                >
                                                                    Vérifier ce paiement
                                                                </button>
                                                            ) : (
                                                                'Paiement déjà vérifié.'
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default Payment;