import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../admin/components/Sidebar";
import Header from "../admin/components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "../admin/components/FormatDate";
import CountDownTimer from "../admin/components/CountDownTimer";
import * as XLSX from 'xlsx';

const Appointment = () => {

    const [loading, setLoading] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedEntity, setSelectedEntity] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [entities, setEntities] = useState([]);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    useEffect(() => {
        if (token) {
            const getRDVs = async () => {
                setLoading(true);

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/appointment/`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const appointments = response.data;
                            setAppointments(appointments);
                            setFilteredAppointments(appointments);

                            const uniqueEntities = [...new Set(appointments.map(appointment => appointment.nameEntity))];
                            setEntities(uniqueEntities);

                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                    setLoading(false);
                }
            };

            getRDVs();
        }
    }, [token]);

    const handleDateFilterChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        filterAppointments(date, selectedEntity, statusFilter);
    };

    const handleEntityFilterChange = (event) => {
        const entity = event.target.value;
        setSelectedEntity(entity);
        filterAppointments(selectedDate, entity, statusFilter);
    };

    const handleStatusFilterChange = (event) => {
        const status = event.target.value;
        setStatusFilter(status);
        filterAppointments(selectedDate, selectedEntity, status);
    };

    const filterAppointments = (date, entity, status) => {
        let filtered = appointments;

        if (date) {
            filtered = filtered.filter(appointment =>
                new Date(appointment.appointmentDateTime).toISOString().split('T')[0] === date
            );
        }

        if (entity) {
            filtered = filtered.filter(appointment =>
                appointment.nameEntity.toLowerCase().includes(entity.toLowerCase())
            );
        }

        if (status) {
            filtered = filtered.filter(appointment => {
                const isInstalled = appointment.idInstallation;
                const isDelayed = !isInstalled && new Date(appointment.appointmentDateTime) < new Date();
                const isPending = !isInstalled && new Date(appointment.appointmentDateTime) >= new Date();

                if (status === "installed") {
                    return isInstalled;
                } else if (status === "pending") {
                    return isPending;
                } else if (status === "delayed") {
                    return isDelayed;
                }
                return true;
            });
        }

        setFilteredAppointments(filtered);
    };

    const resetFilters = () => {
        setSelectedDate('');
        setSelectedEntity('');
        setStatusFilter('');
        setFilteredAppointments(appointments);
    };

    const calculateTimeDifference = (startDate, endDate) => {
        const difference = new Date(endDate) - new Date(startDate);
        const days = Math.abs(Math.floor(difference / (1000 * 60 * 60 * 24)));
        const hours = Math.abs(Math.floor((difference / (1000 * 60 * 60)) % 24));
        const minutes = Math.abs(Math.floor((difference / 1000 / 60) % 60));
        const seconds = Math.abs(Math.floor((difference / 1000) % 60));

        return { difference, days, hours, minutes, seconds };
    };

    const downloadExcel = () => {
        const dataToExport = filteredAppointments.map(appointment => ({
            'Id Souscription': appointment.idSubscription + appointment.idSheet,
            'Nom du client': appointment.nameOrSocialName,
            'Contact du client': appointment.mobile,
            'Entité d\'installation': appointment.nameEntity,
            'Technicien installateur': appointment.nom + ' ' + appointment.prenom,
            'Jour & Heure du RDV': appointment.appointmentDateTime,
            'Installation dans': appointment.idInstallation
                ? calculateTimeDifference(appointment.appointmentDateTime, appointment.installationCreatedAt)
                : 'N/A',
            'Statut d\'installation': appointment.idInstallation ? 'Client installé' : 'Client en retard d\'installation'
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");

        XLSX.writeFile(workbook, "filtered_appointments.xlsx");
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <>
                        <Header />

                        <Sidebar setHideText={setHideText} hideText={hideText} />

                        <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[75%] 2xl:w-[73%]'}`}>
                            <div>
                                <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des RDV</h1>

                                <div className="mb-4 flex items-center space-x-4">
                                    <div>Filtre: </div>
                                    <input
                                        type="date"
                                        value={selectedDate}
                                        onChange={handleDateFilterChange}
                                        className="p-2 border border-gray-300 rounded"
                                    />
                                    <select
                                        value={selectedEntity}
                                        onChange={handleEntityFilterChange}
                                        className="p-2 border border-gray-300 rounded"
                                    >
                                        <option value="">Toutes les entités</option>
                                        {entities.map((entity, index) => (
                                            <option key={index} value={entity}>
                                                {entity}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        value={statusFilter}
                                        onChange={handleStatusFilterChange}
                                        className="p-2 border border-gray-300 rounded"
                                    >
                                        <option value="">Tous les clients</option>
                                        <option value="installed">Clients installés</option>
                                        <option value="pending">Clients en attente d'installation</option>
                                        <option value="delayed">Clients en retard d'installation</option>
                                    </select>
                                    <button
                                        onClick={resetFilters}
                                        className="p-2 bg-red-500 text-white rounded"
                                    >
                                        Réinitialiser
                                    </button>
                                    <button
                                        onClick={downloadExcel}
                                        className="p-2 bg-green-500 text-white rounded ml-auto"
                                    >
                                        Télécharger Excel
                                    </button>
                                </div>

                                <div className="mt-2 overflow-x-auto">
                                    <table className="w-full table-auto">
                                        <thead>
                                            <tr className="text-center">
                                                <th className="px-2 py-2">Id Souscription</th>
                                                <th className="px-2 py-2">Nom du client</th>
                                                <th className="px-2 py-2">Contact du client</th>
                                                <th className="px-2 py-2">Entité d'installation</th>
                                                <th className="px-2 py-2">Technicien installateur</th>
                                                <th className="px-2 py-2">Jour & Heure du RDV</th>
                                                <th className="px-2 py-2">Installation dans</th>
                                                <th className="px-2 py-2">Statut d'installation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredAppointments.map((appointment, index) => {
                                                const { difference, days, hours, minutes, seconds } = calculateTimeDifference(
                                                    appointment.appointmentDateTime,
                                                    appointment.installationCreatedAt
                                                );

                                                return (
                                                    <tr key={index} className="border-b hover:bg-gray-100 text-center">
                                                        <td className="px-2 py-2">{appointment.idSubscription + appointment.idSheet}</td>
                                                        <td className="px-2 py-2">{appointment.nameOrSocialName}</td>
                                                        <td className="px-2 py-2">{appointment.mobile}</td>
                                                        <td className="px-2 py-2">{appointment.nameEntity}</td>
                                                        <td className="px-2 py-2">{appointment.nom + ' ' + appointment.prenom}</td>
                                                        <td className="px-2 py-2 text-center">
                                                            <FormatDate dateStr={appointment.appointmentDateTime} />
                                                        </td>
                                                        <td className="px-2 py-2 text-center">
                                                            {appointment.idInstallation ? (
                                                                <>
                                                                    <span>
                                                                        Installé avec {difference < 0 ? 'une avance de ' : 'un retard de '}
                                                                    </span>
                                                                    <span className="font-bold">
                                                                        {days !== 0 && `${days} jours `}
                                                                        {hours !== 0 && `${hours} heures `}
                                                                        {minutes !== 0 && `${minutes} minutes `}
                                                                        {seconds !== 0 && `${seconds} secondes`}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <CountDownTimer targetDate={appointment.appointmentDateTime} />
                                                            )}
                                                        </td>
                                                        <td className={`px-2 py-2 ${appointment.idInstallation ? 'bg-green-300' : calculateTimeDifference(appointment.appointmentDateTime, new Date()).difference > 0 ? 'bg-red-300' : 'bg-yellow-300'}`}>
                                                            {appointment.idInstallation ? 'Client installé' : calculateTimeDifference(appointment.appointmentDateTime, new Date()).difference > 0 ? 'Client en retard d\'installation' : 'Client en attente d\'installation'}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default Appointment;