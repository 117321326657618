import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../../style.css';

const Sidebar = ({ setHideText, hideText }) => {

    const toggleHideText = () => {
        setHideText(!hideText);
        localStorage.setItem('sidebarHidden', !hideText);
    };

    useEffect(() => {
        const sidebarHidden = localStorage.getItem('sidebarHidden');
        if (sidebarHidden !== null) {
            setHideText(sidebarHidden === 'true');
        }
    }, []);

    const sidebarClass = `fixed top-0 left-0 w-full h-full border-r bg-white space-y-8 sm:w-80 lg:block xl:block 2xl:block hidden ${hideText ? 'text-hidden' : ''}`;

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState(null);
    const [userEntity, setUserEntity] = useState(null);
    const [userRole, setUserRole] = useState(null);

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        localStorage.removeItem('userEntity');
        localStorage.removeItem('userRole');
        localStorage.removeItem('token');
        localStorage.removeItem('userInfos');
        localStorage.removeItem('region');

        setIsUserLoggedIn(false);

        navigate('/login');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const email = localStorage.getItem('email');
        const userEntity = localStorage.getItem('userEntity');
        const userRole = localStorage.getItem('userRole');

        if (token && userId) {
            setIsUserLoggedIn(true);

            setUserId(userId);
            setEmail(email);
            setUserEntity(userEntity);
            setUserRole(userRole);

        } else {
            setIsUserLoggedIn(false);
        }
    }, []);

    const navigationProfile = [
        {
            href: '/profile',
            name: 'Profil',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} className="w-5 h-5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shield-plus"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" /><path d="M8 11h8" /><path d="M12 15V7" /></svg>
        }
    ];

    const navigationAgency = [
        {
            href: '/agency',
            name: 'Agences',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} className="w-5 h-5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-building-2">
                <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" /><path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" /><path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" /><path d="M10 6h4" /><path d="M10 10h4" /><path d="M10 14h4" /><path d="M10 18h4" />
            </svg>
        }
    ];

    const navigationPartner = [
        {
            href: '/partner',
            name: 'Partenaires',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} className="w-5 h-5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-briefcase"><rect width="20" height="14" x="2" y="7" rx="2" ry="2" /><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" /></svg>
        }
    ];

    const navigationSuperOrder = [
        {
            href: '/super-order',
            name: 'Commandes',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
            </svg>
        }
    ]

    const navigationOrder = [
        {
            href: '/order',
            name: 'Commandes',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
            </svg>
        }
    ]

    const navigationPreStudyOrder = [
        {
            href: '/pre-study-order',
            name: 'Commandes',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
            </svg>,
        }
    ]

    const navigationInstallation = [
        {
            href: '/installation',
            name: 'Installation',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} className="w-5 h-5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up-down"><path d="m21 16-4 4-4-4" /><path d="M17 20V4" /><path d="m3 8 4-4 4 4" /><path d="M7 4v16" /></svg>,
        }
    ]

    const navigationActivation = [
        {
            href: '/activation',
            name: 'Commandes',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-5 h-5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
            </svg>,
        }
    ]

    const navigation = [
        {
            href: '/dashboard',
            name: 'Accueil',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-5 h-5" strokeWidth={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home">
                <path strokeLinecap="round" strokeLinejoin="round" d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" />
            </svg>

        },
        // {
        //     href: 'javascript:void(0)',
        //     name: 'Offres FTTH',
        //     icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
        //         <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
        //     </svg>
        // },
    ]

    const navigationPayment = [
        {
            href: '/payment',
            name: 'Paiement',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-5 h-5" strokeWidth={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-dollar-sign">
                <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8" /><path d="M12 18V6" />
            </svg>,
        }
    ]

    const navigationAppointment = [
        {
            href: '/appointment',
            name: 'RDV',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-calendar-clock">
                <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5" /><path d="M16 2v4" /><path d="M8 2v4" /><path d="M3 10h5" /><path d="M17.5 17.5 16 16.3V14" /><circle cx="16" cy="16" r="6" />
            </svg>,
        }
    ]

    const navigationFeedback = [
        {
            href: '/feedback-list',
            name: 'Feedback',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/></svg>,
        }
    ]

    const LogOutButton = () => (
        <button onClick={handleLogout} title='Déconnexion' style={{ cursor: 'pointer', display: 'flex' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
            </svg> &nbsp;
            {!hideText && <span className="text">Déconnexion</span>}
        </button>
    );

    const navsFooter = [
        {
            href: '/help',
            name: 'Aide',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
            </svg>,
            title: 'Aide'
        },
        {
            href: '/settings',
            title: 'Paramètres',
            name: 'Paramètres',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        },
        {
            icon: <LogOutButton />
        }
    ]

    return (
        <>
            <nav className={sidebarClass}>
                <button
                    className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
                    onClick={toggleHideText}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12" /><line x1="4" x2="20" y1="6" y2="6" /><line x1="4" x2="20" y1="18" y2="18" /></svg>
                </button>
                <div class="flex flex-col h-full">
                    <div className='h-28 flex items-center justify-center px-8 mt-2'>
                        <a href='javascript:void(0)' className='flex-none'>
                            <img
                                src={require('../../../assets/img/icons/favicon/favicon-camtel.png')}
                                width={120}
                                className={`mx-auto ${hideText ? 'small-image' : ''}`}
                            />
                        </a>
                    </div>
                    <div className="overflow-auto">
                        <ul className="px-4 text-sm font-medium flex-1">
                            {
                                navigation.map((item, idx) => (
                                    <li key={idx}>
                                        <a
                                            href={item.href}
                                            title="Accueil"
                                            className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                } duration-150`}
                                        >
                                            <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                            {!hideText && <span className="text">{item.name}</span>}
                                        </a>
                                    </li>
                                ))
                            }
                            {
                                localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'admin_partner' ? (
                                    navigationProfile.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Profil"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                localStorage.getItem('userRole') === 'super_admin' ? (
                                    navigationAgency.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Agences"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                localStorage.getItem('userRole') === 'super_admin' || localStorage.getItem('userRole') === 'admin_camtel' ? (
                                    navigationPartner.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Partenaires"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                (localStorage.getItem('userRole') === 'super_admin') ? (
                                    navigationSuperOrder.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Commandes"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                (localStorage.getItem('userRole') === 'super_admin') ? (
                                    navigationPayment.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Paiement"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                (localStorage.getItem('userRole') === 'super_admin') ? (
                                    navigationAppointment.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="RDV"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                             {
                                (localStorage.getItem('userRole') === 'super_admin') ? (
                                    navigationFeedback.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Feedback"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                ((localStorage.getItem('userRole') !== 'technicien_camtel') && (localStorage.getItem('userRole') !== 'commercial_camtel') && (localStorage.getItem('userRole') !== 'technicien_partner')) && (localStorage.getItem('userRole') !== 'super_admin') ? (
                                    navigationOrder.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Commandes"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {localStorage.getItem('userRole') === 'technicien_camtel' ? (
                                navigationPreStudyOrder.map((item, idx) => (
                                    <li key={idx}>
                                        <a
                                            href={item.href}
                                            title="Commandes"
                                            className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                } duration-150`}
                                        >
                                            <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                            {!hideText && <span className="text">{item.name}</span>}
                                        </a>
                                    </li>
                                ))
                            ) : null
                            }
                            {
                                localStorage.getItem('userRole') === 'technicien_partner' ? (
                                    navigationInstallation.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Installation"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                localStorage.getItem('userRole') === 'commercial_camtel' ? (
                                    navigationActivation.map((item, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={item.href}
                                                title="Commandes"
                                                className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                    } duration-150`}
                                            >
                                                <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                                {!hideText && <span className="text">{item.name}</span>}
                                            </a>
                                        </li>
                                    ))
                                ) : null
                            }
                            {
                                navsFooter.map((item, idx) => (
                                    <li key={idx}>
                                        <a
                                            href={item.href}
                                            title={item.title}
                                            className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-sky-50 ${window.location.pathname === item.href ? 'bg-sky-500 text-white' : ''
                                                } duration-150`}
                                        >
                                            <div className={`text-gray-500 icon ${window.location.pathname === item.href ? 'text-white' : ''}`}>{item.icon}</div>
                                            {!hideText && <span className="text">{item.name}</span>}
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>

                        <div className="mt-2">
                            <div className="py-4 px-4 border-t">
                                <div className="flex items-center gap-x-4">
                                    <img
                                        src={require('../../../assets/img/icons/user-icon.png')}
                                        className={`w-12 h-12 rounded-full ${hideText ? 'small-avatar' : ''}`}
                                    />
                                    <div>
                                        {isUserLoggedIn && (
                                            <>
                                                {!hideText &&
                                                    <span className=" text block text-gray-700 text-sm font-semibold">
                                                        {userRole === 'super_admin' ? (
                                                            <>
                                                                Super Admin
                                                            </>
                                                        ) : userRole === 'admin_camtel' ? (
                                                            <>Chef d'Agence</>
                                                        ) : userRole === 'admin_partner' ? (
                                                            <>Partenaire</>
                                                        ) : userRole === 'technicien_camtel' ? (
                                                            <>Technicien CAMTEL</>
                                                        ) : userRole === 'technicien_partner' ? (
                                                            <>Technicien du partenaire</>
                                                        ) : userRole === 'commercial_partner' ? (
                                                            <>Commercial du partenaire</>
                                                        ) : userRole === 'commercial_camtel' ? (
                                                            <>Commercial de CAMTEL</>
                                                        ) : userRole === 'commercial_manager' ? (
                                                            <>Manager Commercial</>
                                                        ) : userRole === 'infra_admin' ? (
                                                            <>Infra-Admin</>
                                                        ) : null}
                                                    </span>
                                                }
                                            </>
                                        )}
                                        {!hideText && (
                                            <>
                                                {/* <span className="block text-gray-700 text-sm font-semibold">Alivika tony</span> */}
                                                <span
                                                    className="block mt-px text-gray-600 hover:text-indigo-600 text-xs"
                                                >
                                                    {userRole === 'super_admin' || userRole === 'commercial_manager' || userRole === 'infra_admin' ? 'CAMTEL' : userEntity}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </nav>
        </>
    );
};

export default Sidebar;