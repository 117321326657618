import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelUploader = () => {
    const [file1Data, setFile1Data] = useState([]);
    const [file2Data, setFile2Data] = useState([]);

    const handleFileUpload = (event, setFileData) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
            setFileData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };

    const normalizeMsisdn = (msisdn) => {
        return msisdn.replace(/\D/g, '');
    };

    const findCommonRows = () => {
        const file1Filtered = file1Data.filter(row => row.STATUS === 'Succès');
        const file1MsisdnSet = new Set(file1Filtered.map(row => normalizeMsisdn(row.SUBSCRIBER_MSISDN)));

        const commonRows = file2Data.filter(row =>
            row.COLLECT_STATUS === 'SUCCESSFULL' &&
            file1MsisdnSet.has(normalizeMsisdn(row.SUBSCRIBER_MSISDN))
        );

        if (commonRows.length > 0) {
            generateExcelFile(commonRows);
        } else {
            alert('Aucune ligne commune trouvée.');
        }
    };

    const generateExcelFile = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Common Rows");

        XLSX.writeFile(workbook, "common_rows.xlsx");
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-6">Upload des fichiers Excel</h2>

                <div className="mb-4">
                    <label className="block mb-2 text-gray-700">Upload du fichier excel de la DCF:</label>
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={(e) => handleFileUpload(e, setFile1Data)}
                        className="p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 text-gray-700">Upload di fichier excel de FIBERCONNECT:</label>
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={(e) => handleFileUpload(e, setFile2Data)}
                        className="p-2 border border-gray-300 rounded"
                    />
                </div>

                <button
                    onClick={findCommonRows}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Effectuer le croisement
                </button>
            </div>
        </div>
    );
}

export default ExcelUploader;