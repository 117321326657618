import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "./components/FormatDate";

const Installation = () => {
    const [orders, setOrders] = useState([]);

    const [filterStatus, setFilterStatus] = useState('ALL');
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedOrderEmail, setSelectedOrderEmail] = useState(null);
    const [selectedOrderName, setSelectedOrderName] = useState(null);
    const [selectedOrderOffer, setSelectedOrderOffer] = useState(null);
    const [selectedPhoneNumberActivated, setSelectedPhoneNumberActivated] = useState(null);
    const [offerPrice, setOfferPrice] = useState(null);
    const [showTechPartnerForm, setShowTechPartnerForm] = useState(false);

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isInstallationVisible, setIsInstallationVisible] = useState(true);

    const [selectedInstallationData, setSelectedInstallationData] = useState(null);

    const [details, setDetails] = useState(null);

    const [loading, setLoading] = useState(false);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    // LISTE DES COMMANDES DU TECHNICIEN PARTENAIRE
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'technicien_partner') {
            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/installation/` + localStorage.getItem('profileId'),
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            setOrders(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [showTechPartnerForm, token]);

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredOrders = Object.values(orders).filter((entry) => {
        const clientName = entry.preStudyOrder[0].nameOrSocialName;
        return (
            (filterStatus === "ALL" ||
                (filterStatus === "INSTALLED" && entry.installationData.length > 0 && entry.installationData[0].idInstallation) ||
                (filterStatus === "NOT_INSTALLED" && (entry.installationData.length === 0 || !entry.installationData[0].idInstallation))) &&
            (!searchQuery || (clientName && clientName.toLowerCase().includes(searchQuery.toLowerCase())))
        );
    });

    const showOrderDetails = async (order) => {
        setSelectedInstallationData(null);
        setDetails(null);

        setSelectedOrder(order);
        setSelectedOrderId(order.idSheet);
        setShowTechPartnerForm(false);
        setIsDetailsVisible(false);

        if (token) {
            try {
                const response = await axios.get(`${BASE_URL}/api/orders/details/` + order.idSheet, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const orderData = response.data[order.idSheet].preStudyOrderData[0];

                setSelectedInstallationData(response.data[order.idSheet].installationData[0]);

                const idBindedEntity = orderData.idBindedEntity;
                const entityResponse = await axios.get(`${BASE_URL}/api/entities/details/` + idBindedEntity, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                const entityData = entityResponse.data.entities;
                setDetails(entityData[0]);

            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
            }
        }
    };

    const pursueTechPartnerProcedure = async (order) => {
        setSelectedOrder(order);
        setSelectedOrderId(order.idSheet);
        setSelectedOrderEmail(order.email);
        setSelectedOrderName(order.nameOrSocialName);
        setSelectedOrderOffer(order.offerBlueMaxSelected);

        setIsInstallationVisible(false);

        try {
            const response = await axios.get(`${BASE_URL}/api/offer?offerName=` + order.offerBlueMaxSelected);

            setOfferPrice(response.data.offer[0].price)
        } catch (error) {
            console.error("Erreur lors de la récupération de l'offre", error);
        }

        setSelectedPhoneNumberActivated(order.phoneNumberActivated);

        setShowTechPartnerForm(true);
    };

    const handlePartnerFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const formData = {
            idSheet: selectedOrderId,
            email: selectedOrderEmail,
            name: selectedOrderName,
            offer: selectedOrderOffer,
            price: offerPrice,
            phoneNumberActivated: selectedPhoneNumberActivated,
            idTechPartner: localStorage.getItem('profileId'),
            portFat: e.target.portFat.value,
            nbBalancel: e.target.nbBalancel.value,
            cableMeterEntryFat: e.target.cableMeterEntryFat.value,
            usedCableLong: e.target.usedCableLong.value,
            atbNumber: e.target.atbNumber.value,
            nbCrochetArret: e.target.nbCrochetArret.value,
            clientCableMeter: e.target.clientCableMeter.value,
            nbArmedInstalledPoto: e.target.nbArmedInstalledPoto.value,
            ontNumber: e.target.ontNumber.value,
            stbStickNumber: e.target.stbStickNumber.value,
            tbNumber: e.target.tbNumber.value,
            nbGarter: e.target.nbGarter.value,
            nbSmoove: e.target.nbSmoove.value
        };

        const data = formData;

        if (token) {
            try {
                const config = {
                    method: 'post',
                    url: `${BASE_URL}/api/installation`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    data: data
                };

                const response = await axios.request(config);

                e.target.reset();

                setSelectedOrder(null);
                setSelectedOrderId(null);
                setShowTechPartnerForm(false);

                setIsInstallationVisible(true);
                setIsDetailsVisible(true);

                setLoading(false);

                alert('Données d\'installation inserées avec succès.');
            } catch (error) {
                console.error("Erreur lors de l'insertion des données d'installation.", error);
                setLoading(false);
                alert('Erreur lors de l\'insertion des données d\'installation.');
            }
        }
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                        <div className="w-full">
                            {isDetailsVisible && isInstallationVisible && (
                                <>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des commandes</h1>

                                    <div className="flex flex-col md:flex-row justify-between mb-4">
                                        <div className="flex items-center mb-4 md:mb-0">
                                            <div className="font-bold mr-2">Filtrer par: </div>
                                            <select
                                                className="border border-gray-300 py-2 px-4 rounded-lg"
                                                value={filterStatus}
                                                onChange={handleFilterChange}
                                            >
                                                <option value="ALL">Tous</option>
                                                <option value="INSTALLED">Client installé</option>
                                                <option value="NOT_INSTALLED">Client non installé</option>
                                            </select>
                                            <span className="ml-2 text-gray-600">({filteredOrders.length})</span>
                                        </div>

                                        <div className="w-full md:w-[50%]">
                                            <label className="relative block">
                                                <span className="sr-only">Search</span>
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search">
                                                        <circle cx="11" cy="11" r="8" />
                                                        <path d="m21 21-4.3-4.3" />
                                                    </svg>
                                                </span>
                                                <input
                                                    className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                    placeholder="Rechercher un client par son nom"
                                                    type="text"
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr className="text-center">
                                                <th className="px-2 py-2">Date de souscription</th>
                                                <th className="px-2 py-2">Nom du partenaire</th>
                                                <th className="px-2 py-2">Nom du client</th>
                                                <th className="px-2 py-2">Statut de la pré-étude</th>
                                                <th className="px-2 py-2">Statut de l'étude</th>
                                                <th className="px-2 py-2">Statut de l'activation</th>
                                                <th className="px-2 py-2">Statut de l'installation</th>
                                                <th className="px-4 py-2">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredOrders.length > 0 ? (
                                                filteredOrders.map((entry, key) => (
                                                    <tr key={key} className="border-b hover:bg-gray-100 text-center">
                                                        <td className="px-2 py-2">{<FormatDate dateStr={entry.preStudyOrder[0].sheetCreatedAt} />}</td>
                                                        <td className="px-2 py-2">{entry.preStudyOrder[0].nameEntity}</td>
                                                        <td className="px-2 py-2">{entry.preStudyOrder[0].nameOrSocialName}</td>
                                                        <td className="px-2 py-2">{entry.preStudyOrder[0].result || 'Pré-Étude non réalisée'}</td>
                                                        <td className="px-2 py-2">
                                                            {entry.preStudyOrder[0].validation || (entry.preStudyOrder[0].result ? 'Étude non réalisée' : 'En attente de la réalisation de la pré-étude')}
                                                        </td>
                                                        <td className="px-2 py-2">
                                                            {entry.preStudyOrder[0].idActivation ? 'Service activé' : 'Service non activé'}
                                                        </td>
                                                        <td className="px-2 py-2">
                                                            {entry.installationData.length > 0 && entry.installationData[0].idInstallation ? 'Client installé' : 'Client non installé'}
                                                        </td>
                                                        <td className="text-center px-4 py-2 text-center">
                                                            <button
                                                                onClick={() => { showOrderDetails(entry.preStudyOrder[0]) }}
                                                                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 m-2"
                                                            >
                                                                Détails
                                                            </button>
                                                            {entry.installationData.length > 0 && entry.installationData[0].idInstallation && (
                                                                <Link
                                                                    to={`/fiche/${entry.installationData[0].idSheet}`}
                                                                    className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 m-2 whitespace-nowrap"
                                                                >
                                                                    Prévisualiser la fiche
                                                                </Link>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="px-2 py-2" colSpan="8">Aucune donnée trouvée...</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            )}

                            {selectedOrder && !isDetailsVisible && isInstallationVisible && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end">
                                        <div className="flex">
                                            {/* {selectedInstallationData && selectedInstallationData.idInstallation !== null && (
                                                <button
                                                    onClick={() => {
                                                        // editPartnerProcedure(order) 
                                                    }}
                                                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold p-2 mr-2"
                                                >
                                                    Modifier l'installation
                                                </button>
                                            )} */}

                                            {((selectedInstallationData && selectedInstallationData.idInstallation === null) && (selectedOrder.paymentRef !== null && selectedOrder.paymentRef !== '')) && (
                                                <button
                                                    onClick={() => { pursueTechPartnerProcedure(selectedOrder) }}
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 mr-2"
                                                >
                                                    Poursuivre la procédure
                                                </button>
                                            )}

                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => setIsDetailsVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-2xl font-bold mb-2 uppercase text-center">Détails de la commande</h2>

                                    <p><strong>Date de souscription du client: </strong><FormatDate dateStr={selectedOrder.sheetCreatedAt} /></p>

                                    {selectedOrder.customerPhoto && (
                                        <div>
                                            <strong>Photo du client:</strong>
                                            <img
                                                src={selectedOrder.customerPhoto}
                                                alt="Photo du client"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 200, width: 200 }}
                                            />
                                        </div>
                                    )}
                                    <p><strong>N°ID Souscription :</strong> {selectedOrder.idSubscription + selectedOrder.idSheet}</p>
                                    <p><strong>Nom et Prénoms / Raison Sociale :</strong> {selectedOrder.nameOrSocialName}</p>
                                    <p><strong>Type de Client:</strong> {selectedOrder.customerType}</p>
                                    <p>
                                        <strong>Région:</strong>
                                        {
                                            selectedOrder.region === 'CE' ? 'Centre' :
                                                selectedOrder.region === 'LT' ? 'Littoral' :
                                                    selectedOrder.region === 'NO' ? 'Nord' :
                                                        selectedOrder.region === 'EN' ? 'Extrême-Nord' :
                                                            selectedOrder.region === 'AD' ? 'Adamaoua' :
                                                                selectedOrder.region === 'ES' ? 'Est' :
                                                                    selectedOrder.region === 'SU' ? 'SUD' :
                                                                        selectedOrder.region === 'OU' ? 'Ouest' :
                                                                            selectedOrder.region === 'NW' ? 'Nord-Ouest' :
                                                                                'Sud-Ouest'
                                        }
                                    </p>
                                    <p><strong>Ville:</strong> {selectedOrder.city}</p>
                                    <p><strong>Localisation Précise:</strong> {selectedOrder.localisation}</p>
                                    <p><strong>Lieu-dit:</strong> {selectedOrder.lieuDit}</p>
                                    {selectedOrder.customerGpsCoordinates && (<p><strong>Coordonnées GPS: </strong>{selectedOrder.customerGpsCoordinates}</p>)}
                                    <p>
                                        <strong>Plan de localisation:</strong>
                                        {selectedOrder.imageLocalisation && (selectedOrder.imageLocalisation.endsWith('.jpg') || selectedOrder.imageLocalisation.endsWith('.png')) ? (
                                            <img
                                                src={selectedOrder.imageLocalisation}
                                                alt="Plan de localisation"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 500, width: '100%' }}
                                            />
                                        ) : (
                                            <iframe
                                                src={selectedOrder.imageLocalisation}
                                                width="100%"
                                                height="500"
                                                title="PDF Viewer"
                                            ></iframe>
                                        )}
                                    </p>
                                    <p><strong>Email:</strong> {selectedOrder.email}</p>
                                    <p><strong>N° Mobile:</strong> {selectedOrder.mobile}</p>
                                    {selectedOrder.idCardNumber !== null && (
                                        <>
                                            <p><strong>Numéro de la CNI:</strong> {selectedOrder.idCardNumber}</p>
                                            <p>
                                                <strong>Carte Nationnale d'identité:</strong>
                                                <div className="flex">
                                                    {selectedOrder.rectoCni && (selectedOrder.rectoCni.endsWith('.jpg') || selectedOrder.rectoCni.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.rectoCni}
                                                            alt="Image Recto de la CNI"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.rectoCni}
                                                            width="50%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}

                                                    {selectedOrder.versoCni && (selectedOrder.versoCni.endsWith('.jpg') || selectedOrder.versoCni.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.versoCni}
                                                            alt="Image Verso de la CNI"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.versoCni}
                                                            width="50%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.idPassportNumber !== null && (
                                        <>
                                            <p><strong>Numéro du passeport:</strong> {selectedOrder.idPassportNumber}</p>
                                            {selectedOrder.idPasseportFront && (selectedOrder.idPasseportFront.endsWith('.jpg') || selectedOrder.idPasseportFront.endsWith('.png')) ? (
                                                <img
                                                    src={selectedOrder.idPasseportFront}
                                                    alt="Image Recto du passeport"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 250, width: '50%' }}
                                                />
                                            ) : (
                                                <iframe
                                                    src={selectedOrder.idPasseportFront}
                                                    width="100%"
                                                    height="300"
                                                    title="PDF Viewer"
                                                ></iframe>
                                            )}
                                        </>
                                    )}
                                    {selectedOrder.idResidencePermitNumber !== null && (
                                        <>
                                            <p><strong>Numéro dr la carte de séjour:</strong> {selectedOrder.idResidencePermitNumber}</p>
                                            <p>
                                                <strong>Carte de séjour:</strong>
                                                <div className="flex">
                                                    {selectedOrder.idResidencePermitFront && (selectedOrder.idResidencePermitFront.endsWith('.jpg') || selectedOrder.idResidencePermitFront.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.idResidencePermitFront}
                                                            alt="Image Recto de la carte de séjour"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.idResidencePermitFront}
                                                            width="100%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.commerceRegNumber !== '' && (
                                        <>
                                            <p><strong>N°Régistre Commerce :</strong> {selectedOrder.commerceRegNumber}</p>
                                            <p>
                                                <strong>Image du registre de commerce:</strong>
                                                <div className="flex">
                                                    {selectedOrder.rectoCommerceReg && (selectedOrder.rectoCommerceReg.endsWith('.jpg') || selectedOrder.rectoCommerceReg.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.rectoCommerceReg}
                                                            alt="Image du registre de commerce"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 'auto', width: '100%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.rectoCommerceReg}
                                                            width="100%"
                                                            height="500"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}

                                    <h3 className="text-2xl font-bold mt-2">TYPE D’OPERATION</h3>
                                    <p><strong>Formule d’abonnement choisie:</strong> {selectedOrder.offerBlueMaxSelected}</p>

                                    {selectedOrder.result !== null && (
                                        <>
                                            <div className="mt-4 border p-4">
                                                <h3 className="text-2xl font-bold mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                                                <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedOrder.preEtudeCreatedAt} /></p>
                                                <p><strong>Nom du prestataire :</strong> {selectedOrder.name}</p>
                                                <p><strong>Tel :</strong> {selectedOrder.telephoneEntity}</p>
                                                <p><strong>Email :</strong> {selectedOrder.emailEntity}</p>
                                                {
                                                    details && (
                                                        <>
                                                            <p><strong>Zone d’intervention :</strong> {details.interventionZone}</p>
                                                            <p><strong>Agence Commerciale :</strong> {details.name}</p>
                                                        </>
                                                    )
                                                }
                                                <p><strong>Référence du FDT :</strong> {selectedOrder.fdtRef}</p>
                                                <p><strong>Référence du FAT :</strong> {selectedOrder.fatRef}</p>
                                                <p><strong>Distance FAT-Client (m) :</strong> {selectedOrder.dFatCustomer}</p>
                                                <p><strong>OLT - F/S/P :</strong> {selectedOrder.oltSfp}</p>
                                                <p><strong>Port FAT :</strong> {selectedOrder.portFat}</p>
                                                <p><strong>Coordonnée GPS du FAT :</strong> {selectedOrder.fatGpsCoordinates}</p>
                                                <p><strong>Coordonnée GPS Client :</strong> {selectedOrder.customerGpsCoordinates}</p>
                                                <p><strong>Nombre de poteau à installer :</strong> {selectedOrder.nbPoToInstall}</p>
                                            </div>
                                            <div className="mt-4 border p-4">
                                                <h3 className="text-2xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE LA PRE-ETUDE</h3>
                                                <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedOrder.preEtudeCreatedAt} /></p>
                                                <p><strong>Statut de la validation par le technicien du prestataire:</strong> {selectedOrder.result}</p>
                                                <p><strong>Observations fait par le technicien du prestataire:</strong> {selectedOrder.preEtudeObs}</p>
                                            </div>
                                        </>
                                    )}
                                    {selectedOrder.validation !== '' && (
                                        (selectedOrder.validation !== '' || selectedOrder.etudeObs !== '') && (
                                            <div className="mt-4 border p-4">
                                                <h3 className="text-2xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE L'ETUDE</h3>
                                                <p><strong>Date de validation de la pré-étude: </strong><FormatDate dateStr={selectedOrder.preEtudeCreatedAt} /></p>
                                                {selectedOrder.validation !== '' && (
                                                    <p><strong>Statut de la validation par le technicien:</strong> {selectedOrder.validation}</p>
                                                )}
                                                {selectedOrder.etudeObs !== '' && (
                                                    <p><strong>Observations de l'étude:</strong> {selectedOrder.etudeObs}</p>
                                                )}
                                            </div>
                                        )
                                    )}

                                    {selectedOrder.crmCustomerCode !== null && selectedOrder.crmCustomerCode !== '' && (
                                        <div className="mt-4 border p-4">
                                            <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">ACTIVATION</h3>
                                            <p><strong>Date de l'activation: </strong><FormatDate dateStr={selectedOrder.activationCreatedAt} /></p>
                                            <p><strong>ID de la commande (CBS) :</strong> {selectedOrder.cbsOrderId}</p>
                                            <p><strong>Code Client CRM :</strong> {selectedOrder.crmCustomerCode}</p>
                                            <p><strong>Compte de facturation :</strong> {selectedOrder.billingAccount}</p>
                                            <p><strong>Numéro de Telephone :</strong> {selectedOrder.phoneNumberActivated}</p>
                                            <p><strong>Numéro ONT :</strong> {selectedOrder.ontNumber}</p>
                                            <p><strong>Référence paiement :</strong> {selectedOrder.paymentRef}</p>
                                            <p><strong>Date de paiement :</strong> {selectedOrder.paymentDate}</p>
                                            <p><strong>Numéro STB/IP Stick :</strong> {selectedOrder.stbIpStickNumber}</p>
                                        </div>
                                    )}

                                    {selectedInstallationData && selectedInstallationData.idInstallation !== null && (
                                        <div className="mt-4 border p-4">
                                            <h3 className="text-2xl font-bold mb-2 text-center">INSTALLATION PRESTATAIRE</h3>
                                            <p><strong>Date de l'installation: </strong><FormatDate dateStr={selectedInstallationData.installationCreatedAt} /></p>
                                            <p><strong>Numéro port FAT :</strong> {selectedInstallationData.portFat}</p>
                                            <p><strong>Nombre de Balancelle :</strong> {selectedInstallationData.nbBalancel}</p>
                                            <p><strong>Métrage câble entrée FAT :</strong> {selectedInstallationData.cableMeterEntryFat}</p>
                                            <p><strong>Longueur Câble utilisée (m) :</strong> {selectedInstallationData.usedCableLong}</p>
                                            <p><strong>Nombre d’ATB :</strong>{selectedInstallationData.atbNumber}</p>
                                            <p><strong>Nombre Crochet d’Arrêt :</strong>{selectedInstallationData.nbCrochetArret}</p>
                                            <p><strong>Métrage câble chez l’abonné :</strong> {selectedInstallationData.clientCableMeter}</p>
                                            <p><strong>Nombre de poteau armé installé :</strong> {selectedInstallationData.nbArmedInstalledPoto}</p>
                                            <p><strong>Numéro ONT :</strong> {selectedInstallationData.ontNumber}</p>
                                            <p><strong>Nombre de STB/ Stick :</strong> {selectedInstallationData.stbStickNumber}</p>
                                            <p><strong>Nombre de TB :</strong> {selectedInstallationData.tbNumber}</p>
                                            <p><strong>Nombre de Jarretière :</strong> {selectedInstallationData.nbGarter}</p>
                                            <p><strong>Nombre de Smoove :</strong> {selectedInstallationData.nbSmoove}</p>
                                        </div>
                                    )}
                                </div>
                            )}

                            {showTechPartnerForm && !isInstallationVisible && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end">
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => setIsInstallationVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h3 className="text-3xl text-center font-bold mt-2 mb-2 text-center">INSTALLATION</h3>
                                    <form onSubmit={handlePartnerFormSubmit}>
                                        <input
                                            type="hidden"
                                            id="orderID"
                                            name="orderID"
                                            value={selectedOrderId}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            id="orderEmail"
                                            name="orderEmail"
                                            value={selectedOrderEmail}
                                            readOnly
                                        />
                                        <div className="mb-2">
                                            <label htmlFor="portFat" className="block text-gray-700 font-bold">
                                                Port FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="portFat"
                                                name="portFat"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="nbBalancel" className="block text-gray-700 font-bold">
                                                Nombre de Balancelle <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="nbBalancel"
                                                name="nbBalancel"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="cableMeterEntryFat" className="block text-gray-700 font-bold">
                                                Métrage câble entrée FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="cableMeterEntryFat"
                                                name="cableMeterEntryFat"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="usedCableLong" className="block text-gray-700 font-bold">
                                                Longueur Câble utilisée (m) <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="usedCableLong"
                                                name="usedCableLong"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="atbNumber" className="block text-gray-700 font-bold">
                                                Nombre d’ATB <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="atbNumber"
                                                name="atbNumber"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="nbCrochetArret" className="block text-gray-700 font-bold">
                                                Nombre Crochet d’Arrêt <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="nbCrochetArret"
                                                name="nbCrochetArret"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="clientCableMeter" className="block text-gray-700 font-bold">
                                                Métrage câble chez l’abonné <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="clientCableMeter"
                                                name="clientCableMeter"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="nbArmedInstalledPoto" className="block text-gray-700 font-bold">
                                                Nombre de poteau armé installé <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="nbArmedInstalledPoto"
                                                name="nbArmedInstalledPoto"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="ontNumber" className="block text-gray-700 font-bold">
                                                Numéro ONT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="ontNumber"
                                                name="ontNumber"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="stbStickNumber" className="block text-gray-700 font-bold">
                                                Nombre de STB/ Stick <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="stbStickNumber"
                                                name="stbStickNumber"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="tbNumber" className="block text-gray-700 font-bold">
                                                Nombre de TB <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="tbNumber"
                                                name="tbNumber"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="nbGarter" className="block text-gray-700 font-bold">
                                                Nombre de Jarretière <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="nbGarter"
                                                name="nbGarter"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="nbSmoove" className="block text-gray-700 font-bold">
                                                Nombre de Smoove <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="nbSmoove"
                                                name="nbSmoove"
                                                className="border rounded w-full py-2 px-3"
                                                required
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Valider
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Installation;