import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "./components/FormatDate";
import Modal from 'react-modal';
import * as XLSX from 'xlsx';

const Order = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [techs, setTechs] = useState([]);

    const [allSheetData, setAllSheetData] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const [selectedPreStudyOrder, setSelectedPreStudyOrder] = useState(null);
    const [selectedInstallationData, setSelectedInstallationData] = useState(null);
    const [selectedActivationData, setSelectedActivationData] = useState(null);

    const [comPartnerName, setComPartnerName] = useState([]);
    const [techPartnerName, setTechPartnerName] = useState([]);
    const [techCamtelName, setTechCamtelName] = useState([]);
    const [comCamtelName, setComCamtelName] = useState([]);

    const [details, setDetails] = useState(null);

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);

    const [loading, setLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");

    const [partnerFilter, setPartnerFilter] = useState('');
    const [installationStatusFilter, setInstallationStatusFilter] = useState('');

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // ADMIN CAMTEL
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'admin_camtel') {
            setLoading(true);

            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/camtel/` + localStorage.getItem('userId'),
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setAllSheetData(allSheetData);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [token]);

    // ADMIN DU PARTENAIRE
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'admin_partner') {
            setLoading(true);

            const fetchOrders = async () => {
                const idEntity = localStorage.getItem('userId');

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/${idEntity}`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setAllSheetData(allSheetData);

                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [isOpen, token]);

    // COMMERCIAL DU PARTENAIRE
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'commercial_partner') {
            setLoading(true);

            const fetchOrders = async () => {
                const entityId = localStorage.getItem('userId');
                const profileId = localStorage.getItem('profileId');

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/${entityId}/${profileId}`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            setOrders(response.data);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [token]);

    // COMMERCIAL MANAGER
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'commercial_manager') {
            setLoading(true);

            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/com/manager/` + localStorage.getItem('region'),
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setAllSheetData(allSheetData);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [token]);

    // INFRA-ADMIN
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'infra_admin') {
            setLoading(true);

            const fetchOrders = async () => {
                const entityId = localStorage.getItem('userId');

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/infra/admin/${entityId}`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setAllSheetData(allSheetData);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [token]);

    const showOrderDetails = async (order) => {
        setSelectedPreStudyOrder(null);
        setSelectedActivationData(null);
        setSelectedInstallationData(null);

        setTechCamtelName([]);
        setTechPartnerName([]);
        setComCamtelName([]);
        setComPartnerName([]);

        setSelectedOrder(order);

        setIsDetailsVisible(false);

        if (token && order.idPartnerTechnician && order.idPartnerTechnician !== 0) {
            const techPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerTechnician, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setTechPartnerName(techPartnerProfile.data.profile[0]);
        } else {
            console.log('Pas de technicien assigné à cette commande.');
        }

        if (token && order && order.idPartnerCommercial && order.idPartnerCommercial !== 0) {
            const comPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerCommercial, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setComPartnerName(comPartnerProfile.data.profile[0]);
        } else {
            console.log('Commande autoprospectée ou par CAMTEL.');
        }

        if (token) {
            try {
                const response = await axios.get(`${BASE_URL}/api/orders/details/` + order.idSheet, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const orderData = response.data[order.idSheet].preStudyOrderData[0];
                setSelectedPreStudyOrder(orderData);

                if (orderData.idTechnicienCamtel && orderData.idTechnicienCamtel !== 0) {
                    const techCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + orderData.idTechnicienCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setTechCamtelName(techCamtelProfile.data.profile[0]);
                } else {
                    console.log('Pré-étude de la commande non validée par le Pool Technique de CAMTEL.');
                }

                setSelectedInstallationData(response.data[order.idSheet].installationData[0]);
                setSelectedActivationData(response.data[order.idSheet].activationData[0]);

                if (response.data[order.idSheet].activationData[0] && response.data[order.idSheet].activationData[0].idCommercialCamtel !== 0 && response.data[order.idSheet].activationData[0].idCommercialCamtel !== null) {
                    const comCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + response.data[order.idSheet].activationData[0].idCommercialCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setComCamtelName(comCamtelProfile.data.profile[0]);
                } else {
                    console.log('Commande non activée par le Pool Commercial de CAMTEL.');
                }

                const idBindedEntity = orderData.idBindedEntity;
                const entityResponse = await axios.get(`${BASE_URL}/api/entities/details/` + idBindedEntity, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                const entityData = entityResponse.data.entities;
                setDetails(entityData[0]);

            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
            }
        }
    };

    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'admin_partner') {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/user/${localStorage.getItem('userId')}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTechs(response.data.techs);
                } catch (error) {
                    console.error("Erreur lors de la récupération des techniciens :", error);
                }
            };

            fetchData();
        }
    }, [token]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredSheetData = allSheetData.filter(sheetData =>
        sheetData.preStudyOrder.some(order =>
            order.nameOrSocialName.toLowerCase().includes(searchQuery) &&
            (!partnerFilter || order.nameEntity === partnerFilter) &&
            (!installationStatusFilter || (sheetData.installationData.length > 0 && sheetData.installationData[0].idTechPartner !== null === (installationStatusFilter === 'Installé')))
        )
    );

    const filteredOrders = orders?.orders?.filter(order =>
        order.nameOrSocialName.toLowerCase().includes(searchQuery)
    ) || [];

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            borderRadius: '8px',
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        },
    };

    const CustomModal = ({ isOpen, closeModal, techs, selectedOrderId }) => {
        const [technician, setTechnician] = useState(null);

        const [formData, setFormData] = useState({
            idPartnerTechnician: null,
        });


        const handleSelectChangeTech = (e) => {
            const newValue = e.target.value;
            setTechnician(newValue);
            setFormData({ ...formData, idPartnerTechnician: newValue });
        }

        const handleFormSubmit = async (e) => {
            e.preventDefault();

            if (token) {
                try {
                    const response = await axios.patch(`${BASE_URL}/api/orders/tech/${selectedOrderId}`, formData, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    console.log(response.data);
                    setIsOpen(false);
                    alert('Technicien assignée avec succès.');
                    setIsDetailsVisible(true);
                } catch (error) {
                    console.error('Erreur lors de la soumission du formulaire :', error);
                    alert('Erreur survenue lors de l\'assignation du technicien.');
                }
            }
        };

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Liste des techniciens"
            >
                <input
                    type="hidden"
                    id="orderId"
                    name="orderId"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={selectedOrderId}
                    onChange={selectedOrderId}
                    readOnly
                />
                <h2 className="text-lg font-semibold mb-4">Liste des techniciens</h2>
                <select
                    id="nameEntity"
                    name="nameEntity"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={technician}
                    onChange={handleSelectChangeTech}
                >
                    <option value="">Choisissez un technicien</option>
                    {Array.isArray(techs) && techs.length > 0 ? (
                        techs.map((tech) => (
                            <option value={tech.id}>
                                {tech.nom}
                            </option>
                        ))
                    ) : (
                        <option value="">Aucun technicien trouvé.</option>
                    )}
                </select>
                <button
                    onClick={handleFormSubmit}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-4 rounded-full"
                >
                    Valider
                </button>
            </Modal>
        );
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    const handleDownloadExcel = () => {
        const dataToExport = filteredSheetData.map((sheetData) => {
            let rowData = {};

            if (Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0) {
                sheetData.preStudyOrder.forEach((order) => {
                    rowData['Date de souscription'] = order.sheetCreatedAt;
                    rowData['Partenaire/CERAF'] = order.nameEntity;
                    rowData['Nom du client'] = order.nameOrSocialName;
                    rowData['Statut d\'affectation de la commande'] = order.idPartnerTechnician === 0 || order.idPartnerTechnician === null || order.idPartnerTechnician === '' ? 'Non affectée à un technicien' : 'Affectée à un technicien';
                });
            }

            if (Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0) {
                sheetData.installationData.forEach((installation) => {
                    rowData['Statut de la pré-étude'] = installation.result === null || installation.result === '' ? 'Pré-étude non réalisée' : installation.result;
                    rowData['Statut de l\'étude'] = installation.validation === null || installation.validation === '' ? 'Étude non réalisée' : installation.validation;
                    rowData['Statut de l\'installation'] = installation.idTechPartner !== null ? 'Installé' : 'Non installé';
                });
            } else {
                rowData['Statut de la pré-étude'] = 'Pré-étude non réalisée';
                rowData['Statut de l\'étude'] = 'Étude non réalisée';
                rowData['Statut de l\'installation'] = 'Non installé';
            }

            if (Array.isArray(sheetData.activationData) && sheetData.activationData.length > 0) {
                sheetData.activationData.forEach((activation) => {
                    rowData['Statut de l\'activation'] = activation.idActivation === '' || activation.idActivation === null ? 'Service non activé' : 'Service activé';
                });
            } else {
                rowData['Statut de l\'activation'] = 'Service non activé';
            }

            return rowData;
        });

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Commandes");

        XLSX.writeFile(wb, "commandes.xlsx");
    };

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <>
                        <Header />

                        <Sidebar setHideText={setHideText} hideText={hideText} />

                        <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>

                            {isDetailsVisible && (
                                <div>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des commandes</h1>

                                    {localStorage.getItem('userRole') === 'commercial_partner' ? (
                                        <>
                                            <div className="flex flex-col md:flex-row justify-end mb-4">
                                                <Link to="/partner-sheet">
                                                    <button
                                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-4"
                                                    >
                                                        Prospecter un client
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="flex flex-col md:flex-row justify-end mb-4">
                                                <div className="w-full md:w-[50%]">
                                                    <label className="relative block">
                                                        <span className="sr-only">Rechercher</span>
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search">
                                                                <circle cx="11" cy="11" r="8" />
                                                                <path d="m21 21-4.3-4.3" />
                                                            </svg>
                                                        </span>
                                                        <input
                                                            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                            placeholder="Rechercher un client par son nom"
                                                            type="text"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="px-2 py-2">Date de souscription</th>
                                                        <th className="px-2 py-2">Nom du client</th>
                                                        <th className="px-2 py-2">Email</th>
                                                        <th className="px-4 py-2">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredOrders.length > 0 ? (
                                                            filteredOrders.map((order) => (
                                                                <tr key={order.idSheet} className="border-b hover:bg-gray-100 text-center">
                                                                    <td className="px-2 py-2">{<FormatDate dateStr={order.sheetCreatedAt} />}</td>
                                                                    <td className="px-2 py-2">{order.nameOrSocialName}</td>
                                                                    <td className="px-2 py-2">{order.email}</td>
                                                                    <td className="text-center px-4 py-2">
                                                                        <button
                                                                            onClick={() => { showOrderDetails(order); }}
                                                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 mr-2"
                                                                        >
                                                                            Détails
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">Aucune commande trouvée.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                    ) : null}

                                    {localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'admin_partner' || localStorage.getItem('userRole') === 'commercial_manager' || localStorage.getItem('userRole') === 'infra_admin' || localStorage.getItem('userRole') !== 'commercial_partner' ? (
                                        <>
                                            <div className="flex flex-col md:flex-row justify-end mb-4">
                                                <div className="w-full md:w-[50%]">
                                                    <label className="relative block">
                                                        <span className="sr-only">Rechercher</span>
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search">
                                                                <circle cx="11" cy="11" r="8" />
                                                                <path d="m21 21-4.3-4.3" />
                                                            </svg>
                                                        </span>
                                                        <input
                                                            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                            placeholder="Rechercher un client par son nom"
                                                            type="text"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="flex flex-col md:flex-row justify-end mb-4">
                                                <div className="w-full md:w-[20%]">
                                                    <select
                                                        className="block w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                        value={partnerFilter}
                                                        onChange={(e) => setPartnerFilter(e.target.value)}
                                                    >
                                                        <option value="">Tous les partenaires/CERAF</option>
                                                        {[...new Set(allSheetData.flatMap(data => data.preStudyOrder.map(order => order.nameEntity)))].map(partner => (
                                                            <option key={partner} value={partner}>{partner}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="ml-2 w-full md:w-[20%]">
                                                    <select
                                                        className="block w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                        value={installationStatusFilter}
                                                        onChange={(e) => setInstallationStatusFilter(e.target.value)}
                                                    >
                                                        <option value="">Statut de l'installation</option>
                                                        <option value="Installé">Installé</option>
                                                        <option value="Non installé">Non installé</option>
                                                    </select>
                                                </div>
                                                <div className="ml-2 w-full md:w-[20%]">
                                                    <button
                                                        onClick={handleDownloadExcel}
                                                        className="block w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                                    >
                                                        Télécharger Excel
                                                    </button>
                                                </div>
                                            </div>

                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="px-2 py-2">Date de souscription</th>
                                                        <th className="px-2 py-2">Partenaire/CERAF</th>
                                                        <th className="px-2 py-2">Nom du client</th>
                                                        <th className="px-2 py-2">Statut d'affectation de la commande</th>
                                                        <th className="px-2 py-2">Statut de la pré-étude</th>
                                                        <th className="px-2 py-2">Statut de l'étude</th>
                                                        <th className="px-2 py-2">Statut de l'activation</th>
                                                        <th className="px-2 py-2">Statut de l'installation</th>
                                                        <th className="px-4 py-2">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredSheetData.length > 0 ? (
                                                            filteredSheetData.map((sheetData) => (
                                                                <tr key={sheetData.idSheet} className="border-b hover:bg-gray-100 text-center">
                                                                    {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 ? (
                                                                        sheetData.preStudyOrder.map((order) => (
                                                                            <>
                                                                                <td className="px-2 py-2">{<FormatDate dateStr={order.sheetCreatedAt} />}</td>
                                                                                <td className="px-2 py-2 text-center">{order.nameEntity}</td>
                                                                                <td className="px-2 py-2 text-center">{order.nameOrSocialName}</td>
                                                                                <td className="px-2 py-2 text-center">{order.idPartnerTechnician === 0 || order.idPartnerTechnician === null || order.idPartnerTechnician === '' ? 'Non affectée à un technicien' : 'Affectée à un technicien'}</td>
                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        <td className="px-2 py-2 text-center">Aucune donnée disponible</td>
                                                                    )}

                                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                                        sheetData.installationData.map((installation) => (
                                                                            <>
                                                                                <td className="px-2 py-2 text-center">{installation.result === null || installation.result === '' ? 'Pré-étude non réalisée' : installation.result}</td>
                                                                                <td className="px-2 py-2 text-center">
                                                                                    {installation.validation === null || installation.validation === '' ? 'Étude non réalisée' : installation.validation}
                                                                                </td>
                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        <>
                                                                            <td className="px-2 py-2 text-center">Pré-étude non réalisée</td>
                                                                            <td className="px-2 py-2 text-center">Étude non réalisée</td>
                                                                        </>
                                                                    )}

                                                                    {Array.isArray(sheetData.activationData) && sheetData.activationData.length > 0 ? (
                                                                        sheetData.activationData.map((activation) => (
                                                                            <>
                                                                                <td className="px-2 py-2 text-center">{activation.idActivation === '' || activation.idActivation === null ? 'Service non activé' : 'Service activé'}</td>
                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        <>
                                                                            <td className="px-2 py-2 text-center">Service non activé</td>
                                                                        </>
                                                                    )}

                                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                                        sheetData.installationData.map((installation) => (
                                                                            <>
                                                                                <td className="px-2 py-2 text-center">
                                                                                    {installation.idTechPartner !== null ? 'Installé' : 'Non installé'}
                                                                                </td>
                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        <>
                                                                            <td className="px-2 py-2 text-center">Non installé</td>
                                                                        </>
                                                                    )}

                                                                    {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 ? (
                                                                        sheetData.preStudyOrder.map((order) => (
                                                                            <>
                                                                                <td className="text-center px-4 py-2 text-center">
                                                                                    <button
                                                                                        onClick={() => { showOrderDetails(order) }}
                                                                                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 my-1 px-2 mr-2"
                                                                                    >
                                                                                        Détails
                                                                                    </button>
                                                                                </td>
                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        <p>Aucune donnée de pré-étude disponible</p>
                                                                    )}
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr><td className="text-center" colSpan={8}>Aucune donnée disponible</td></tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                    ) : null}
                                </div>
                            )}

                            {selectedOrder && !isDetailsVisible && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end mb-4">
                                        {localStorage.getItem('userRole') === 'admin_partner' && (
                                            <>
                                                {!selectedInstallationData ? (
                                                    <div>
                                                        <button
                                                            onClick={() => openModal(selectedOrder.idSheet)}
                                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 mr-2"
                                                        >
                                                            Assigner à un technicien
                                                        </button>
                                                        <CustomModal
                                                            isOpen={isOpen}
                                                            closeModal={closeModal}
                                                            techs={techs}
                                                            selectedOrderId={selectedOrder.idSheet}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {selectedInstallationData.idInstallation === null ? (
                                                            <div>
                                                                <button
                                                                    onClick={() => openModal(selectedOrder.idSheet)}
                                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 mr-2"
                                                                >
                                                                    Ré-assigner à un autre technicien
                                                                </button>
                                                                <CustomModal
                                                                    isOpen={isOpen}
                                                                    closeModal={closeModal}
                                                                    techs={techs}
                                                                    selectedOrderId={selectedOrder.idSheet}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => setIsDetailsVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-3xl font-bold mb-4 uppercase text-center">Détails de la commande</h2>
                                    {localStorage.getItem('userRole') === 'admin_partner' && (
                                        <>
                                            {comPartnerName.nom ? <p><strong>Commercial du partenaire ayant prospecté la commande: </strong> {comPartnerName.nom + ' ' + comPartnerName.prenom}</p> : <p><strong>Client assignée à vous par CAMTEL.</strong></p>}
                                            {techPartnerName.nom ? <p><strong>Technicien du partenaire en charge de la pré-étude et de l'installation de la commande :</strong> {techPartnerName.nom + ' ' + techPartnerName.prenom}</p> : <p><strong>Commande non assignée à un technicien (Partenaire) pour pré-étude.</strong></p>}
                                        </>
                                    )}
                                    {localStorage.getItem('userRole') === 'admin_camtel' && (
                                        <>
                                            {techCamtelName.nom ? <p><strong>Pool Technique CAMTEL en charge de l'étude: </strong> {techCamtelName.nom + ' ' + techCamtelName.prenom}</p> : <p><strong>Étude pas encore validée par le Pool Technique CAMTEL</strong></p>}
                                            {comCamtelName.nom ? <p><strong>Pool Commercial CAMTEL en charge de l'activation de cette commande: </strong> {comCamtelName.nom + ' ' + comCamtelName.prenom}</p> : <p><strong>Commande non activée par le Pool Commercial CAMTEL.</strong></p>}
                                        </>
                                    )}

                                    <p><strong>Date de souscription du client: </strong><FormatDate dateStr={selectedOrder.sheetCreatedAt} /></p>

                                    {selectedOrder.customerPhoto && (
                                        <div>
                                            <strong>Photo du client:</strong>
                                            <img
                                                src={selectedOrder.customerPhoto}
                                                alt="Photo du client"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 200, width: 200 }}
                                            />
                                        </div>
                                    )}
                                    <h3 className="text-2xl font-bold mb-2">INFORMATIONS CLIENT</h3>
                                    <p><strong>N°ID Souscription :</strong> {selectedOrder.idSubscription + selectedOrder.idSheet}</p>
                                    <p><strong>Nom et Prénoms / Raison Sociale :</strong> {selectedOrder.nameOrSocialName}</p>
                                    <p><strong>Type de Client:</strong> {selectedOrder.customerType}</p>
                                    <p>
                                        <strong>Région:</strong>
                                        {
                                            selectedOrder.region === 'CE' ? 'Centre' :
                                                selectedOrder.region === 'LT' ? 'Littoral' :
                                                    selectedOrder.region === 'NO' ? 'Nord' :
                                                        selectedOrder.region === 'EN' ? 'Extrême-Nord' :
                                                            selectedOrder.region === 'AD' ? 'Adamaoua' :
                                                                selectedOrder.region === 'ES' ? 'Est' :
                                                                    selectedOrder.region === 'SU' ? 'SUD' :
                                                                        selectedOrder.region === 'OU' ? 'Ouest' :
                                                                            selectedOrder.region === 'NW' ? 'Nord-Ouest' :
                                                                                'Sud-Ouest'
                                        }
                                    </p>
                                    <p><strong>Ville:</strong> {selectedOrder.city}</p>
                                    <p><strong>Localisation Précise:</strong> {selectedOrder.localisation}</p>
                                    <p><strong>Lieu-dit:</strong> {selectedOrder.lieuDit}</p>
                                    {selectedOrder.customerGpsCoordinates && (<p><strong>Coordonnées GPS: </strong>{selectedOrder.customerGpsCoordinates}</p>)}
                                    <p>
                                        <strong>Plan de localisation:</strong>
                                        {(selectedOrder.imageLocalisation !== null || selectedOrder.imageLocalisation !== '') && (selectedOrder.imageLocalisation.endsWith('.jpg') || selectedOrder.imageLocalisation.endsWith('.png')) ? (
                                            <img
                                                src={selectedOrder.imageLocalisation}
                                                alt="Plan de localisation"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 500, width: '100%' }}
                                            />
                                        ) : (
                                            <iframe
                                                src={selectedOrder.imageLocalisation}
                                                width="100%"
                                                height="500"
                                                title="PDF Viewer"
                                            ></iframe>
                                        )}
                                    </p>
                                    <p><strong>Email:</strong> {selectedOrder.email}</p>
                                    <p><strong>N° Mobile:</strong> {selectedOrder.mobile}</p>
                                    {selectedOrder.idCardNumber !== null && (
                                        <>
                                            <p><strong>Numéro de la CNI:</strong> {selectedOrder.idCardNumber}</p>
                                            <p>
                                                <strong>Carte Nationnale d'identité:</strong>
                                                <div className="flex">
                                                    <div className="flex flex-col md:flex-row">
                                                        {(selectedOrder.rectoCni !== null || selectedOrder.rectoCni !== '') && (selectedOrder.rectoCni.endsWith('.jpg') || selectedOrder.rectoCni.endsWith('.png')) ? (
                                                            <img
                                                                src={selectedOrder.rectoCni}
                                                                alt="Image Recto de la CNI"
                                                                className="w-full h-auto mt-2 rounded md:w-1/2"
                                                                style={{ height: 250 }}
                                                            />
                                                        ) : (
                                                            <iframe
                                                                src={selectedOrder.rectoCni}
                                                                className="w-full md:w-1/2"
                                                                height="300"
                                                                title="PDF Viewer"
                                                            ></iframe>
                                                        )}

                                                        {(selectedOrder.versoCni !== null || selectedOrder.versoCni !== '') && (selectedOrder.versoCni.endsWith('.jpg') || selectedOrder.versoCni.endsWith('.png')) ? (
                                                            <img
                                                                src={selectedOrder.versoCni}
                                                                alt="Image Verso de la CNI"
                                                                className="w-full h-auto mt-2 rounded md:w-1/2"
                                                                style={{ height: 250 }}
                                                            />
                                                        ) : (
                                                            <iframe
                                                                src={selectedOrder.versoCni}
                                                                className="w-full md:w-1/2"
                                                                height="300"
                                                                title="PDF Viewer"
                                                            ></iframe>
                                                        )}
                                                    </div>
                                                </div>
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.idPassportNumber !== null && (
                                        <>
                                            <p><strong>Numéro du passeport:</strong> {selectedOrder.idPassportNumber}</p>
                                            <p>
                                                <strong>Passeport:</strong>
                                                {(selectedOrder.idPasseportFront !== null || selectedOrder.idPasseportFront !== '') && (selectedOrder.idPasseportFront.endsWith('.jpg') || selectedOrder.idPasseportFront && selectedOrder.idPasseportFront.endsWith('.png')) ? (
                                                    <img
                                                        src={selectedOrder.idPasseportFront}
                                                        alt="Image Recto du passeport"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 250, width: '50%' }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={selectedOrder.idPasseportFront}
                                                        width="100%"
                                                        height="300"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.idResidencePermitNumber !== null && (
                                        <>
                                            <p><strong>Numéro de la carte de séjour:</strong> {selectedOrder.idResidencePermitNumber}</p>
                                            <p>
                                                <strong>Carte de séjour:</strong>
                                                <div className="flex">
                                                    {(selectedOrder.idResidencePermitFront !== null || selectedOrder.idResidencePermitFront !== '') && (selectedOrder.idResidencePermitFront.endsWith('.jpg') || selectedOrder.idResidencePermitFront.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.idResidencePermitFront}
                                                            alt="Image Recto de la carte de séjour"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.idResidencePermitFront}
                                                            width="100%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}
                                    {selectedOrder.commerceRegNumber !== '' && (
                                        <>
                                            <p><strong>N°Régistre Commerce :</strong> {selectedOrder.commerceRegNumber}</p>
                                            <p>
                                                <strong>Image du registre de commerce:</strong>
                                                <div className="flex">
                                                    {(selectedOrder.rectoCommerceReg !== null || selectedOrder.rectoCommerceReg !== '') && (selectedOrder.rectoCommerceReg.endsWith('.jpg') || selectedOrder.rectoCommerceReg && selectedOrder.rectoCommerceReg.endsWith('.png')) ? (
                                                        <img
                                                            src={selectedOrder.rectoCommerceReg}
                                                            alt="Image du registre de commerce"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 'auto', width: '100%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.rectoCommerceReg}
                                                            width="100%"
                                                            height="500"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </p>
                                        </>
                                    )}

                                    <h3 className="text-2xl font-bold mt-2">TYPE D’OPERATION</h3>
                                    <p><strong>Formule d’abonnement choisie:</strong> {selectedOrder.offerBlueMaxSelected}</p>
                                </div>
                            )}

                            {selectedPreStudyOrder && !isDetailsVisible && (
                                <>
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-3xl font-bold mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                                        <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                                        <p><strong>Nom du prestataire :</strong> {selectedPreStudyOrder.name}</p>
                                        <p><strong>Tel :</strong> {selectedPreStudyOrder.telephoneEntity}</p>
                                        <p><strong>Email :</strong> {selectedPreStudyOrder.emailEntity}</p>
                                        {
                                            details && (
                                                <>
                                                    <p><strong>Zone d’intervention :</strong> {details.interventionZone}</p>
                                                    <p><strong>Agence Commerciale :</strong> {details.name}</p>
                                                </>
                                            )
                                        }
                                        <p><strong>Référence du FDT :</strong> {selectedPreStudyOrder.fdtRef}</p>
                                        <p><strong>Référence du FAT :</strong> {selectedPreStudyOrder.fatRef}</p>
                                        <p><strong>Distance FAT-Client (m) :</strong> {selectedPreStudyOrder.dFatCustomer}</p>
                                        <p><strong>Nombre de poteau à installer :</strong> {selectedPreStudyOrder.nbPoToInstall}</p>
                                    </div>
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-3xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE LA PRE-ETUDE</h3>
                                        <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                                        <p><strong>Statut de la validation par le technicien du prestataire:</strong> {selectedPreStudyOrder.result}</p>
                                        <p><strong>Observations fait par le technicien du prestataire:</strong> {selectedPreStudyOrder.preEtudeObs}</p>
                                    </div>
                                    {
                                        (selectedPreStudyOrder.validation !== '' || selectedPreStudyOrder.etudeObs !== '') && (
                                            <div className="mt-4 border p-4">
                                                <h3 className="text-3xl font-bold mb-2 mt-2 text-center">VALIDATION ET OBSERVATIONS DE L’ETUDE</h3>
                                                <p><strong>Date de validation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                                                {selectedPreStudyOrder.validation !== '' && (
                                                    <p><strong>Statut de la validation par le technicien de CAMTEL:</strong> {selectedPreStudyOrder.validation}</p>
                                                )}
                                                {selectedPreStudyOrder.etudeObs !== '' && (
                                                    <p><strong>Observations de l'étude du technicien de CAMTEL:</strong> {selectedPreStudyOrder.etudeObs}</p>
                                                )}
                                            </div>
                                        )
                                    }
                                </>
                            )}

                            {selectedActivationData && selectedActivationData.crmCustomerCode !== null && selectedActivationData.crmCustomerCode !== '' && !isDetailsVisible && (
                                <div className="mt-4 border p-4">
                                    <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">ACTIVATION</h3>
                                    <p><strong>Date de l'activation: </strong><FormatDate dateStr={selectedActivationData.activationCreatedAt} /></p>
                                    <p><strong>ID de la commande (CBS) :</strong> {selectedActivationData.cbsOrderId}</p>
                                    <p><strong>Code Client CRM :</strong> {selectedActivationData.crmCustomerCode}</p>
                                    <p><strong>Compte de facturation :</strong> {selectedActivationData.billingAccount}</p>
                                    <p><strong>Numéro de Telephone :</strong> {selectedActivationData.phoneNumberActivated}</p>
                                    <p><strong>Numéro ONT :</strong> {selectedActivationData.ontNumber}</p>
                                    <p><strong>Référence paiement :</strong> {selectedActivationData.paymentRef}</p>
                                    <p><strong>Date de paiement :</strong><FormatDate dateStr={selectedActivationData.paymentDate} /></p>
                                    <p><strong>Numéro STB/IP Stick :</strong> {selectedActivationData.stbIpStickNumber}</p>
                                </div>
                            )}

                            {selectedInstallationData && selectedInstallationData.idInstallation !== null && !isDetailsVisible && (
                                <>
                                    {selectedInstallationData.idTechPartner !== null && (
                                        <div className="mt-4 border p-4">
                                            <h3 className="text-3xl font-bold mb-2 mt-2 text-center">INSTALLATION</h3>
                                            <p><strong>Date de l'installation: </strong><FormatDate dateStr={selectedInstallationData.installationCreatedAt} /></p>
                                            <p><strong>Nombre de Balancelle :</strong> {selectedInstallationData.nbBalancel}</p>
                                            <p><strong>Métrage câble entrée FAT :</strong> {selectedInstallationData.cableMeterEntryFat}</p>
                                            <p><strong>Longueur Câble utilisée (m) :</strong> {selectedInstallationData.usedCableLong}</p>
                                            <p><strong>Nombre d’ATB :</strong> {selectedInstallationData.atbNumber}</p>
                                            <p><strong>Nombre Crochet d’Arrêt :</strong> {selectedInstallationData.nbCrochetArret}</p>
                                            <p><strong>Métrage câble chez l’abonné :</strong> {selectedInstallationData.clientCableMeter}</p>
                                            <p><strong>Nombre de poteau armé installé :</strong> {selectedInstallationData.nbArmedInstalledPoto}</p>
                                            <p><strong>Numéro ONT :</strong> {selectedInstallationData.ontNumber}</p>
                                            <p><strong>Nombre de STB/ Stick :</strong> {selectedInstallationData.stbStickNumber}</p>
                                            <p><strong>Nombre de TB :</strong> {selectedInstallationData.tbNumber}</p>
                                            <p><strong>Nombre de Jarretière :</strong> {selectedInstallationData.nbGarter}</p>
                                            <p><strong>Nombre de Smoove :</strong> {selectedInstallationData.nbSmoove}</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )
            }
        </>
    );
}

export default Order;