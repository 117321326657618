import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./components/Sidebar";
import FormatDate from "./components/FormatDate";
import Header from "./components/Header";
import { BASE_URL } from "../../apiConfig";

const Profile = () => {
    const [profiles, setProfiles] = useState([]);
    const [formData, setFormData] = useState({ nom: "", prenom: "", mobile: "", email: "", password: "camtel", cni_matricule: "", localisation: "", role: "", role: "", userEntity: localStorage.getItem('userEntity'), idEntity: localStorage.getItem('userId') });
    const [isAdding, setIsAdding] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [editingProfile, setEditingProfile] = useState(null);

    const [role, setRole] = useState("");

    const [editedName, setEditedName] = useState("");
    const [editedPrenom, setEditedPrenom] = useState("");
    const [editedMobile, setEditedMobile] = useState("");
    const [editedEmail, setEditedEmail] = useState("");
    const [editedCniMatricule, seteditedCniMatricule] = useState("");
    const [editedLocalisation, setEditedLocalisation] = useState("");
    const [editedRole, setEditedRole] = useState("");

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isAddProfileVisibile, setIsAddProfileVisible] = useState(true);
    const [isEditProfileVisible, setIsEditProfileVisible] = useState(true);

    const [loading, setLoading] = useState(false);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDetails = (id) => {
        const profile = profiles.find((profile) => profile.id === id);
        setSelectedProfile(profile);
        setEditingProfile(false);
        setIsAdding(false);

        setIsDetailsVisible(false);
    };

    const handleAdd = async () => {
        setLoading(true);

        if (
            !formData.nom ||
            !formData.prenom ||
            !formData.mobile ||
            !formData.email ||
            !formData.cni_matricule ||
            !formData.localisation ||
            !role
        ) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const data = formData;

        if (token) {
            try {
                const config = {
                    method: 'post',
                    url: `${BASE_URL}/api/profile`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    data: data
                };

                const response = await axios.request(config);

                setFormData({
                    nom: "",
                    prenom: "",
                    mobile: "",
                    email: "",
                    password: "camtel123",
                    cni_matricule: "",
                    localisation: "",
                    role: "",
                    userEntity: localStorage.getItem('userEntity'),
                    idEntity: localStorage.getItem('userId')
                });

                setProfiles([...profiles, response.data]);
                setEditingProfile(false);
                setIsAdding(false);
                setIsAddProfileVisible(true);
                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la création du profil : ", error);
                setLoading(false);
                alert('Erreur lors de la création du profil !');
            }
        }
    };

    const handleEdit = (id) => {
        setSelectedProfile(false);
        setIsAdding(false);

        const profile = profiles.find((profile) => profile.id === id);
        setEditingProfile(profile);
        setEditedName(profile.nom);
        setEditedPrenom(profile.prenom);
        setEditedMobile(profile.mobile);
        setEditedEmail(profile.email);
        seteditedCniMatricule(profile.cni_matricule);
        setEditedLocalisation(profile.localisation);
        setEditedRole(profile.role);

        setIsDetailsVisible(false);
        setIsEditProfileVisible(false);
    };

    const handleSubmit = () => {
        setLoading(true);

        if (!editedName || !editedPrenom || !editedMobile || !editedEmail || !editedCniMatricule || !editedLocalisation || !editedRole) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const updatedData = {
            ...editingProfile,
            nom: editedName,
            prenom: editedPrenom,
            mobile: editedMobile,
            email: editedEmail,
            cni_matricule: editedCniMatricule,
            localisation: editedLocalisation,
            role: editedRole
        };

        if (token) {
            axios.put(`${BASE_URL}/api/profile/${editingProfile.id}/${editingProfile.idEntity}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    alert('Profil modifié avec succès.');
                    setIsDetailsVisible(true);
                    setEditingProfile(null);
                    setIsEditProfileVisible(true);
                    setLoading(false);
                })
                .catch((error) => {
                    alert('Erreur lors de la mise à jour du profil.');
                    console.error("Erreur lors de la mise à jour du profil : ", error);
                    setLoading(false);
                });
        }
    };

    const handleDelete = (id) => {
        setLoading(true);

        if (token && window.confirm('Êtes-vous sûr de vouloir désactiver ce profil ? Ce profil ne pourra plus se connecter et sera inactif dans la plateforme après cette action.')) {
            axios.patch(`${BASE_URL}/api/profile/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    setSelectedProfile(null);
                    setLoading(false);
                    setIsDetailsVisible(true);
                })
                .catch((error) => {
                    console.error("Erreur lors de la suppression du profil : ", error);
                    setLoading(false);
                });
        }
        setLoading(false);
    };

    const reactivateProfile = (id) => {
        setLoading(true);

        if (token && window.confirm('Êtes-vous sûr de vouloir réactiver ce profil ? Ce profil pourra désormais être en mesure de se connecter et d\'être actif dans la plateforme après cette action.')) {
            axios.patch(`${BASE_URL}/api/profile/reactivate/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    setSelectedProfile(null);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Erreur lors de la suppression du profil : ", error);
                    setLoading(false);
                });
        }
        setLoading(false);
    }

    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                const idEntity = localStorage.getItem('userId');

                try {
                    const response = await axios.get(`${BASE_URL}/api/profile/${idEntity}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setProfiles(response.data.profiles);
                } catch (error) {
                    console.error("Erreur lors de la récupération des profils :", error);
                }
            };

            fetchData();
        }
    }, [formData, selectedProfile, editingProfile, handleSubmit, handleDelete, reactivateProfile, token]);

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                        <div className="w-full">
                            {isDetailsVisible && isEditProfileVisible && isAddProfileVisibile && (
                                <div>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des profils</h1>

                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => { setIsAdding(true); setSelectedProfile(false); setEditingProfile(false); setIsAddProfileVisible(false); }}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-4"
                                        >
                                            Ajouter un profil
                                        </button>
                                    </div>

                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-4 py-2">Nom</th>
                                                <th className="px-4 py-2">Email</th>
                                                <th className="px-4 py-2">Type de profil</th>
                                                <th className="px-4 py-2">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(profiles) && profiles.length > 0 ? (
                                                profiles.map((profile) => (
                                                    <tr key={profile.id} className={profile.visible === 0 ? "text-red-500 border-b hover:bg-gray-100 text-center" : "border-b hover:bg-gray-100 text-center"}>
                                                        <td className="px-4 py-2">{profile.nom}</td>
                                                        <td className="px-4 py-2">{profile.email}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                profile.role === 'commercial_partner' ? 'Commercial' :
                                                                    profile.role === 'technicien_partner' ? 'Technicien' :
                                                                        profile.role === 'technicien_camtel' ? 'Technicien' :
                                                                            'Commercial'
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-center">

                                                            {profile.visible === 1 ? (
                                                                <button
                                                                    onClick={() => handleDetails(profile.id)}
                                                                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold m-2 py-1 px-2 mr-2"
                                                                >
                                                                    Détails
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    onClick={() => reactivateProfile(profile.id)}
                                                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 m-1"
                                                                >
                                                                    Réactiver
                                                                </button>
                                                            )
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="3" className="text-center">Aucun profil trouvé.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {selectedProfile && !isDetailsVisible && (
                                <div className="mt-4">
                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => handleEdit(selectedProfile.id)}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 m-1"
                                        >
                                            Modifier
                                        </button>
                                        <button
                                            onClick={() => handleDelete(selectedProfile.id)}
                                            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold p-2 m-1"
                                        >
                                            Désactiver
                                        </button>
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 m-1 text-white"
                                            onClick={() => { setIsDetailsVisible(true); }}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                    <h2 className="text-3xl font-bold text-center m-2">Détails du profil</h2>
                                    <div>
                                        <strong>Nom:</strong> {selectedProfile.nom}
                                    </div>
                                    <div>
                                        <strong>Prénom:</strong> {selectedProfile.prenom}
                                    </div>
                                    <div>
                                        <strong>Numéro mobile:</strong> {selectedProfile.mobile}
                                    </div>
                                    <div>
                                        <strong>Email:</strong> {selectedProfile.email}
                                    </div>
                                    <div>
                                        <strong>CNI / Matricule:</strong> {selectedProfile.cni_matricule}
                                    </div>
                                    <div>
                                        <strong>Localisation précise:</strong> {selectedProfile.localisation}
                                    </div>
                                    <div>
                                        <strong>Rôle:</strong>
                                        {
                                            selectedProfile.role === 'commercial_camtel' || selectedProfile.role === 'commercial_partner' ? (
                                                <> Commercial</>
                                            ) : (
                                                <> Technicien</>
                                            )
                                        }
                                    </div>
                                    <div>
                                        <strong>Date de création: </strong> <FormatDate dateStr={selectedProfile.userCreatedAt} />
                                    </div>
                                </div>
                            )}

                            {isAdding && !isAddProfileVisibile && (
                                <div className="mt-4">
                                    <div className="flex justify-end">
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => { setIsAddProfileVisible(true); setIsDetailsVisible(true); }}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                    <h2 className="text-3xl font-bold text-center mb-2">Ajouter un profil</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="nom" className="block text-gray-700 font-bold">
                                                Nom  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="nom"
                                                name="nom"
                                                value={formData.nom}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="prenom" className="block text-gray-700 font-bold">
                                                Prénom  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="prenom"
                                                name="prenom"
                                                value={formData.prenom}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="mobile" className="block text-gray-700 font-bold">
                                                Mobile  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="mobile"
                                                name="mobile"
                                                value={formData.mobile}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="email" className="block text-gray-700 font-bold">
                                                Email  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="cni_matricule" className="block text-gray-700 font-bold">
                                                CNI / Matricule  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="cni_matricule"
                                                name="cni_matricule"
                                                value={formData.cni_matricule}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="localisation" className="block text-gray-700 font-bold">
                                                Localisation précise  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="localisation"
                                                name="localisation"
                                                value={formData.localisation}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="role" className="block text-gray-700 font-bold">
                                                Type de compte  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <select
                                                id="role"
                                                name="role"
                                                className="border rounded w-full py-2 px-3"
                                                value={role}
                                                onChange={(event) => {
                                                    setFormData({ ...formData, role: event.target.value });
                                                    setRole(event.target.value);
                                                }}
                                            >
                                                <option value="">Selectionnez le type de compte</option>
                                                {localStorage.getItem('userRole') === 'admin_camtel' && (
                                                    <>
                                                        <option value="commercial_camtel">Commercial</option>
                                                        <option value="technicien_camtel">Technicien</option>
                                                    </>
                                                )}
                                                {localStorage.getItem('userRole') === 'admin_partner' && (
                                                    <>
                                                        <option value="commercial_partner">Commercial</option>
                                                        <option value="technicien_partner">Technicien</option>
                                                    </>
                                                )}
                                            </select>
                                            {/* <p>
                                    Le rôle sélectionné est : {role}
                                </p> */}
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleAdd}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Ajouter
                                        </button>
                                    </form>
                                </div>
                            )}

                            {editingProfile && !isEditProfileVisible && (
                                <div className="mt-4">
                                    <div className="flex justify-end">
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => { setIsEditProfileVisible(true); setIsDetailsVisible(true); }}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                    <h2 className="text-3xl font-bold text-center mb-2">Modifier le profil</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="editedName" className="block text-gray-700 font-bold">
                                                Nom <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedName"
                                                name="editedName"
                                                value={editedName}
                                                onChange={(e) => setEditedName(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedPrenom" className="block text-gray-700 font-bold">
                                                Prénom <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedPrenom"
                                                name="editedPrenom"
                                                value={editedPrenom}
                                                onChange={(e) => setEditedPrenom(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedMobile" className="block text-gray-700 font-bold">
                                                Mobile <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedMobile"
                                                name="editedMobile"
                                                value={editedMobile}
                                                onChange={(e) => setEditedMobile(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedEmail" className="block text-gray-700 font-bold">
                                                Email <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="editedEmail"
                                                name="editedEmail"
                                                value={editedEmail}
                                                onChange={(e) => setEditedEmail(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedCniMatricule" className="block text-gray-700 font-bold">
                                                CNI / Matricule <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedCniMatricule"
                                                name="editedCniMatricule"
                                                value={editedCniMatricule}
                                                onChange={(e) => seteditedCniMatricule(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLocalisation" className="block text-gray-700 font-bold">
                                                Localisation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLocalisation"
                                                name="editedLocalisation"
                                                value={editedLocalisation}
                                                onChange={(e) => setEditedLocalisation(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedRole" className="block text-gray-700 font-bold">
                                                Type de compte <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <select
                                                id="editedRole"
                                                name="editedRole"
                                                className="border rounded w-full py-2 px-3"
                                                value={editedRole}
                                                onChange={(e) => setEditedRole(e.target.value)}
                                            >
                                                <option value="">Selectionnez le type de compte</option>
                                                {localStorage.getItem('userRole') === 'admin_camtel' && (
                                                    <>
                                                        <option value="commercial_camtel">Commercial</option>
                                                        <option value="technicien_camtel">Technicien</option>
                                                    </>
                                                )}
                                                {localStorage.getItem('userRole') === 'admin_partner' && (
                                                    <>
                                                        <option value="commercial_partner">Commercial</option>
                                                        <option value="technicien_partner">Technicien</option>
                                                    </>
                                                )}
                                            </select>
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Enregistrer
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Profile;
