import { useEffect, useState } from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
    Font
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../apiConfig";
import axios from "axios";
import FormatDate from "./admin/components/FormatDate";
import NumberToWordsFr from "../components/NumberToWordsFr";
import NumberToWordsEn from "../components/NumberToWordsEn";

Font.register({
    family: 'Roboto',
    format: "truetype",
    fonts: [
        { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf' },
        { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', fontWeight: 700 }
    ]
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#fff",
        color: "#000",
        fontFamily: 'Roboto'
    },
    bgImg: {
        position: 'absolute',
        display: 'block',
        height: 300,
        width: 320,
        zIndex: -1,
        marginTop: 50,
        marginLeft: 140
    },
    section: {
        margin: 10,
        padding: 10,
        fontWeight: 'bold',
    },
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    title: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 7,
    },
    minTitle: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 8,
        justifyContent: 'space-around'
    },
    titleR: {
        margin: 5,
        padding: 2,
        textAlign: 'center',
        fontSize: 24,
        fontWeight: 'bold',
    },
    agency: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 10
    },
    table: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        marginLeft: 70,
    },
    col: {
        flex: 1
    },
    element: {
        marginBottom: 5,
        padding: 3,
    },
    lastView: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 10,
        marginLeft: 70,
        borderTop: '1px dashed #000',
    },
    totalAmount: {
        flex: 1,
        paddingTop: 5,
        marginTop: 3,
        fontWeight: 'bold',
    },
    lastViewTable: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 8,
        marginLeft: 70,
        marginTop: 5
    },
    bottom: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 8,
        justifyContent: 'space-between',
        margin: 10,
        padding: 10,
        bottom: 0,
        left: 0,
        right: 0,
        fontWeight: 'bold',
        color: 'gray'
    },
    msgLeft: {
        marginLeft: 25,
    },
    msgRight: {
        marginLeft: 20,
    }
});

function formatNumberWithSpaces(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Receipt = () => {
    const { cbsOrderId } = useParams();
    const [details, setDetails] = useState(null);

    useEffect(() => {
        const getReceiptDetails = async () => {
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${BASE_URL}/api/payment/receipt/${cbsOrderId}`,
                    headers: {}
                };

                axios.request(config)
                    .then((response) => {
                        const receiptDetails = response.data.receipt[0];

                        setDetails(receiptDetails);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Une erreur s'est produite :", error);
            }
        };

        getReceiptDetails();
    }, []);

    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A5" orientation="landscape" style={styles.page}>
                    <Image
                        style={styles.bgImg}
                        src={require('../assets/img/icons/bg-logo.png')}
                    />
                    <View style={styles.section}>
                        <Text style={styles.title}>S.A. CAMEROON TELECOMMUNICATIONS (CAMTEL)</Text>
                        <Text style={styles.text}>Capital Social: 67 904 430 000 FCFA / B.P.: 1571 Yaoundé-Cameroun</Text>
                        <Text style={styles.text}>N° RCCM: 2000 Q 225/N° Contribuable M 0998 0000 9853 S</Text>
                        <Text style={styles.text}>TELEPHONE: (+237) 222 234 065/Fax (+237) 222 230 303</Text>
                        <Text style={styles.text}>Site Web: www.camtel.cm</Text>
                    </View>
                    <View style={styles.minTitle}>
                        <View>
                            <Text>Reçu(Receipt) (Duplicata)</Text>
                        </View>
                        <View>
                            <Text>Page 1 sur (of) 1</Text>
                        </View>
                    </View>
                    <View style={styles.titleR}>
                        <Text>Reçu</Text>
                    </View>
                    {details && details.agencyName && (
                        <View style={styles.agency}>
                            <Text>Agence d'attache: {details.agencyName}</Text>
                        </View>
                    )}
                    <View style={styles.table}>
                        <View style={styles.col}>
                            <Text style={styles.element}>N° facture (Invoice N°)</Text>
                        </View>
                        {details && (
                            <View style={styles.col}>
                                <Text style={styles.element}>{details.payToken}</Text>
                            </View>
                        )}
                        {/* {details && (
                            <View style={styles.col}>
                                <Text style={styles.element}>{details.cbsOrderId}</Text>
                            </View>
                        )} */}
                    </View>
                    <View style={styles.table}>
                        <View style={styles.col}>
                            <Text style={styles.element}>Reçu No (Receipt No)</Text>
                        </View>
                        {details && (
                            <View style={styles.col}>
                                <Text style={styles.element}>{details.fibTxnId}</Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.table}>
                        <View style={styles.col}>
                            <Text style={styles.element}>Date</Text>
                        </View>
                        {details && (
                            <View style={styles.col}>
                                <Text style={styles.element}><FormatDate dateStr={details.orderPaidAt} /></Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.table}>
                        <View style={styles.col}>
                            <Text style={styles.element}>Client (Customer)</Text>
                        </View>
                        {details && (
                            <View style={styles.col}>
                                <Text style={styles.element}>{details.customerName}</Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.table}>
                        <View style={styles.col}>
                            <Text style={styles.element}>Méthode de paiement (Payment method)</Text>
                        </View>
                        {details && (
                            <View style={styles.col}>
                                <Text style={styles.element}>{details.paymentMethod === 'ORANGE' ? 'ORANGE Money' : 'MTN Mobile Money'}</Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.table}>
                        <View style={styles.col}>
                            <Text style={styles.element}>ID de la transaction (Transaction ID)</Text>
                        </View>
                        {details && (
                            <View style={styles.col}>
                                <Text style={styles.element}>{details.transactionId}</Text>
                            </View>
                        )}
                    </View>
                    {details && (
                        <View>
                            <View style={styles.lastView}>
                                <Text style={styles.totalAmount}>Montant total payé TTC (Total amount paid TTC)</Text>
                                {/* <Text style={styles.totalAmount}>{formatNumberWithSpaces(details.installationAmount)} FCFA</Text> */}
                                <Text style={styles.totalAmount}>{formatNumberWithSpaces(50000)} FCFA</Text>
                            </View>

                            <View style={styles.lastViewTable}>
                                <View style={styles.col}>
                                    <Text style={{}}>La somme en XAF</Text>
                                </View>
                                {details && (
                                    <View style={styles.col}>
                                        {/* <Text>{<NumberToWordsFr number={details.installationAmount} />}</Text> */}
                                        <Text>{<NumberToWordsFr number={50000} />}</Text>
                                    </View>
                                )}
                            </View>

                            <View style={styles.lastViewTable}>
                                <View style={styles.col}>
                                    <Text>The sum XAF</Text>
                                </View>
                                {details && (
                                    <View style={styles.col}>
                                        {/* <Text>{<NumberToWordsEn number={details.installationAmount} />}</Text> */}
                                        <Text>{<NumberToWordsEn number={50000} />}</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    )}
                    <View style={styles.bottom}>
                        <View>
                            <Text>Merci de nous faire confiance</Text>
                            <Text style={styles.msgLeft}>A bientôt !</Text>
                        </View>
                        <View>
                            <Text>Thank you for trusting us</Text>
                            <Text style={styles.msgRight}>See you soon !</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
}
export default Receipt;