import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../apiConfig';
import { useParams } from 'react-router-dom';
import { usePDF } from 'react-to-pdf';
import axios from 'axios';
import logoCamtel from '../assets/img/icons/favicon/favicon-camtel.png';
import logosFtth from '../assets/img/logo/logos-ftth.png';
import QRCode from 'qrcode.react';
import WhatsAppButton from '../components/WhatsAppButton';

const PdfSubscriptioForm = () => {
    const { idSheet } = useParams();
    const { toPDF, targetRef } = usePDF({ filename: `fiche_d_abonnement_${idSheet}.pdf` });
    const [order, setOrder] = useState(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${BASE_URL}/api/orders/pdf/` + idSheet,
                    headers: {}
                };

                axios.request(config)
                    .then((response) => {
                        setOrder(response.data.sheet);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Une erreur s'est produite :", error);
            }
        };

        fetchOrder();
    }, []);

    return (
        <>
            <div className="m-3 p-4">
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        className={`bg-sky-500 hover:bg-sky-600 text-white px-4 py-2 ml-2}`}
                        onClick={() => toPDF()}
                    >
                        Télécharger
                    </button>
                </div>

                <section ref={targetRef} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 200 }}>
                    {order && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20, height: 150, marginTop: 40 }}>
                                <div style={{ marginLeft: 30, height: 120, width: 170 }}>
                                    <img src={logoCamtel} alt="Logo CAMTEL" style={{ width: '100%', height: '100%' }} />
                                </div>

                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ width: 230 }}>
                                        <img src={logosFtth} alt="icônes FTTH" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                    <h2 className="text-sky-500" style={{ fontSize: '1.8rem', fontWeight: 'bold', textAlign: 'center', whiteSpace: 'nowrap' }}>
                                        Formulaire d’abonnement
                                    </h2>
                                </div>

                                <div style={{ marginRight: 20, width: 150 }}>
                                    <div>
                                        <img
                                            src={order[0].customerPhoto}
                                            alt="Photo du client"
                                            style={{ display: 'block', width: '100%', height: '100%', borderRadius: '0.25rem' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>ID de la souscription: </strong> {order[0].idSubscription}{order[0].idSheet}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Noms et Prénoms / Raison sociale: </strong> {order[0].nameOrSocialName}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Type de client: </strong> {order[0].customerType}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Région: </strong>
                                {
                                    order[0].region === 'CE' ? 'Centre' :
                                        order[0].region === 'LT' ? 'Littoral' :
                                            order[0].region === 'NO' ? 'Nord' :
                                                order[0].region === 'EN' ? 'Extrême-Nord' :
                                                    order[0].region === 'AD' ? 'Adamaoua' :
                                                        order[0].region === 'ES' ? 'Est' :
                                                            order[0].region === 'SU' ? 'SUD' :
                                                                order[0].region === 'OU' ? 'Ouest' :
                                                                    order[0].region === 'NW' ? 'Nord-Ouest' :
                                                                        'Sud-Ouest'
                                }
                            </p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Ville: </strong> {order[0].city}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Quartier: </strong> {order[0].localisation}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Lieu-dit: </strong> {order[0].lieuDit}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Coordonnées GPS du client: </strong> {order[0].customerGpsCoordinates}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Adresse mail: </strong> {order[0].email}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}><strong> N° de téléphone mobile: </strong> {order[0].mobile}</p>
                            <p style={{ marginLeft: 30, marginBottom: 10 }}>
                                <strong>Document d'identification: </strong>
                                {
                                    order[0].idCardNumber ? 'Carte Nationale d\'identité' : order[0].idPassportNumber ? 'Passeport' : 'Carte de séjour'
                                }
                            </p>
                            {order[0].idCardNumber && (
                                <>
                                    <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Numéro de la CNI: </strong> {order[0].idCardNumber}</p>
                                    <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Recto et Verso de la CNI: </strong></p>
                                    <div style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
                                        <div style={{ marginLeft: 30 }}>
                                            {order[0].rectoCni && (
                                                <img
                                                    src={order[0].rectoCni}
                                                    alt="Photo recto de la CNI"
                                                    style={{ width: '100%', height: '250px', borderRadius: '0.50rem' }}
                                                />
                                            )}
                                        </div>
                                        <div style={{ marginLeft: 30 }}>
                                            {order[0].versoCni && (
                                                <img
                                                    src={order[0].versoCni}
                                                    alt="Photo verso de la CNI"
                                                    style={{ width: '70%', height: '400px', borderRadius: '0.50rem' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {order[0].idPassportNumber && (
                                <>
                                    <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Numéro du passeport: </strong> {order[0].idPassportNumber}</p>
                                    <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Image du passeport: </strong></p>
                                    <div style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
                                        <div style={{ marginLeft: 30, width: '100%' }}>
                                            {order[0].idPasseportFront && (
                                                <img
                                                    src={order[0].idPasseportFront}
                                                    alt="Image du passeport"
                                                    style={{ width: '100%', height: '100%', borderRadius: '0.50rem' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {order[0].idResidencePermitNumber && (
                                <>
                                    <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Numéro de la carte de séjour: </strong> {order[0].idResidencePermitNumber}</p>
                                    <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>Image de la carte de séjour: </strong></p>
                                    <div style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
                                        <div style={{ marginLeft: 30 }}>
                                            {order[0].idResidencePermitFront && (
                                                <img
                                                    src={order[0].idResidencePermitFront}
                                                    alt="Image de la carte de séjour"
                                                    style={{ width: '70%', height: '400px', borderRadius: '0.50rem' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {order[0].regComNumber && (
                                <p style={{ marginLeft: 30, marginBottom: 10 }}><strong>N° du régistre de commerce: </strong> {order[0].regComNumber}</p>
                            )}
                            <p style={{ marginLeft: 30, marginBottom: 30 }}><strong>Offre Blue Max choisie: </strong> {order[0].offerBlueMaxSelected}</p>
                        </>
                    )}
                    <div className="float-right mr-5">
                        <QRCode value={`http://localhost:3000/order/pdf/${idSheet}`} />
                    </div>
                </section>
            </div>

            <WhatsAppButton />
        </>
    );
}

export default PdfSubscriptioForm;